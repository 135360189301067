import styles from './MainOrder.module.css'
import { useState, useEffect, Suspense } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import order from '../../../Images/Regular_size/order.png';
import orderCompressed from '../../../Images/Compressed_Image/order.png';
import BlueButton from "../../CommonComponents/BlueButton/BlueButton";
import { useNavigate } from 'react-router';

function MainOrder() {
    const [width, setWidth] = useState(window.innerWidth); // тут хранится ширина экрана

    useEffect(() => { // хук отслеживает изменение ширины экрана
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let navigate = useNavigate()

    return (
        <div style={width > 1920 ? { display: 'flex', justifyContent: 'center' } : {}}>
            <div style={width > 1920 ? { display: 'flex', flexDirection: 'row', maxWidth: 1920, justifyContent: 'center' } : {}}>
                <div style={{ marginTop: 100, display: 'flex', alignItems: 'flex-end' }}>
                    {width > 480 &&
                        <div style={{ marginLeft: (11 / 45) * width - (298 / 3), background: 'white' }} />
                    }
                    <div className={styles.polusWrapper} style={{ width: 1250 }}>
                        <div className={styles.polusHeader} style={{ marginTop: 40, marginLeft: 35 }}>
                            Полюс - точка притяжения в образовании и науке
                        </div>
                        <div className={styles.polusList} style={{ display: 'flex', marginTop: 23, marginLeft: 35 }}>
                            <div>
                                <ul className={styles.listMarginTop} style={{ paddingLeft: 20 }}>
                                    <li>Работа с образовательными учреждениями, департаментами и администрациями</li>
                                    <li className={styles.listMarginTop}>Партнеры по всей России</li>
                                </ul>
                            </div>
                            <div>
                                <ul className={styles.listMarginTop} style={{ paddingLeft: 20 }}>
                                    <li>Поддержка и обслуживания поставляемых товаров и услуг</li>
                                    <li className={styles.listMarginTop}>Продукция и услуги поставляются в более 20 регионов</li>
                                </ul>
                            </div>
                        </div>
                        <div style={{ marginLeft: 35, marginTop: 20, marginBottom: 30, marginRight: 20, display: 'flex', flexDirection: (width > 768) ? 'row' : "column" }}>
                            <div style={{ marginTop: 10, marginRight: 10, width: (width > 768) ? 220 : 300 }} onClick={() => { navigate("/customers"); window.scrollTo({top: 0, behavior: "instant"});}}>
                                <BlueButton width={(width > 768) ? 196 : 280} extension={(width > 768) ? 220 : 300} text={"Заказчикам"} />
                            </div>
                            <div style={{ flex: 2, marginTop: 10 }} >
                                {/* <BlueButton width={(width > 768) ? 196 : 280} extension={(width > 768) ? 220 : 300} text={"Приказ 804"} /> */}
                            </div>
                        </div>
                        {(width < 768 && width > 480) &&
                            <div style={{ position: 'absolute', right: 0, bottom: -20, width: 450 - (1000 - width) / 1.1, overflow: 'hidden' }}>
                                <LazyLoadImage
                                    src={order}
                                    placeholderSrc={orderCompressed}
                                    height={221}
                                    width={442}
                                    effect="blur"
                                />
                            </div>
                        }
                    </div>
                    {width > 1000 &&
                        <div style={{ background: "white", overflow: 'hidden', borderTopLeftRadius: 25 }}>
                            {width > 1000 &&
                                <div style={width <= 1920 ? { background: "rgba(216, 216, 216, 0.5)", marginRight: -(1920 - width) / 1.5 } : { background: "rgba(216, 216, 216, 0.5)" }}>
                                    <LazyLoadImage
                                        src={order}
                                        placeholderSrc={orderCompressed}
                                        height={340}
                                        width={660}
                                        effect="blur"
                                    />
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default MainOrder;
