import styles from './Uav.module.css'
import { useState, useEffect, useRef } from 'react';
import { InView } from "react-intersection-observer";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { addressStore } from "../Store/ConfigStore";
import { useStore } from "effector-react";
import axios from "axios";
import DoubleSidedGradient from '../CommonComponents/DoubleSidedGradient/DoubleSidedGradient';
import NewsPreloader from './NewsPreloader/NewsPreloader';
import NewsHeader from './NewsHeader/NewsHeader';
import SlateRenderer from '../CommonComponents/Editor/SlateRenderer/Renderer';
import NewsUavAdd from './NewsUavAdd/NewsUavAdd';

function Uav() {
    const [products, setProducts] = useState([])
    const [newsPreload, setNewsPreload] = useState([])
    const address = useStore(addressStore);
    let navigate = useNavigate();
    const [isListing, setIsListing] = useState(false)
    const [currentItem, setCurrentItem] = useState(0)
    const [modalName, setModalName] = useState("");
    const [modalDescription, setModalDescription] = useState("");
    const [isShowModalAddProduct, setIsShowModalAddProduct] = useState(false);
    const refs = {}
    const createHandler = (e) => {
        axios.post(address + `api/v1/articles/createArticle`, {
            "Name": 'Название новости',
            "Image": '',
            "Description": modalDescription,
            "Content": [],
            "ParentId": 107
        }, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            }
        }).then(res => {
            navigate('/news_builder/' + res.data?.Id)
        }).catch((error) => {
            alert(JSON.stringify(error))
        });
    }
    useEffect(() => {
        axios.get(address + 'api/v1/articles/getDirectory?id=107&page=1&pageSize=1000')
            .then(resMain => {
                setProducts(resMain.data["Articles"])
                let mem = []
                for (let i = 0; i < resMain.data["Articles"].length; i++) {
                    mem = [...mem, "preload"]
                }
                if (resMain.data["Articles"].length > 0) {
                    let firstNew = {}
                    axios.get(address + 'api/v1/articles/getArticle?id=' + resMain.data["Articles"][0].Id)
                        .then(res => {
                            if (res.data.Content?.length !== 0 && res.data.Content?.length !== undefined) {
                                console.log(res.data.Content[0])
                                firstNew["newsName"] = res.data.Content[0].newsName
                                firstNew["newsDescription"] = res.data.Content[0].newsDescription
                                if (res.data.Content[0]?.content) {
                                    firstNew["content"] = res.data.Content[0].content
                                } else {
                                    firstNew["content"] = [{ type: 'paragaph', children: [{ text: '' }], id: "0", },]
                                }
                                mem[0] = firstNew
                            }

                            if (resMain.data["Articles"].length > 1) {
                                let secondNew = {}
                                axios.get(address + 'api/v1/articles/getArticle?id=' + resMain.data["Articles"][1].Id)
                                    .then(res => {
                                        if (res.data.Content?.length !== 0 && res.data.Content?.length !== undefined) {
                                            console.log(res.data.Content[0])
                                            secondNew["newsName"] = res.data.Content[0].newsName
                                            secondNew["newsDescription"] = res.data.Content[0].newsDescription
                                            if (res.data.Content[0]?.content) {
                                                secondNew["content"] = res.data.Content[0].content
                                            } else {
                                                secondNew["content"] = [{ type: 'paragaph', children: [{ text: '' }], id: "0", },]
                                            }
                                            mem[1] = secondNew
                                        }

                                        setNewsPreload(mem)
                                    })
                            } else {
                                setNewsPreload(mem)
                            }
                        })
                } else {
                    setNewsPreload(mem)
                }
            })
    }, []);


    const loadNew = num => {
        if (products?.[num]?.Id) {
            axios.get(address + 'api/v1/articles/getArticle?id=' + products[num].Id)
                .then(res => {
                    let mem = [...newsPreload]
                    let firstNew = {}
                    if (res.data.Content?.length !== 0 && res.data.Content?.length !== undefined) {
                        console.log(res.data.Content[0])
                        firstNew["newsName"] = res.data.Content[0].newsName
                        firstNew["newsDescription"] = res.data.Content[0].newsDescription
                        if (res.data.Content[0]?.content) {
                            firstNew["content"] = res.data.Content[0].content
                        } else {
                            firstNew["content"] = [{ type: 'paragaph', children: [{ text: '' }], id: "0", },]
                        }
                        mem[num] = firstNew
                    }
                    setNewsPreload(mem)
                    // alert("lol")
                })
        }
    }

    const deleteProductHandler = (id) => {
        axios.post(address + `api/v1/articles/deleteArticle?id=` + id, {}, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            }
        }).then(res => {
        }).catch((error) => {
            alert("Ошибка удаления товара")
        });
    }

    useEffect(() => {
        // alert(currentItem)
        if (newsPreload[currentItem] === "preload" && !isListing) {
            loadNew(currentItem)
        }

        // alert(JSON.stringify(products[0].Id))

    }, [currentItem]);



    return (
        <div className={styles.justifier} >
            {isShowModalAddProduct &&
                <div className={styles.shadow} onClick={() => {
                    setIsShowModalAddProduct(false);
                    setModalName("");
                    setModalDescription("");
                }}>
                    <div style={{ color: 'white', display: 'flex', flexDirection: 'column' }} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.wrapProd2} style={{ background: '#C5E2F1' }}>
                            <div style={{ margin: 12 }} >
                                <div style={{ display: 'flex' }}>
                                    <div className={styles.labDescription} style={{ flex: 1 }}>
                                        <input
                                            value={modalDescription}
                                            style={{ background: 'rgba(0,0,0,0.0)' }}
                                            className={styles.inputDescriptionStyle}
                                            placeholder="Введите название новости"
                                            onChange={(e) => setModalDescription(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.createButtonWrapper} onClick={(e) => createHandler(e)}>
                            Создать
                        </div>
                    </div>
                </div>
            }
            <div style={{ width: 1920, position: 'relative' }} >
                <div>
                    <DoubleSidedGradient />
                    <div className={styles.greyHeader}>
                        <div className={styles.topTextBox}>
                            <div className={styles.topLeftText}>Новости сферы БПЛА</div>
                            <div className={styles.topRightText}>Здесь вы можете ознакомиться с самыми свежими новостями, связанными с деятельностью нашей компании в сфере разработки БПЛА. <br /> <br />
                                Узнать о новинках и новых разработках.</div>
                        </div>
                    </div>
                    <div className={styles.navigationWrapper}>
                        <div className={styles.navigationHeader}>
                            <div className={styles.textheadernavigation}>
                                <div className={styles.headerText}>
                                    Сводка новостей 
                                </div>
                                <div className={styles.subHeaderWrapper}>
                                    <div className={styles.subHeaderText}>
                                        Выберете интересующую Вас новость в нашем навигаторе
                                    </div>
                                    <div className={styles.newsIcon}>
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.6016 23.683C12.0432 23.7005 9.86684 22.8178 8.0726 21.035C6.27812 19.2522 5.38089 17.0845 5.38089 14.5319C5.38089 11.991 6.27083 9.83063 8.05073 8.05073C9.83063 6.27083 11.991 5.38089 14.5319 5.38089C17.0845 5.38089 19.2536 6.27959 21.0394 8.07698C22.8251 9.87413 23.7063 12.0519 23.683 14.6103L20.7514 13.6569C20.5326 12.1152 19.8328 10.833 18.6521 9.81021C17.4713 8.78743 16.0979 8.27604 14.5319 8.27604C12.7958 8.27604 11.3191 8.88465 10.1019 10.1019C8.88465 11.3191 8.27604 12.7958 8.27604 14.5319C8.27604 16.0979 8.78597 17.4699 9.80583 18.6477C10.8257 19.8256 12.1065 20.5268 13.6482 20.7514L14.6016 23.683ZM16.2407 28.9545C15.9559 29.0031 15.6725 29.0335 15.3905 29.0456C15.1088 29.0578 14.8226 29.0639 14.5319 29.0639C12.5362 29.0639 10.6557 28.6861 8.89037 27.9307C7.12505 27.1756 5.57971 26.1352 4.25432 24.8095C2.9287 23.4841 1.8883 21.9388 1.13312 20.1735C0.377708 18.4082 0 16.5277 0 14.5319C0 12.5119 0.377708 10.6253 1.13312 8.87214C1.8883 7.11898 2.9287 5.57971 4.25432 4.25432C5.57971 2.9287 7.12505 1.8883 8.89037 1.13313C10.6557 0.377709 12.5362 0 14.5319 0C16.552 0 18.4386 0.377709 20.1917 1.13313C21.9449 1.8883 23.4841 2.9287 24.8095 4.25432C26.1352 5.57971 27.1756 7.11898 27.9307 8.87214C28.6861 10.6253 29.0639 12.5119 29.0639 14.5319C29.0639 14.8226 29.0578 15.1103 29.0456 15.3949C29.0335 15.6798 29.0152 15.9675 28.9909 16.2582L26.1771 15.3778V14.5319C26.1771 11.3024 25.0436 8.55422 22.7766 6.28724C20.5096 4.02026 17.7614 2.88677 14.5319 2.88677C11.3024 2.88677 8.55422 4.02026 6.28724 6.28724C4.02026 8.55422 2.88677 11.3024 2.88677 14.5319C2.88677 17.7614 4.02026 20.5096 6.28724 22.7766C8.55422 25.0436 11.3024 26.1771 14.5319 26.1771C14.6651 26.1771 14.8046 26.171 14.9505 26.1589C15.0963 26.1467 15.2358 26.1406 15.369 26.1406L16.2407 28.9545ZM27.0586 29.6771L20.9453 23.5189L18.9678 28.75L14.5319 14.5319L28.7499 18.9678L23.5189 20.9089L29.6771 27.0586L27.0586 29.6771Z" fill="white" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.newsHeaderElementWrapper}>
                            {products.map((product, i) =>
                                <div key={product.toString()} className={styles.newsHeaderElement} onClick={() => {
                                    refs[i].scrollIntoView({ alignToTop: 'true', behavior: 'smooth' })
                                }} style={{ fontWeight: currentItem === i ? 'bold' : 'normal' }}>
                                    {product.Description}
                                </div>
                            )}
                        </div>
                    </div>
                    {/* {JSON.stringify(newsPreload)} */}
                    {sessionStorage.getItem('token') &&
                        <div onClick={() => setIsShowModalAddProduct(true)}>
                            <NewsUavAdd />
                        </div>
                    }
                    <div key={JSON.stringify(newsPreload)} style={sessionStorage.getItem('token')?{ marginTop: 50 }:{ marginTop: -100 }} >
                        {newsPreload.map((news, i) =>
                            <div ref={ref => refs[i] = ref}>
                                <InView as="div" threshold={0.3} onChange={(inView) => {
                                    if (inView) {
                                        setCurrentItem(i)
                                    }
                                    console.log("Inview:", inView)
                                }}>
                                    {news === "preload" &&
                                        <NewsPreloader id={products?.[i]?.Id} deliteHandler={() => {
                                            deleteProductHandler(products?.[i]?.Id)
                                            setNewsPreload(newsPreload.filter((y, j) => i !== j))
                                        }} />
                                    }
                                    {news !== "preload" &&
                                        <div>
                                            <NewsHeader text1={news["newsName"]} text2={news["newsDescription"]} id={products?.[i]?.Id}
                                                deliteHandler={() => {
                                                    deleteProductHandler(products?.[i]?.Id)
                                                    setNewsPreload(newsPreload.filter((y, j) => i !== j))
                                                    setProducts(products.filter((y, j) => i !== j))
                                                }} isLong={i===currentItem}/>
                                            <div className={styles.justifier} style={{ marginTop: 50 }}>
                                                <div className={styles.viewWrapper}>
                                                    <SlateRenderer content={JSON.stringify(news["content"])} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {i !== newsPreload.length - 1 &&
                                        <>
                                            <div style={{ height: 50 }} />
                                            <hr align="start" size="2" color="#736D8C" style={{ opacity: 0.4, width: "200vw", position: 'absolute', left: "-50vw" }} />
                                            <div style={{ height: 50 }} />
                                        </>
                                    }

                                </InView>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </div >
    );
}

export default Uav;
