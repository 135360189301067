import styles from './Header.module.css'
import { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logoPolus from '../../Images/Regular_size/PolLOgo.svg';
import convert from '../../Images/Regular_size/convert.svg';
import search from '../../Images/Regular_size/search.svg';
import menu from '../../Images/Regular_size/menu.svg';
import X from '../../Images/Regular_size/X.svg';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useStore } from "effector-react";
import axios from "axios";
import { addressStore } from "../Store/ConfigStore";

function Header() {
    const address = useStore(addressStore);
    const [showMenu, setShowMenu] = useState(false);
    const [searchList, setSearchList] = useState([]);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isMiniSearchOpen, setIsMiniSearchOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [width, setWidth] = useState(window.innerWidth); // тут хранится ширина экрана
    const inputSearch = document.querySelector('#inputSearch');

    useEffect(() => { // хук отслеживает изменение ширины экрана
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const location = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        if (width > 652) {
            setShowMenu(false)
        }
        if (width <= 652) {
            setIsSearchOpen(false)
            setSearchText("")
        }
    }, [width]);

    const searchHandler = () => {
        axios.get(address + 'api/v1/articles/search?term=' + searchText + '&page=1&pageSize=5')
            .then(res => {
                console.log(res.data["Articles"].map((x) => {
                    return { name: x.Description, type: (x.ParentId === 1) ? "Новость" : "Продукт", "Id": x.Id }
                }))
                //alert(JSON.stringify(res.data))
                setSearchList(res.data["Articles"].map((x) => {
                    return { name: x.Description, type: (x.ParentId === 1) ? "Новость" : "Продукт", "Id": x.Id }
                }))
            })
        // setSearchList([{ name: "Приказ 804", type: "Страница сайта" }, { name: "О приказе 804", type: "Новости" }, { name: "Интерактивная панель Newline TT-7519RS/RU с настенным креплением и OPS", type: "Товар" }])
    }

    const onKeyDown = e => {
        if (e.key === 'Enter')
            searchHandler(searchText)
    }


    const searchClose = () => {
        setIsSearchOpen(false);
        setSearchText("");
    }

    const rootEl = useRef(null);
    useEffect(() => {
        if (width > 650) {
            const onClick = e => rootEl.current.contains(e.target) || searchClose();
            document.addEventListener('click', onClick);
            return () => document.removeEventListener('click', onClick);
        }

    }, [width]);

    useEffect(() => {
        setTimeout(function tick() {
            if (showMenu === false) {
                setIsMiniSearchOpen(false)
                setSearchText("")
            }
        }, 300);

    }, [showMenu]);

    useEffect(() => {
        setTimeout(function tick() {
            if (!isSearchOpen || !isMiniSearchOpen) {
                setSearchList([])
            }
        }, 300);
    }, [isSearchOpen, isMiniSearchOpen]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {width > 650 &&
                <>
                    <div style={{ width: (width > 1000) ? 1110 : 636, marginRight: 20, marginLeft: 20 }}>
                        <div>
                            <div style={{ display: 'flex' }}>
                                {width <= 1000 &&
                                    <div onClick={() => navigate("/")} style={{ cursor: 'pointer' }}>
                                        <LazyLoadImage
                                            src={logoPolus}
                                            height={30}
                                            width={70}
                                            effect="blur"
                                        />
                                    </div>

                                }
                                <div style={{ flex: 1 }} />
                                {width > 636 &&
                                    <>
                                        <LazyLoadImage
                                            src={convert}
                                            effect="blur"
                                        />
                                        <div style={{ marginLeft: 14, color: " #201644" }}>
                                            <span class="notranslate" translate="no" onClick={() => navigator.clipboard.writeText('polus@tkpolus.ru')}>polus@tkpolus.ru</span>
                                        </div>
                                    </>
                                }
                                <div style={{ marginLeft: 36, color: "#201644" }}>
                                    <span class="notranslate" translate="no">+7 (3822) 528-999</span>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                {width > 1000 &&
                                    <div style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                        <div onClick={() => navigate("/")} style={{ cursor: 'pointer' }}>
                                            <LazyLoadImage
                                                src={logoPolus}
                                                height={30 * 1.9}
                                                width={70 * 1.9}
                                                effect="blur"
                                            />
                                        </div>
                                    </div>
                                }
                                <div className={styles.headerWrapper} style={width <= 1000 ? { flex: 1 } : {}}>
                                    <div style={{ display: 'flex', flex: 1 }}>
                                        <NavLink to="/" className={styles.headerElement} style={location.pathname.split('/')[1] === '' ? { background: '#D8D8D8' } : {}}>
                                            <span class="notranslate" translate="no">Главная</span>
                                        </NavLink>
                                        <NavLink to="/news" className={styles.headerElement} style={location.pathname.split('/')[1] === 'news' ? { background: '#D8D8D8' } : {}}>
                                            <span class="notranslate" translate="no">Новости</span>
                                        </NavLink>
                                        {sessionStorage.getItem('token') &&
                                            <NavLink to="/uav" className={styles.headerElement} style={location.pathname.split('/')[1] === 'uav' ? { background: '#D8D8D8' } : {}}>
                                                <span class="notranslate" translate="no">БПЛА</span>
                                            </NavLink>
                                        }
                                        <NavLink to="/products" className={styles.headerElement} style={location.pathname.split('/')[1] === 'products' ? { background: '#D8D8D8' } : {}}>
                                            <span class="notranslate" translate="no">Продукты</span>
                                        </NavLink>
                                        <NavLink to="/about" className={styles.headerElement} style={location.pathname.split('/')[1] === 'about' ? { background: '#D8D8D8' } : {}}>
                                            <span class="notranslate" translate="no">О нас</span>
                                        </NavLink>
                                        <NavLink to="/contact" className={styles.headerElement} style={location.pathname.split('/')[1] === 'contact' ? { background: '#D8D8D8' } : {}}>
                                            <span class="notranslate" translate="no">Контакты</span>
                                        </NavLink>

                                    </div>
                                    <div ref={rootEl} style={{ position: 'relative' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', marginLeft: "30px", cursor: 'pointer' }} onClick={() => setIsSearchOpen(!isSearchOpen)}>
                                            <LazyLoadImage
                                                onClick={() => { inputSearch.focus() }}
                                                src={search}
                                                effect="blur"
                                            />
                                        </div>
                                        <div className={styles.searchWrapper} style={isSearchOpen ? { opacity: 100, transition: "0.3s" } : { opacity: 0, pointerEvents: 'none', transition: "0.3s" }}>
                                            <div style={{ position: 'relative' }}>
                                                <input
                                                    id="inputSearch"
                                                    autoFocus={true}
                                                    onKeyDown={onKeyDown}
                                                    value={searchText}
                                                    className={styles.searchStyle}
                                                    onChange={(e) => {
                                                        setSearchText(e.target.value)
                                                    }}
                                                    placeholder={"Поиск по сайту..."}
                                                />
                                                {searchList.length > 0 &&
                                                    <div className={styles.searchListWrapper}>
                                                        <div style={{ height: 40 }} />
                                                        {searchList.map((x, i) => {
                                                            return (
                                                                <div key={i} className={styles.searchListElementWrapper} onClick={() => {

                                                                    if (x["type"] === "Новость") {
                                                                        navigate('/news_view/' + x["Id"])
                                                                        window.location.reload()
                                                                    } else {
                                                                        navigate('/product_page/' + x["Id"])
                                                                        window.location.reload()
                                                                    }
                                                                }}>
                                                                    <div className={styles.searchListElementText1} style={{ flex: 3 }}>
                                                                        {x["name"]}
                                                                    </div>
                                                                    <div className={styles.searchListElementText2} style={{ flex: 1, display: 'flex', alignItems: 'flex-start' }}>
                                                                        {x["type"]}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                        <div style={{ height: 20 }} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 10, border: "1px solid rgba(115, 109, 140, 0.22)" }} />
                    </div>
                </>
            }

            {width <= 650 &&
                <>
                    {(width <= 650 && width > 290) &&
                        <div className={styles.smallHeaderWrapper}>
                            <div style={{ marginLeft: 18, height: 60, display: "flex", alignItems: 'center', cursor: 'pointer' }} >
                                <div onClick={() => navigate("/")}>
                                    <LazyLoadImage
                                        src={logoPolus}
                                        height={30 * 1.2}
                                        width={70 * 1.2}
                                        effect="blur"
                                    />
                                </div>

                                <div style={{ flex: 1 }} />
                                <div className={styles.textPhone}>
                                    <span class="notranslate" translate="no">+7 (3822) 528-999</span>
                                </div>
                                <div style={{ marginRight: 13, height: 60, display: "flex", alignItems: 'center' }} onClick={() => setShowMenu(!showMenu)}>
                                    <LazyLoadImage
                                        src={showMenu ? X : menu}
                                        height={showMenu ? 22 : 30}
                                        width={showMenu ? 22 : 24}
                                        effect="blur"
                                    />
                                </div>
                            </div>
                            <div style={{ position: 'relative' }}>
                                <div className={styles.shadow} onClick={() => setShowMenu(false)} style={showMenu ? { opacity: 100, height: document.documentElement.scrollHeight } : { opacity: 0, pointerEvents: 'none', height: document.documentElement.scrollHeight }} />
                                <div className={styles.menuWrapper} style={showMenu ? { opacity: 100 } : { opacity: 0, pointerEvents: 'none' }}>
                                    <div style={{ marginLeft: 30, marginTop: 40 }}>
                                        <NavLink to="/" className={styles.menuElement} onClick={() => setShowMenu(false)} style={location.pathname.split('/')[1] === '' ? { background: '#D8D8D8' } : {}}>
                                            <div className={styles.menuElementText}>Главная</div>
                                        </NavLink>
                                        <NavLink to="/news" className={styles.menuElement} onClick={() => setShowMenu(false)} style={location.pathname.split('/')[1] === 'news' ? { background: '#D8D8D8' } : {}}>
                                            <div className={styles.menuElementText}>Новости</div>
                                        </NavLink>
                                        <NavLink to="/products" className={styles.menuElement} onClick={() => setShowMenu(false)} style={location.pathname.split('/')[1] === 'products' ? { background: '#D8D8D8' } : {}}>
                                            <div className={styles.menuElementText}>Продукты</div>
                                        </NavLink>

                                        <NavLink to="/about" className={styles.menuElement} onClick={() => setShowMenu(false)} style={location.pathname.split('/')[1] === 'about' ? { background: '#D8D8D8' } : {}}>
                                            <div className={styles.menuElementText}>О нас</div>
                                        </NavLink>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <NavLink to="/contact" className={styles.menuElement} onClick={() => setShowMenu(false)} style={location.pathname.split('/')[1] === 'contact' ? { background: '#D8D8D8' } : {}}>
                                                <div className={styles.menuElementText}>Контакты</div>
                                            </NavLink>
                                            <div style={(isMiniSearchOpen) ? { marginLeft: 120, opacity: 0 } : { marginLeft: 120, opacity: 100, cursor: "pointer" }} onClick={() => setIsMiniSearchOpen(true)}>
                                                <LazyLoadImage
                                                    src={search}
                                                    effect="blur"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.searchWrapperMini} style={showMenu && isMiniSearchOpen ? { opacity: 100, transition: "0.3s" } : { opacity: 0, transition: "0.3s", pointerEvents: 'none' }}>
                                        <div style={{ position: 'relative', zIndex: 100 }}>
                                            <input
                                                onKeyDown={onKeyDown}
                                                value={searchText}
                                                style={{ width: 320 }}
                                                className={styles.searchStyleMini}
                                                onChange={(e) => {
                                                    setSearchText(e.target.value)
                                                }}
                                                placeholder={"Поиск по сайту..."}
                                            />
                                            {searchList.length > 0 &&
                                                <div className={styles.searchListWrapper} >
                                                    <div style={{ height: 40 }} />
                                                    {searchList.map((x, i) => {
                                                        return (
                                                            <div key={i} className={styles.searchListElementWrapper} onClick={() => {
                                                                if (x["Id"] === 1) {
                                                                    navigate('/news_view/' + x["Id"])
                                                                    window.location.reload()
                                                                } else {
                                                                    navigate('/product_page/' + x["Id"])
                                                                    window.location.reload()
                                                                }
                                                            }}>
                                                                <div className={styles.searchListElementText1} style={{ flex: 3 }}>
                                                                    {x["name"]}
                                                                </div>
                                                                <div className={styles.searchListElementText2} style={{ flex: 2, display: 'flex', alignItems: 'flex-start' }}>
                                                                    {x["type"]}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                    <div style={{ height: 20 }} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                    {(width <= 290) &&
                        <div className={styles.smallHeaderWrapper}>
                            <div style={{ height: 60, display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                                <LazyLoadImage
                                    src={menu}
                                    height={30}
                                    width={24}
                                    effect="blur"
                                />
                            </div>
                        </div>
                    }
                </>
            }

        </div >
    );
}

export default Header;
