import styles from './PageBuilder.module.css'
import { useParams } from "react-router";
import { useLayoutEffect, useRef, useEffect, useState } from "react";
import PageSettings from './PageSettings/PageSettings';
import PageContent from './PageContent/PageContent';
import { useStore } from "effector-react";
import { addressStore } from "../../Components/Store/ConfigStore";
import axios from 'axios';

function PageBuilder() {
    let { id } = useParams();
    const address = useStore(addressStore);
    const [page, setPage] = useState("settings") // content
    const [pageData, setPageData] = useState({})
    const [isLoad, setIsLoad] = useState(false)
    let fix = true;
    useEffect(() => {
        fix = !fix
        if (fix) return
        axios.get(address + 'api/v1/articles/getArticle?id=' + id)
            .then(res => {
                setPageData(res.data)
                setIsLoad(true)
            })
    }, [])
    return (
        <div style={{ display: 'flex' }}>
            {isLoad &&
                <>
                    <div style={{ flex: 1, minWidth: 300 }}>
                        <div className={styles.buttonWrapper} style={page === "settings" ? { background: "#F0F8FF" } : {}} onClick={() => setPage("settings")}>
                            Общие настройки
                        </div>
                        <div className={styles.buttonWrapper} style={page === "content" ? { background: "#F0F8FF" } : {}} onClick={() => setPage("content")}>
                            Содержимое
                        </div>
                        {/* <div className={styles.buttonBox}>
                            <div className={styles.buttonWrapper}>
                                Текст
                            </div>
                            <div className={styles.buttonWrapper}>
                                Изображение
                            </div>
                            <div className={styles.buttonWrapper}>
                                Карусель
                            </div>
                            <div className={styles.buttonWrapper}>
                                Видео
                            </div>
                        </div> */}
                    </div>
                    <div style={{ flex: 5 }}>
                        {page === "settings" && <PageSettings pageData={pageData} address={address} setPageData={setPageData}/>}
                        {page === "content" && <PageContent  pageData={pageData} address={address}/>}
                    </div>
                </>
            }
            {!isLoad &&
            <div>Загрузка...</div>
            }

        </div>
    );
}


export default PageBuilder;
