import styles from './StackElement.module.css'

function StackElement(props) {
    return (
        <div className={styles.wrapElement}>
            <div className={styles.imgWrapper}>
                <img src={props.img} />
            </div>
            <div className={styles.textStyle}>
                {props.text}
            </div>
        </div>
    );
}


export default StackElement;
