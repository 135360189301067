import Header from "../Header/Header";
import about from '../../Images/Regular_size/aboutsplash.png';
import aboutCompressed from '../../Images/Compressed_Image/aboutsplash.png';
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from './About.module.css'
import AboutScrollBlock from "./AboutScrollBlock/AboutScrollBlock";
import AboutPartnerBlock from "./AboutPartnerBlock/AboutPartnerBlock";
import slideLeft from "../../Images/Regular_size/slideLeft.svg";
import slideRight from "../../Images/Regular_size/slideRight.svg";
import { useRef, useState, useEffect } from "react";
import polymedia from "../../Images/Regular_size/polymedia.png";
import digis from "../../Images/Regular_size/digis.png";
import applied_robotics from "../../Images/Regular_size/applied_robotics.png";
import edustrong from "../../Images/Regular_size/edustrong2.png";
import three_logic from "../../Images/Regular_size/3logic_group.png";
import knowledge_cloud from "../../Images/Regular_size/knowledge_cloud.svg";
import { useNavigate } from "react-router-dom";
import casioCert from "../../Images/Regular_size/casioCert.jpg";
import messageCert from "../../Images/Regular_size/messageCert.jpg";
import polymediaCert from "../../Images/Regular_size/polymediaCert.jpg";
import repairCert from "../../Images/Regular_size/repairCert.jpg";
import armyCert from "../../Images/Regular_size/armyCert.jpg";
import licenzNaKosm from "../../Images/Regular_size/licenzNaKosm.webp";
import fsi from "../../Images/Regular_size/fsi.webp";
import sertKakoyTo1 from "../../Images/Regular_size/sertKakoyTo1.webp";
import sertKakoyTo2 from "../../Images/Regular_size/sertKakoyTo2.webp";
import inter from "../../Images/NCpolus.mp4"
import mmco from "../../Images/mmco.mp4"
import logo from "../../Images/Regular_size/logo.jpg";
import mmcaLogo from "../../Images/Regular_size/mmcaLogo.png";
import SIG_STAMP_1 from "../../Images/Regular_size/SIG_STAMP_1.webp";
import SIG_STAMP_2 from "../../Images/Regular_size/SIG_STAMP_2.webp";

import it1 from "../../Images/Regular_size/it1.webp";
import it2 from "../../Images/Regular_size/it2.webp";
import pismo from "../../Images/Regular_size/pismo.webp";
import techHarakter from "../../Images/Regular_size/techHarakter.webp";
import znakCach from "../../Images/Regular_size/znakCach.webp";
import techHarakterRotate from "../../Images/Regular_size/techHarakterRotate.webp";
import znakCachRotate from "../../Images/Regular_size/znakCachRotate.webp";
import poluslabdisp from "../../Images/Regular_size/poluslabdisp.webp";


function About() {
    let navigate = useNavigate();
    const aboutRef = useRef(null);
    const prosRef = useRef(null);
    const partnersRef = useRef(null);
    const ref = useRef(null);
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className={styles.holder}>
            <div className={styles.wrapper}>
                <LazyLoadImage
                    src={about}
                    placeholderSrc={aboutCompressed}
                    effect="blur"
                    className={styles.splash}
                />

                {width <= 768 && <div className={styles.adoptiveMenuWrap}>
                    <div className={styles.topLeftTabHeader} onClick={() => { aboutRef.current.scrollIntoView({ behavior: 'smooth' }) }}>
                        О компании
                    </div>
                    <div className={styles.topLeftTabText} onClick={() => { prosRef.current.scrollIntoView({ behavior: 'smooth' }) }}>
                        Наши преимущества
                    </div>
                    <div className={styles.topLeftTabText} onClick={() => { partnersRef.current.scrollIntoView({ behavior: 'smooth' }) }}>
                        Партнеры
                    </div>
                </div>}
                <div className={styles.topTabs}>
                    {width > 768 &&
                        <div className={styles.topLeftTab}>
                            <div className={styles.topLeftTabAligner}>
                                <div className={styles.topLeftTabHeader} onClick={() => { aboutRef.current.scrollIntoView({ behavior: 'smooth' }) }}>
                                    О компании
                                </div>
                                <div className={styles.topLeftTabText} onClick={() => { prosRef.current.scrollIntoView({ behavior: 'smooth' }) }}>
                                    Наши преимущества
                                </div>
                                <div className={styles.topLeftTabText} onClick={() => { partnersRef.current.scrollIntoView({ behavior: 'smooth' }) }}>
                                    Партнеры
                                </div>
                                <div className={styles.topLeftTabText} onClick={() => { navigate("/contact"); window.scrollTo(0, 0) }}>
                                    Обратная связь
                                </div>
                            </div>
                        </div>
                    }

                    <div className={styles.topRightTab}>
                        <div className={styles.topRightTabHeader} ref={aboutRef}>
                            О нашей компании
                        </div>
                        <div className={styles.topRightTabText}>
                            ООО «Торговая компания «Полюс» с гордостью представляет Вам более чем 20-летний опыт в поставках и установке компьютерного оборудования в учебные заведения по всей стране. Теперь, благодаря нашим инновационным разработкам, мы стали производителями цифровых лабораторий, дронов и роботов.<br /><br />
                            В 2023 году мы выпустили линейку уникальных по своей разработке цифровых лабораторий PolusLab в двух вариантах – для оборудования школ по национальному проекту «Точка роста» и, следом, кабинетные цифровые лаборатории, которые комплектуются максимально оптимальным набором встроенных и внешних датчиков. При использовании сразу нескольких датчиков можно не только полноценно проводить практические занятия на уроках, но и создавать вариативность в создании учеником собственных проектов.<br /><br />
                            Особенность наших цифровых лабораторий является автономный мультидатчик с цветным сенсорным экраном. Благодаря этому, появилась возможность проводить исследования не только в помещениях, таких как кабинет, но и на улице, что увеличивает интерес ученика. На сегодняшний день лаборатории поставлены в более 20 регионов страны.<br /><br />
                            В начале 2024 года цифровые лаборатории PolusLab сделали важный шаг в своем развитии: пять наших ключевых продуктов (физика, химия, биология, экология и физиология) были внесены в реестр Минпромторга.<br /><br />
                            Продолжая свои разработки, мы создали продукты для образовательных комплексов БАС: линейку образовательных дронов и комплекты образовательной робототехники. В 2024 году в рамках Проектно-образовательного интенсива «Архипелаг 2024» мы стали соорганизаторами двух соревнований: Аддитивная фабрика БАС и Автономные дроны: миссия. В данных соревнованиях использовались наши Образовательные БПЛА PL-DK-EDU-03, которые участники собирали, программировали и использовали на практике в режимах ручного и автономного полета.<br /><br />
                            Образовательный БПЛА с элементами ИИ прошел экспертизу в Фонде содействия развитию малых форм предприятий в научно-технической сфере, что позволило подтвердить статус малой технологической компании (МТК) в апреле 2024 года.<br /><br />
                            Методические пособия к нашим продуктам создаются учителями Томской области, а виртуальные практикумы, которые внесены в реестр российского ПО, дополнены разработками компаний «Физикон» и «ОС3».<br /><br />
                            Дорогие клиенты и партнеры! Теперь, когда мы расширили нашу деятельность до производства собственных продуктов, готовы предложить Вам еще больше возможностей для успешного обучения детей в ваших учебных заведениях. Мы всегда готовы предложить нашим клиентам последние новинки на рынке информационных технологий, и стремимся обеспечить полную поддержку всей IT-инфраструктуры наших заказчиков.
                        </div>
                    </div>
                </div>
                <div className={styles.tabMargin} style={{ display: 'flex', justifyContent: 'start', marginTop: 20 }}>
                    <video className={styles.trailer} style={{ marginRight: 20, marginLeft: 40 }} controls="controls" poster={logo} >
                        <source src={inter} type='video/webm; codecs="vp8, vorbis"' />
                        Тег video не поддерживается вашим браузером.
                        <a href={inter}>Скачайте видео</a>.
                    </video>
                    <video className={styles.mmco} controls="controls" poster={mmcaLogo} >
                        <source src={mmco} type='video/webm; codecs="vp8, vorbis"' />
                        Тег video не поддерживается вашим браузером.
                        <a href={mmco}>Скачайте видео</a>.
                    </video>
                </div>
                <div className={styles.tabMargin} style={{ marginTop: 150 }}>
                    <div>
                        <div className={styles.achievementHeader} ref={prosRef}>
                            Наши преимущества
                        </div>
                    </div>
                    <div className={styles.achievementHolder}>
                        <div className={styles.achievementBlock}>
                            <div className={styles.achievementBlockHeader}>
                                20+ лет
                            </div>
                            <div className={styles.achievementBlockText}>
                                Успешной<br />
                                работы
                            </div>
                        </div>
                        <div className={styles.achievementBlock}>
                            <div className={styles.achievementBlockHeader}>
                                {">25"}
                            </div>
                            <div className={styles.achievementBlockText}>
                                Оборудованных<br />
                                школ
                            </div>
                        </div>
                        <div className={styles.achievementBlock}>
                            <div className={styles.achievementBlockHeader}>
                                50+
                            </div>
                            <div className={styles.achievementBlockText}>
                                Заслуженных<br />
                                наград
                            </div>
                        </div>
                        <div className={styles.achievementBlock}>
                            <div className={styles.achievementBlockHeader}>
                                10+
                            </div>
                            <div className={styles.achievementBlockText}>
                                Регионов в<br />
                                партнерстве
                            </div>
                        </div>
                    </div>
                </div>


                <div className={styles.certificateHolder}>
                    <svg width="10" height="580" viewBox="0 0 10 580" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.certificateSidebar + " " + styles.tabMargin}>
                        <path d="M5 580C2.23858 580 0 577.761 0 575L0 0C5.52285 0 10 4.47717 10 10L10 575C10 577.761 7.76142 580 5 580Z" fill="#0092DD" />
                    </svg>
                    <div className={styles.certificateWrapper}>
                        <div className={styles.certificateHeader}>
                            За время активной деятельности компании, наше портфолио достижений стало обширной галереей наград <br />
                            и сертификатов разного уровня.
                        </div>
                        <div className={styles.certificateScroller} ref={ref}>
                            <AboutScrollBlock image={poluslabdisp} placeholder={poluslabdisp} />
                            <AboutScrollBlock image={it1} placeholder={it1} />
                            <AboutScrollBlock image={it2} placeholder={it2} />
                            <AboutScrollBlock image={pismo} placeholder={pismo} />
                            <AboutScrollBlock image={znakCachRotate} placeholder={znakCach} />
                            <AboutScrollBlock image={techHarakterRotate} placeholder={techHarakter} />
                            <AboutScrollBlock image={SIG_STAMP_1} placeholder={SIG_STAMP_1} />
                            <AboutScrollBlock image={SIG_STAMP_2} placeholder={SIG_STAMP_2} />
                            <AboutScrollBlock image={armyCert} placeholder={armyCert} />
                            {/* <AboutScrollBlock image={fsi} placeholder={fsi} /> */}
                            <AboutScrollBlock image={messageCert} placeholder={messageCert} />
                            {/* <AboutScrollBlock image={polymediaCert} placeholder={polymediaCert} /> */}
                            <AboutScrollBlock image={sertKakoyTo1} placeholder={sertKakoyTo1} />
                            <AboutScrollBlock image={sertKakoyTo2} placeholder={sertKakoyTo2} />
                            {/* <AboutScrollBlock image={repairCert} placeholder={repairCert} />
                            <AboutScrollBlock image={licenzNaKosm} placeholder={licenzNaKosm} /> */}


                        </div>
                        <div className={styles.certificateScrollButtons}>
                            <LazyLoadImage
                                src={slideLeft}
                                effect="blur"
                                className={styles.scrollLeft}
                                onClick={() => { ref.current.scrollLeft -= width * (-0.00032734375000000005 * width + 0.8014000000000001) }}
                            />
                            <LazyLoadImage
                                src={slideRight}
                                effect="blur"
                                className={styles.scrollLeft}
                                onClick={() => { ref.current.scrollLeft += width * (-0.00032734375000000005 * width + 0.8014000000000001) }}
                            />
                        </div>
                    </div>
                </div>


                <div className={styles.partnersWrapper + " " + styles.tabMargin}>
                    <div>
                        <div className={styles.partnersHeader} ref={partnersRef}>
                            Наши партнеры
                        </div>
                        <div className={styles.partnersBox}>
                            <AboutPartnerBlock link={"https://appliedrobotics.ru/"} image={applied_robotics} placeholder={slideLeft} text={"Представитель ROBOTIS Ltd. в России и официальный представитель международного движения STEAM CUP."} />
                            <AboutPartnerBlock link={"https://digis.ru/"} image={digis} placeholder={slideLeft} text={"ГК DIGIS объединяет ряд компаний, специализирующихся на дистрибуции широкого спектра оборудования, аудио и видео-интеграции, проектной деятельности и реализации различных инженерных компетенций."} />
                            <AboutPartnerBlock link={"https://3logic.ru/"} image={three_logic} placeholder={slideLeft} text={"3Logic Group — ведущая ИТ-компания полного цикла. Дистрибьюция компьютерных комплектующих и ИТ-оборудования."} />
                            <AboutPartnerBlock link={"https://oblakoz.ru"} image={knowledge_cloud} placeholder={slideLeft} text={"Облако знаний - образовательный онлайн-сервис с тысячами интерактивных работ."} />
                            <AboutPartnerBlock link={"https://www.polymedia.ru/"} image={polymedia} placeholder={slideLeft} text={"Polymedia – аккредитованная и системообразующая ИТ-компания, ведущий системный интегратор и дистрибьютор, признанный на международном рынке."} />
                            <AboutPartnerBlock link={"https://stronikum.ru/"} image={edustrong} placeholder={slideLeft} text={"ООО «Строникум» занимается разработкой и поставками учебно-наглядных пособий и учебного оборудования для детских садов, школ и профессиональных училищ."} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
