
import { useLayoutEffect, useRef, useState, useMemo, useEffect } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Editable, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { createEditor, Transforms } from "slate";
import {useParams} from "react-router";

import axios from "axios";
import { useStore } from "effector-react";
import { addressStore } from "../../Store/ConfigStore";
import styles from './PreviewEditor.module.css'


function PreviewEditor(props) {
    const [modalImage, setModalImage] = useState("");
    const [modalName, setModalName] = useState("");
    const [modalDescription, setModalDescription] = useState("");
    const address = useStore(addressStore);
    const [width, setWidth] = useState(window.innerWidth);
    let {id} = useParams();
    useEffect(() => { // хук отслеживает изменение ширины экрана
        axios.get(address + 'api/v1/articles/getArticle?id=' + id)
                .then(res => {
                    setModalName(res.data.Name)
                    setModalImage(res.data.Image)
                    setModalDescription(res.data.Description)
                })
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const saveHandler = () => {
        axios.post(address + `api/v1/articles/updateArticle?id=`+id, {
            "Name": modalName,
            "Image": modalImage,
            "Description": modalDescription,
            "Content": null,
            "ParentId": null
        }, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            }
        }).then(res => {
            alert("Данные успешно сохранены")
        }).catch((error) => {
            alert(JSON.stringify(error))
        });
    }

    const changeHandler = (e) => {

        const file = e.target.files[0];
        //if (!file.type.match(imageMimeType)) {
        // alert("kek")
        axios.post(address + `api/v1/files/upload`, file, {
            headers: {
                'Content-Type': "image/png",
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            }
        }).then(res => {
            setModalImage(res.data.FileName)
            // alert(JSON.stringify(address + "uploads/" +res.data.FileName))
        }).catch((error) => {
            // alert(JSON.stringify(error))
            alert("Надо авторизироваться")
        });
        return;
        // }
    }

    return (
        
        <div style={width > 1920?{display:'flex', justifyContent:'center'}:{}}>
            <div style={width > 1920 ? { width: 1920, overflow: 'hidden' } : { overflow: 'hidden' }}>
                <div className={styles.headerProduct}>
                    <div style={{ flex: 1 }}>
                        <div className={styles.buttonWrapper} onClick={() => props.setIsPreviewEditor(false)}>
                            Вернуться
                        </div>
                    </div>
                    <div style={{ flex: "1000px", display: 'flex', justifyContent: 'center' }}>
                    <div style={{width: width > 1920 ? 240 : width > 768 ? 0.05128205128205128 * width + 141.53846153846155 : 0.13480392156862744 * width + 111.47058823529412}}>
                    <div className={styles.wrapProd2}
                        style={{ width: width > 1920 ? 240 : width > 768 ? 0.05128205128205128 * width + 141.53846153846155 : 0.13480392156862744 * width + 111.47058823529412, height: width > 1920 ? 292 : width > 768 ? 0.04935897435897436 * width + 197.23076923076923 : (0.024509803921568627 * width + 206.1764705882353 + 20) }}>
                        <div style={{ margin: 12 }} >
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: width > 1920 ? 210 : 0.04487179487179487 * width + 123.84615384615384, height: width > 1920 ? 204 : 0.041025641025641026 * width + 125.23076923076923 }}>
                                    <form style={{ cursor: "pointer", cursor: 'pointer' }}
                                        method="post" encType="multipart/form-data">
                                        <label className="input-file" style={{ cursor: 'pointer' }}>
                                            <input style={{ cursor: 'pointer', position: 'absolute', opacity: 0 }} type="file" name="file" id='image'
                                                accept='.png, .jpg, .jpeg'
                                                onChange={changeHandler}
                                            />
                                            <div >
                                                <LazyLoadImage
                                                    src={address + "uploads/" + modalImage}
                                                    effect="blur"
                                                    width={width > 1920 ? 210 : 0.04487179487179487 * width + 123.84615384615384}
                                                    height={width > 1920 ? 204 : 0.041025641025641026 * width + 125.23076923076923}
                                                />
                                            </div>
                                        </label>
                                    </form>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div className={styles.labDescription} style={{ flex: 1 }}>
                                    {/* {x["Description"]} */}
                                    <input
                                        value={modalDescription}
                                        className={styles.inputStyle}
                                        style={{background:"rgb(197, 226, 241, 0)"}}
                                        placeholder="Введите описание"
                                        onChange={(e) => setModalDescription(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.createButtonWrapper} onClick={() => saveHandler()}>
                        Сохранить
                    </div>
                </div>
                    </div>
                    <div style={{ flex: 1 }}>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PreviewEditor;
