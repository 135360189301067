import FooterFooter from './FooterFooter/FooterFooter';
import FooterHeader from './FooterHeader/FooterHeader';

function Footer() {
    return (
        <div style={{background:"linear-gradient(180deg, rgba(246, 246, 246, 0) 0%, rgba(157, 158, 164, 0.1) 100%), #F6F6F6"}}>
            <FooterHeader />
            <FooterFooter />
        </div>
    );
}

export default Footer;
