import styles from './TransparentButton.module.css'
import { useState } from 'react';

function TransparentButton(props) {
    const [isMouseOverButton, setIsMouseOverButton] = useState(false);

    return (
        <div style={{ position: 'relative', height: 40 }}>
            <div className={styles.buttonWrapper} style={!isMouseOverButton ? { width: props.width } : { filter: "drop-shadow(4px 4px 4px rgba(89, 116, 130, 0.25))", width: props.extension }} onMouseOver={() => setIsMouseOverButton(true)}
                onMouseLeave={() => setIsMouseOverButton(false)}>
                <div className={styles.buttonText} style={{ display: 'flex', marginLeft: 30 }}>
                    {props.text}
                </div>
                <div style={{ flex: 1 }} />
                <div style={{ display: 'flex', marginRight: 30 }}>
                    <svg width="31" height="7" viewBox="0 0 31 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.3333 3.4747C25.3366 4.94746 26.5332 6.1387 28.006 6.13541C29.4787 6.13212 30.6699 4.93555 30.6667 3.4628C30.6634 1.99004 29.4668 0.798802 27.994 0.80209C26.5213 0.805377 25.3301 2.00195 25.3333 3.4747ZM0.00111607 4.03125L28.0011 3.96875L27.9989 2.96875L-0.00111607 3.03125L0.00111607 4.03125Z" fill="#0092DD" />
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default TransparentButton;
