import React from 'react'
import styles from './Button.module.css'
const Button =(props)=>{
    const {children,format,active, ...rest} = props
    return(
        <button  className={active?styles.btnActive:''} title={format}  {...rest} style={{width:'auto',height:'20px',margin:'0 2px'}}>
            {children}
        </button>
    )
}

export default Button;