import { LazyLoadImage } from 'react-lazy-load-image-component';

import styles from './ImageLinksViewer.module.css'

function ImageLinksViewer({productLinks}) {

    return (
        <div className={styles.imageLinksEditorWrapper}>
            {productLinks&&productLinks.map((x, i) => {
                                        return (
                                            <div>
                                                <a href={x.url} target="_blank">
                                                    <LazyLoadImage 
                                                        draggable="false" 
                                                        src={'https://tkpoluslab.ru:8080/uploads/'+x.file} 
                                                        className={styles.itemIcon}
                                                    />
                                                </a>
                                            </div>
                                        )
                                    })}
        </div>
    );
}

export default ImageLinksViewer;
