import { LazyLoadImage } from "react-lazy-load-image-component";
import React, { useEffect, useRef, useState } from 'react';
import styles from './Renderer.module.css'
import DownloadButton from "../../DownloadButton/DownloadButton";
import leftButton from '../../../../Images/Regular_size/sliderButtonLeft.svg';
import rightButton from '../../../../Images/Regular_size/sliderButtonRight.svg';
import point from '../../../../Images/Regular_size/point.svg';
import circle from '../../../../Images/Regular_size/circle.svg';
export const sizeMap = {
    small:'0.75em',
    normal:'1em',
    medium:'1.75em',
    huge:'2.5em'
}

export const getMarked = (leaf,children) =>{
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }
    
    if (leaf.code) {
        children = <code>{children}</code>
    }
    
    if (leaf.italic) {
        children = <em>{children}</em>
    }
    if (leaf.strikethrough) {
        children = <span style={{textDecoration:'line-through'}}>{children}</span>
    }
    if (leaf.underline) {
        children = <u>{children}</u>
    }
    if(leaf.superscript){
        children = <sup>{children}</sup>
    }
    if(leaf.subscript){
        children = <sub>{children}</sub>
    }
    if(leaf.color){
        children = <span style={{color:leaf.color}}>{children}</span>
    }
    if(leaf.bgColor){
        children = <span style={{backgroundColor:leaf.bgColor}}>{children}</span>
    }
    if(leaf.fontSize){
        const size = sizeMap[leaf.fontSize]
        children = <span style={{fontSize:size}}>{children}</span>
    }
    if(leaf.link){
         children = <a href={children}>{children}</a>
     }
    return children;
}
const ImageElement = ({ attributes, children, element }) => {
    let trueScale = 100
    if (element.scale){
        trueScale = element.scale   
    }
    let trueBackground = true
    if (element.background){
        trueBackground = element.background   
    }
    return (
        <div 
            {...attributes} 
            className={element.alignImage==='center'?styles.imageWrapperCenter:element.alignImage==='right'?styles.imageWrapperRight:styles.imageWrapperLeft}
        >
            <div 
                style={{
                        userSelect: "none", 
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: element.alignImage==='center'? 'center' : element.alignImage==='right'?'right':'left'
                    }}
                className={element.alignImage==='right'?styles.imageOrderControlRight:styles.imageOrderControlLeft}
                contentEditable={false}
            >
                <LazyLoadImage
                    src={element.url}
                    placeholderSrc={element.url}
                    effect="blur"
                    draggable='false'
                    style={element.background===false?{ "--scale": trueScale/100, backgroundColor:'transparent', boxShadow:'0 0 0 0'}:{ "--scale": trueScale/100}}
                    className={element.alignImage==='center'?styles.imageCenter:element.alignImage==='left'?styles.imageLeft:styles.imageRight}
                />
            </div>
            <div style={{justifyContent:'center', display:'flex'}} className={element.alignImage==='right'?styles.imageTextOrderControlRight:styles.imageTextOrderControlLeft}>
                <div className={element.alignImage==='center'?styles.paragraph:element.alignImage==='left'?styles.imageLeftText:styles.imageRightText }>
                    {children}
                </div>
            </div>
        </div>
    )
  }
  const SeparatorElement = ({ attributes, children, element }) => {
    return (
        <div {...attributes} contentEditable={false}>
            <div className={styles.separatorWrapper}>
                <div className={styles.paragraph} style={{display:'flex', justifyContent:element.alignSelf==='left'?'left':'right'}}>
                    <svg width="100" height="10" viewBox="0 0 100 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={element.alignSelf==='left'?{}:{transform:'scale(-1,1)'}}>
                        <path d="M0 5C0 2.23858 2.23858 0 5 0H100C100 5.52285 95.5229 10 90 10H5C2.23858 10 0 7.76142 0 5Z" fill="#0092DD"/>
                    </svg>
                </div>
            </div>
            {children}
        </div>
    )
  }
  const ImageGalleryElement = ({ attributes, children, element }) => {
    const ref = useRef(null)
    const topScrollerRef = useRef(null)
    const [selectedId, setSelectedId] = useState(null)
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [scrollTimer, setScrollTimer] = useState(0)
    
    const scrollToId = (itemId) => {
        if (topScrollerRef?.current){
            const id = element.items.findIndex(item => item.id === itemId)
            const newScroll = topScrollerRef.current?.offsetWidth * id
            topScrollerRef.current.scrollLeft = newScroll
        }
    }

    const trueSetSelectedId = (id) => {
        setSelectedId(id)
        scrollToId(id) 
    }

    if (element.items && element.items[0] && selectedId===null){
        trueSetSelectedId(element.items[0].id)
    }
    
    const scrollBy = (amount) => {
        if (topScrollerRef.current){
            const shift = element.items.findIndex(item => item.id === selectedId)
            const newId = shift + amount
            if (newId >=0 && newId < element.items.length){
                trueSetSelectedId(element.items[newId].id)
            }
            if (newId < 0){
                trueSetSelectedId(element.items[element.items.length-1].id)
            }
            if (newId >= element.items.length){
                trueSetSelectedId(element.items[0].id)
            }
        }
    }
    useEffect(()=>{
        const timer = setTimeout(()=>{
            scrollBy(1)
        },150000)
        return () => clearInterval(timer);
    },[selectedId])
    return (
        <div {...attributes} contentEditable={false} style={{display:'flex',justifyContent:'center',outline:'none'}}
            tabIndex="0"
            onKeyDown={(e)=>{
                if (e.key==='Escape'){
                    setIsFullScreen(false)
                }
            }}
        >
            {isFullScreen &&<div style={{display:'flex',position:'fixed', height:'100vh', width:'100vw', top:0, left:0,zIndex:1000, background: 'rgba(0,0,0,0.5)', userSelect:'none', justifyContent:'center'}}
                onClick={()=>{setIsFullScreen(false)}}
                >
                {topScrollerRef.current&&
                <LazyLoadImage
                    src={element.items[Math.round(topScrollerRef.current?.scrollLeft/topScrollerRef.current?.offsetWidth)].url}
                    placeholderSrc={element.items[Math.round(topScrollerRef.current?.scrollLeft/topScrollerRef.current?.offsetWidth)].url}
                    effect="blur"
                    draggable='false'
                    className={styles.galleryFullScreenImage}
                />}
            </div>}
            <div style={{display:'flex',flexDirection:'column'}}>
                <div style={{display:'flex',flexDirection:'column', justifyContent:'flex-end'}}>
                    <div className={styles.galleryMainBox} ref={topScrollerRef} 
                        onScroll={(e)=>{
                                if (topScrollerRef.current){
                                    clearTimeout(scrollTimer)
                                    setScrollTimer(setTimeout(()=>
                                    {
                                        const offset = e.target.scrollLeft
                                        const width = topScrollerRef.current?.offsetWidth
                                        const abs = Math.round(offset/width)
                                        element.items[abs]&&trueSetSelectedId(element.items[abs].id)
                                    },100))
                                }
                            }}
                    >
                        {element.items.map((currentItem)=>{
                            return(
                                <div style={{display:'flex',justifyContent:'right', backgroundColor:'white'}} key={currentItem.key} ref={selectedId===currentItem.id?ref:null}
                                    onClick={()=>{
                                        setIsFullScreen(true)
                                    }}
                                >
                                    <LazyLoadImage
                                        src={currentItem.url}
                                        placeholderSrc={currentItem.url}
                                        effect="blur"
                                        draggable='false'
                                        className={styles.galleryTopItemImage}
                                    />
                                </div>
                            )
                        })}   
                    </div>
                    <div className={styles.galleryButtonBox}>
                        <div onMouseDown={()=>{scrollBy(-1)}}>
                            <LazyLoadImage
                                className={styles.leftGalleryButton}
                                src={leftButton}
                                effect="blur"
                            />
                        </div>
                        <div onMouseDown={()=>{scrollBy(1)}}>
                            <LazyLoadImage
                                className={styles.rightGalleryButton}
                                src={rightButton}
                                effect="blur"
                            />
                        </div>
                    </div>
                    <div className={styles.dotWrapper}>
                            {element.items.map((item) => {
                                if (item.id === selectedId) {
                                    return (
                                        <LazyLoadImage
                                            src={circle}
                                            effect="blur"
                                        />
                                    )
                                } else {
                                    return (
                                        <LazyLoadImage
                                            src={point}
                                            effect="blur"
                                        />
                                    )
                                }

                            })}
                    </div>
                </div>
                {element.displayType==='carousel' &&  
                    <div className={styles.galleryBottomBox} style={{display:'flex', overflow:'hidden', overflowX:'auto'}} >
                        {element.items.map((currentItem)=>{
                            return(
                                <div className={styles.galleryBottomItem} key={currentItem.key} 
                                onMouseDown={(e)=>{
                                    trueSetSelectedId(currentItem.id)
                                }}>
                                    <LazyLoadImage
                                        src={currentItem.url}
                                        placeholderSrc={currentItem.url}
                                        effect="blur"
                                        draggable='false'
                                        style={{outline:selectedId===currentItem.id?'1px solid rgba(0,146,221,1)':'', backgroundColor:'white'}}
                                        className={styles.galleryBottomItemImage}
                                    />
                                </div>
                            )
                        })} 
                    </div>
                }
                {children}
            </div>
        </div>
    )
  }
  const YoutubeElement = ({ attributes, children, element }) => {
    return (
        <div {...attributes} contentEditable={false}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'center', marginBottom:'2%'}}>
                <iframe
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/${element.url}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            {children}
        </div>
    )
  }
  const VideoElement = ({ attributes, children, element }) => {
    return (
        <div {...attributes} contentEditable={false}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'center', height: '480px', marginBottom:'2%'}}>
                <video controls="controls">
                    <source src={element.url}/>
                </video>
            </div>
            {children}
        </div>
    )
  }
  const FileElement = ({ attributes, children, element }) => {
    return (
        <div {...attributes}>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                <div style={{display:'flex', flexDirection:element.displayType===0?'column':'row'}} className={styles.paragraph}>
                    <div style={{marginTop: '10px', marginBottom: '10px', marginRight: '10px'}}>
                        {children}
                    </div>
                    <a href={element.url} download style={{zIndex:'100', marginBottom: '2%'}}>
                        <DownloadButton width={189} extension={199} text={"Загрузить"} />
                    </a>
                </div>
            </div>
        </div>
    )
  }
  const ConclusionElement = ({ attributes, children, element }) => {
    if (element.displayType===0){
        return (
            <div {...attributes} style={{display:'flex',flexDirection:'row',justifyContent:'center'}} >
                <div className={styles.paragraph}>
                    <div contentEditable={false} className={styles.conclusionHr} style={{marginBottom:'35px'}}/>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                        <div style={{width:'100%'}}>
                            {children}
                        </div>
                    </div>
                    <div contentEditable={false} className={styles.conclusionHr} style={{marginTop:'35px',marginBottom:'120px'}}/>
                </div>
            </div>)
    }else{
        return (
            <div {...attributes} style={{display:'flex',flexDirection:'row',justifyContent:'left', marginBottom:'80px'}} >
                <div className={styles.arrowConclusion}>
                    <svg className={styles.arrowConclusionBlob} viewBox="0 0 659 331" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 42.2842L0 25.2842C846.5 -108.716 869.988 333.715 108.5 330.284C108.5 207.284 97.5 119.784 7 42.2842Z" fill="#CECECE" fill-opacity="0.4"/>
                    </svg>
                    <svg className={styles.arrowConclusionArrow} viewBox="0 0 85 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.672 1.40817C28.3331 0.522052 29.3742 0 30.4803 0H47.6374L23.1391 36.5525C22.3493 37.7309 22.3493 39.2691 23.1391 40.4475L47.6374 77H30.4803C29.3742 77 28.3331 76.478 27.672 75.5918L0 38.5L27.672 1.40817Z" fill="#0092DD"/>
                        <path d="M65.0346 1.40817C65.6957 0.522052 66.7368 0 67.8429 0H85L60.5017 36.5525C59.7119 37.7309 59.7119 39.2691 60.5017 40.4475L85 77H67.8429C66.7368 77 65.6957 76.478 65.0346 75.5918L37.3626 38.5L65.0346 1.40817Z" fill="#0092DD"/>
                    </svg>
                    <div className={styles.arrowConclusionText}>
                        <div style={{zIndex:100}}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>)
    }
    
  }
export const getBlock = (props) => {
    const {element,children} = props;
    const attributes = props.attributes ?? {};
    switch(element.type){
        case 'headingOne':
            return <div style={{display:'flex', justifyContent:'center'}}><h1 {...attributes} {...element.attr} className={styles.paragraph}>{children}</h1></div>
        case 'headingTwo':
            return <div style={{display:'flex', justifyContent:'center'}}><h2 {...attributes} {...element.attr} className={styles.paragraph}>{children}</h2></div>
        case 'headingThree':
            return <div style={{display:'flex', justifyContent:'center'}}><h3 {...attributes} {...element.attr} className={styles.paragraph}>{children}</h3></div>
        case 'blockquote':
            return <div style={{display:'flex', justifyContent:'center'}}><blockquote {...attributes} {...element.attr } className={styles.paragraph}>{children}</blockquote></div>
        case 'alignLeft':
            return <div style={{textAlign:'left',listStylePosition:'inside'}} {...attributes} {...element.attr} >{children}</div>
        case 'alignCenter':
            return <div style={{textAlign:'center',listStylePosition:'inside'}} {...attributes} {...element.attr}>{children}</div>
        case 'alignRight':
            return <div style={{textAlign:'right',listStylePosition:'inside'}} {...attributes} {...element.attr}>{children}</div>
        case 'alignJustify':
            return <div style={{textAlign:'justify', listStylePosition:'inside'}} {...attributes} {...element.attr}>{children}</div>
        case 'list-item':
            return <li {...attributes} {...element.attr} className={styles.paragraph}>{children}</li>
        case 'orderedList':
            return <div style={{display:'flex', justifyContent:'center',marginTop:'-20px'}}><ol type='1' {...attributes} className={styles.paragraph}>{children}</ol></div>
        case 'unorderedList':
            return <div style={{display:'flex', justifyContent:'center',marginTop:'-20px'}}><ul {...attributes} className={styles.paragraph}>{children}</ul></div>
        case 'image':
            return <ImageElement {...props}/>
        case 'separator':
            return <SeparatorElement {...props}/>
        case 'gallery':
            return <ImageGalleryElement {...props}/>
        case 'video':
            return <VideoElement {...props}/>
        case 'youtube':
            return <YoutubeElement {...props}/>
        case 'file':
            return <FileElement {...props}/>
        case 'conclusion':
            return <ConclusionElement {...props}/>
        default :
            return <div className={styles.paragraphWrapper}><div {...element.attr} {...attributes} className={styles.paragraph}>{children}</div></div>
    }
} 