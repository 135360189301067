import styles from './MainProducts.module.css'
import ProductComponent from "../../CommonComponents/ProductComponent/ProductComponent";
import lab from '../../../Images/Regular_size/lab.png';
import robotics from '../../../Images/Regular_size/robotics.png';
import EducationalEquipment from '../../../Images/Regular_size/EducationalEquipment.png';
import InteractiveEquipment from '../../../Images/Regular_size/InteractiveEquipment.png';
import price from '../../../Images/Regular_size/price.png';
import labCompressed from '../../../Images/Compressed_Image/lab.png';
import roboticsCompressed from '../../../Images/Compressed_Image/robotics.png';
import EducationalEquipmentCompressed from '../../../Images/Compressed_Image/EducationalEquipment.png';
import InteractiveEquipmentCompressed from '../../../Images/Compressed_Image/InteractiveEquipment.png';
import priceCompressed from '../../../Images/Compressed_Image/price.png';
import cerrent from '../../../Images/Regular_size/current.png';
import { useNavigate } from 'react-router';

function MainProducts() {
    let navigate = useNavigate()
    return (
        <div >
            <div className={styles.productWrapper} style={{ display: 'flex', justifyContent: 'center', marginTop:110, paddingBottom:40 }}>
                <div style={{flex:1}} />
                <div style={{ width: 1920 }}>
                    <div  style={{ marginTop: 40 }}>
                        <div className={styles.productHeader} style={{ display: 'flex', justifyContent: 'center'}}>
                            Информационные технологии в нашем производстве
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className={styles.commonProductWrapper}>
                                <div onClick={() => {
                                    navigate("/products", { state: { directoryId: 2 } }); window.scrollTo({top: 0, behavior: "instant"});
                                }}>
                                    <ProductComponent text={"Цифровые лаборатории"} des1={"Программное обеспечение используется для автономного режима работы, проведения нескольких лабораторных работ одновременно."} des2={"Внесены в реестр Минпромторга."} src={lab} placeholderSrc={labCompressed} />
                                </div>
                                <div onClick={() => {
                                    navigate("/products", { state: { directoryId: 3 } }); window.scrollTo({top: 0, behavior: "instant"});
                                }}>
                                    <ProductComponent text={"Робототехника"}  des1={"В составе роботов имеется программа AR+TCam VIEWER, которая предназначена для настройки системы технического зрения на базе модуля камеры с интегрированным алгоритмическим ПО."} des2={""} src={robotics} placeholderSrc={roboticsCompressed} />
                                </div>
                                {/* <div onClick={() => {
                                    navigate("/products", { state: { directoryId: 5 } }); window.scrollTo(0, 0);
                                }}>
                                    <ProductComponent text={"Учебное оборудование"} src={EducationalEquipment} placeholderSrc={EducationalEquipmentCompressed} />
                                </div> */}
                                <div onClick={() => {
                                    navigate("/products", { state: { directoryId: 4 } }); window.scrollTo({top: 0, behavior: "instant"});
                                }}>
                                    <ProductComponent text={"БПЛА (БВС)"} des1={"Благодаря программному обеспечению, наш БПЛА можно запускать как в ручной, так и в автономный полет. Также в комплекте присутствует техническое зрение."} des2={""} src={InteractiveEquipment} placeholderSrc={InteractiveEquipmentCompressed} />
                                </div>
                                {/* <div onClick={() => {
                                    navigate("/products", { state: { directoryId: 97 } }); window.scrollTo(0, 0);
                                }}>
                                    <ProductComponent text={"Текущие предложения"} src={cerrent} placeholderSrc={cerrent} />
                                </div> */}
                                {/* <div onClick={() => { navigate("/customers"); window.scrollTo(0, 0) }}>
                                    <ProductComponent text={"Прайс лист"} src={price} placeholderSrc={priceCompressed} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{flex:1}}/>
            </div>

        </div >
    );
}

export default MainProducts;
