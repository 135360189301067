import styles from './Order804.module.css';
import DoubleSidedGradient from '../CommonComponents/DoubleSidedGradient/DoubleSidedGradient';
import TransparentButton from '../CommonComponents/TransparentButton/TransparentButton';
import BlueButton from '../CommonComponents/BlueButton/BlueButton';
import {useNavigate} from "react-router-dom";
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

function Order804() {
    let navigate = useNavigate()
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => { 
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className={styles.justifier}> 
            <div className={styles.order}>
                <DoubleSidedGradient/>
                <div className={styles.orderTint}/>
                <div className={styles.orderHeaderWrapper}>
                    <div className={styles.orderHeader}>
                        Приказ Министерства просвещения Российской Федерации от 06.09.2022 № 804
                    </div>
                </div>
                <div className={styles.orderSeparatorWrapper}>
                    <div className={styles.orderSeparator}/>
                </div>
                
                <div style={{justifyContent:'center', display:'flex'}}>
                    <div className={styles.orderText}>
                        Приказ Минпросвещения России от 6 сентября 2022 года № 804 «Об утверждении перечня средств обучения и воспитания, соответствующих современным условиям
                        обучения, необходимых при оснащении общеобразовательных организаций в целях реализации мероприятий государственной программы Российской Федерации
                        «Развитие образования», направленных на содействие созданию (создание) в субъектах Российской Федерации новых (дополнительных) мест в общеобразовательных
                        организациях, модернизацию инфраструктуры общего образования, школьных систем образования, критериев его формирования и требований к функциональному
                        оснащению общеобразовательных организаций, а также определении норматива стоимости оснащения одного места обучающегося указанными средствами обучения и
                        воспитания»
                    </div>
                </div>
                <svg width="1390" height="316" viewBox="0 0 1390 316" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.orderCross}>
                    <path d="M175.462 0.990365C175.645 -0.126267 177.251 -0.126284 177.435 0.990348L199.365 134.127C199.443 134.603 199.85 134.955 200.332 134.964L1390 158L200.332 181.036C199.85 181.045 199.443 181.397 199.365 181.873L177.435 315.01C177.251 316.126 175.645 316.126 175.462 315.01L153.515 181.773C153.445 181.344 153.104 181.009 152.673 180.946L1.79975 158.99C0.658434 158.823 0.658423 157.177 1.79974 157.01L152.673 135.054C153.104 134.991 153.445 134.656 153.515 134.227L175.462 0.990365Z" fill="#C5E2F1"/>
                </svg>
                <div className={styles.justifier}>
                    <div className={styles.orderButtons}>
                        <div style={{width:'218px', marginRight:'67px', marginTop:'3px', marginBottom:'20px', marginLeft:width>700?0:'5%'}}>
                            <Link to="/приказ_804.xlsx" target="_blank" download>
                                <TransparentButton width={width>700?214:width*0.9} extension={width>700?230.52:width*0.9}  text={"Скачать файл"} />
                            </Link>
                        </div>
                        <div onClick={()=>{navigate("/products")}} style={{marginLeft:width>700?0:'5%',}}>
                            <BlueButton width={width>700?259:width*0.9} extension={width>700?280:width*0.9} text={"Перейти к товарам"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Order804;
