import styles from './PageSettings.module.css'
import ImageLoader from "../../ImageLoader/ImageLoader";
import { useLayoutEffect, useRef, useState, useMemo } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Editable, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { createEditor, Transforms } from "slate";
import image from '../../../Images/Regular_size/standardPicture.png';
import axios from "axios";
import { useStore } from "effector-react";

function PageSettings(props) {
    const [showCreate, setShowCreate] = useState(false)
    const [src, setSrc] = useState(props.address+"uploads/"+props.pageData.Image)
    const [isShowCut, setIsShowCut] = useState(false)
    const [croppedImageUrl, setCroppedImageUrl] = useState()
    const [imgWidth, setImgWidth] = useState()
    const [imgHeight, setImgHeight] = useState()

    const cutHandler = (im) => {
        setIsShowCut(true)
    }

    function resizedataURL(datas, wantedWidth, wantedHeight) {
        // We create an image to receive the Data URI
        let img = document.createElement('img');

        // When the event "onload" is triggered we can resize the image.
        img.onload = function () {
            // We create a canvas and get its context.
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            // We set the dimensions at the wanted size.
            canvas.width = wantedWidth;
            canvas.height = wantedHeight;

            // We resize the image with the canvas method drawImage();
            ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
            let dataURI = canvas.toDataURL();
            setSrc(dataURI)
            cutHandler(dataURI)
        };

        // We put the Data URI in the image's src attribute
        img.src = datas;
    }

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", async () => {

                const img = new Image();
                img.src = reader.result;
                await img.decode();

                if (img.naturalWidth < 500 && img.naturalHeight < 500) {
                    setSrc(reader.result)
                    cutHandler(reader.result)
                    setImgWidth(img.naturalWidth)
                    setImgHeight(img.naturalHeight)
                } else {
                    resizedataURL(reader.result, 500, (500 * img.naturalHeight) / img.naturalWidth)
                    setImgWidth(500)
                    setImgHeight((500 * img.naturalHeight) / img.naturalWidth)
                }

            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const editor = useMemo(() => withSingleLine(withHistory(withReact(createEditor()))), []);
    const [value, setValue] = useState([
        {
            children: [{ text: props.pageData.Description }],
        },
    ]);

    const handleDOMBeforeInput = (event) => {
        const inputType = event.inputType;
        if (inputType === 'insertText') {
            const textLength = editor.string(editor, []).length;
            if (textLength >= 86) {
                event.preventDefault();
                return;
            }
        }
    };

    const saveHandler = () => {
        //Сюды сохранить src - картинка; value[0]["children"][0]["text"] - описание
        
    }

    return (
        <div>
            {isShowCut &&
                <div>
                    <div className={styles.cutWrapper}>
                        <div>
                            <div style={{ zIndex: 1006 }}>
                                <ImageLoader width={100} height={50} src={src} setCroppedImageUrl={setCroppedImageUrl} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', zIndex: 1010 }}>
                                <div className={styles.buttonWrapper} style={{ zIndex: 1010 }} onClick={() => {
                                    setSrc(croppedImageUrl)
                                    setIsShowCut(false);
                                }}>
                                    Обрезать
                                </div>
                            </div>
                            <div className={styles.shadow2} onClick={() => setIsShowCut(false)} />
                        </div>
                    </div>
                </div>
            }
            <div style={{borderRadius: 16, zIndex: 1001, display:'flex', justifyContent:'center', margin:50 }}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <div className={styles.newsWrapper}>
                        <div style={{ borderRadius: "35px 5px 35px 35px", overflow: 'hidden', width: 310, margin: 20, cursor: 'pointer' }}>
                            <form style={{ height: "180px", cursor: "pointer", padding: 10 }}
                                method="post" encType="multipart/form-data">
                                <label style={{ cursor: "pointer" }}>
                                    <input type="file" name="file" id='image'
                                        accept='.png, .jpg, .jpeg'
                                        onChange={(e) => onSelectFile(e)} style={{ position: 'absolute', opacity: 0 }} />
                                    <div style={{ borderRadius: "25px 5px 25px 25px", width: 310, height: 180, overflow: 'hidden' }}>
                                        <LazyLoadImage
                                            src={src}
                                            style={{ objectFit: 'cover' }}
                                            height={180}
                                            width={310}
                                            effect="blur"
                                        />
                                    </div>
                                </label>
                            </form>
                        </div>
                        <div className={styles.newsTextWrapper}>
                            {/* 'Полюс и ТУСУР открыли центр компетенций Intel AI for Youth на базе МАОУ "Перспектива"' */}
                            <Slate editor={editor} value={value} onChange={(v) => {
                                setValue(v)
                            }}>
                                <Editable onDOMBeforeInput={handleDOMBeforeInput} placeholder={<span>Введите описание...</span>} />
                            </Slate>
                        </div>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className={styles.buttonWrapper} onClick={() => saveHandler()}>
                            Сохранить
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

function withSingleLine(editor) {
    const { normalizeNode } = editor;
    editor.normalizeNode = ([node, path]) => {
        if (path.length === 0) {
            if (editor.children.length > 1) {
                Transforms.mergeNodes(editor);
            }
        }
        return normalizeNode([node, path]);
    };
    return editor;
}


export default PageSettings;
