import ImageLoader from "../ImageLoader/ImageLoader";
import { useLayoutEffect, useRef, useState, useMemo, useEffect } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Editable, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { createEditor, Transforms } from "slate";
import image from '../../Images/Regular_size/standardPicture.png';
import exclamation from '../../Images/Regular_size/exclamation.svg';
import buttonUp from '../../Images/Regular_size/buttonUp.svg';
import buttonDown from '../../Images/Regular_size/buttonDown.svg';
import mail2 from '../../Images/Regular_size/mail2.svg';
import axios from "axios";
import { useStore } from "effector-react";
import { addressStore } from "../Store/ConfigStore";
import styles from './Products.module.css'
import { useLocation, useNavigate } from "react-router-dom";
import DoubleSidedGradient from "../CommonComponents/DoubleSidedGradient/DoubleSidedGradient";
import TransparentButton from "../CommonComponents/TransparentButton/TransparentButton";
import CommercialOffer from "../CommonComponents/CommercialOffer/CommercialOffer";

function Partners() {
    const { state } = useLocation();
    const address = useStore(addressStore);
    const [directory, setDirectory] = useState(state?.["directoryId"] ? state["directoryId"] : 2)
    const [subDirectories, setSubDirectories] = useState([])
    const [isStart, setIsStart] = useState(false)
    const [currentSubDirectory, setCurrentSubDirectory] = useState({})

    const [products, setProducts] = useState([])
    /*  1 -  новости
        2 -  Цифровые лаборатории
        3 -  Робототехника
        4 -  БПЛА (БВС)
        5 -  Учебное об-ние 
        97 - Текущие предложения*/
    const [isModerator, setIsModerator] = useState(sessionStorage.getItem("token") ? true : false)
    const [memI, setMemI] = useState(0);
    const [isShowModalAddProduct, setIsShowModalAddProduct] = useState(false);
    const [memJ, setMemJ] = useState(0);
    const [isShowMenu, setIsShowMenu] = useState(false);
    const [isShowAlert, setIsShowAlert] = useState(false);
    const [isShowAlertDeleteSubDir, setIsShowAlertDeleteSubDir] = useState(false);
    const [isShowCreateSubDir, setIsShowCreateSubDir] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [modalImage, setModalImage] = useState("");
    const [modalName, setModalName] = useState("");
    const [modalDescription, setModalDescription] = useState("");
    const [id, setId] = useState(0);
    const [memI2, setMemI2] = useState(0);
    const [id2, setId2] = useState(0);
    const [memI22, setMemI22] = useState(0);
    const [isKP, setIsKP] = useState(false);

    const [isOpenLable, setIsOpenLable] = useState(false);
    useEffect(() => { // хук отслеживает изменение ширины экрана
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    let navigate = useNavigate();
    //alert(JSON.stringify(currentSubDirectory))
    useEffect(() => {
        axios.get(address + 'api/v1/articles/getDirectory?id=' + directory + '&page=1&pageSize=100')
            .then(res => {
                setSubDirectories(res.data["Directories"])
                setCurrentSubDirectory(res.data["Directories"][0])
            })
    }, [directory]);

    useEffect(() => {
        // alert(subDirectories.includes(currentSubDirectory))
        if (subDirectories.length > 0) {
            if (subDirectories.includes(currentSubDirectory) === false) {
                setCurrentSubDirectory(subDirectories[0])
            }
        }
    }, [subDirectories]);

    useEffect(() => {
        if (subDirectories.length > 0) {
            axios.get(address + 'api/v1/articles/getDirectory?id=' + currentSubDirectory.Id + '&page=1&pageSize=100')
                .then(res => {
                    setProducts(res.data["Articles"])
                    setIsStart(true);
                })
        } else {
            setProducts([])
        }
    }, [currentSubDirectory]);

    useEffect(() => {
        if (isStart && state?.["subDirectory"]) {
            setCurrentSubDirectory(state["subDirectory"])
        }
    }, [isStart]);


    function dragStartHandler(e, word, i) {
        if (isModerator) {
            setMemI(i)
        }
    }

    function dragEndHandler(e) {
        if (isModerator) {
            e.target.style.boxShadow = ""
        }
    }

    function dragOverHandler(e) {
        if (isModerator) {
            e.preventDefault()
            e.target.style.boxShadow = "-3px 0 1px #ababab";
        }
    }

    function dropHandler(e, word, i) {
        if (isModerator) {
            e.preventDefault()
            let oldWord = subDirectories[memI]
            let newWord = subDirectories[i]
            axios.get(address + `api/v1/articles/swapDirectories?id1=${oldWord.Id}&id2=${newWord.Id}`, {}).then(res => {
                e.target.style.boxShadow = ""
                let copy = [...subDirectories]
                copy[i] = oldWord
                copy[memI] = newWord
                setSubDirectories(copy)
            })

        }
    }

    function dragStartHandler2(e, word, i) {
        if (isModerator) {
            setMemJ(i)
        }
    }

    function dragEndHandler2(e) {
        if (isModerator) {
            e.target.style.boxShadow = ""
        }
    }

    function dragOverHandler2(e) {
        if (isModerator) {
            e.preventDefault()
            e.target.style.boxShadow = "-3px 0 1px #ababab";
        }
    }

    function dropHandler2(e, word, i) {
        if (isModerator) {
            e.preventDefault()
            let oldWord = products[memJ]
            let newWord = products[i]
            axios.get(address + `api/v1/articles/swapArticles?id1=${oldWord.Id}&id2=${newWord.Id}`, {}).then(res => {
                e.target.style.boxShadow = ""
                let copy = [...products]
                copy[i] = oldWord
                copy[memJ] = newWord
                setProducts(copy)
            })

        }
    }

    const changeHandler = (e) => {

        const file = e.target.files[0];
        //if (!file.type.match(imageMimeType)) {
        // alert("kek")
        axios.post(address + `api/v1/files/upload`, file, {
            headers: {
                'Content-Type': "image/png",
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            }
        }).then(res => {
            setModalImage(res.data.FileName)
            // alert(JSON.stringify(address + "uploads/" +res.data.FileName))
        }).catch((error) => {
            // alert(JSON.stringify(error))
            alert("Надо авторизироваться")
        });
        return;
        // }
    }

    const createHandler = () => {
        if (modalImage !== '') {
            if (modalName !== '') {
                if (modalDescription !== '') {
                    axios.post(address + `api/v1/articles/createArticle`, {
                        "Name": modalName,
                        "Image": modalImage,
                        "Description": modalDescription,
                        "Content": [],
                        "ParentId": currentSubDirectory?.Id
                    }, {
                        headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                        }
                    }).then(res => {
                        // setModalImage(res.data.FileName)
                        navigate('/product_builder/' + res.data?.Id)
                    }).catch((error) => {
                        alert(JSON.stringify(error))
                        //alert("Ошибка создания товара")
                    });
                } else {
                    alert("Не введено описание")
                }
            } else {
                alert("Не введено название")
            }
        } else {
            alert("Не загружено изображение")
        }
    }

    const deleteProductHandler = (id) => {
        axios.post(address + `api/v1/articles/deleteArticle?id=` + id, {}, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            }
        }).then(res => {
            // alert("Успешное удаление")
        }).catch((error) => {
            //alert(JSON.stringify(error))
            alert("Ошибка удаления товара")
        });
    }

    const deleteSubDirectoriesHandler = (id) => {
        axios.post(address + `api/v1/articles/deleteDirectory?id=` + id, {}, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            }
        }).then(res => {
        }).catch((error) => {
            alert("Ошибка удаления товара")
        });
    }

    const createSubDir = () => {
        if (modalName !== "") {
            // alert(directory)
            // alert(modalName)
            axios.post(address + `api/v1/articles/createDirectory`, {
                "Name": modalName,
                "Image": "",
                "ParentId": directory
            }, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                }
            }).then(res => {

                setSubDirectories([...subDirectories, { "Id": res.data?.Id, "Name": modalName, "Image": "" }])
                setModalName("")
                setIsShowCreateSubDir(false)
                // setCurrentSubDirectory({ "Id": res.data.Id, "Name": modalName, "Image": "" })
                // alert(JSON.stringify(res.data.Id))
            }).catch((error) => {
                alert(JSON.stringify(error))
                setModalName("")
                setIsShowCreateSubDir(false)
            });
        } else {
            alert("Введите название")
        }
    }

    return (
        <div style={width > 1920 ? { background: '#F6F6F6', position: 'relative', display: 'flex', justifyContent: 'center' } : { background: '#F6F6F6' }}>

            {isOpenLable &&
                <div className={styles.shadow} onClick={() => { setIsShowAlertDeleteSubDir(false) }}>
                    <div className={styles.modalWrapper} style={{ position: 'relative' }}>
                        <input
                            value={modalName}
                            className={styles.inputStyle}
                            placeholder="Введите название "
                            style={{ width: 'calc(100% - 30px)', margin: 20, fontSize: 30 }}
                            onChange={(e) => setModalName(e.target.value)} />
                    </div>
                </div>
            }
            {isShowAlertDeleteSubDir &&
                <div className={styles.shadow} onClick={() => { setIsShowAlertDeleteSubDir(false) }}>
                    <div className={styles.modalWrapper} style={{ position: 'relative' }}>
                        <div className={styles.modalText}>Вы действительно хотите удалить?</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={styles.alertButtonWrapperDelete} onClick={() => {
                                deleteSubDirectoriesHandler(id2)
                                setSubDirectories(subDirectories.filter((y, j) => memI22 !== j))
                                setIsShowAlertDeleteSubDir(false)
                            }}>
                                Удалить
                            </div>
                            <div className={styles.alertButtonWrapperCancel}>
                                Отмена
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isShowAlert &&
                <div className={styles.shadow} onClick={() => { setIsShowAlert(false) }}>
                    <div className={styles.modalWrapper} style={{ position: 'relative' }}>
                        <div className={styles.modalText}>Вы действительно хотите удалить?</div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={styles.alertButtonWrapperDelete} onClick={() => {
                                deleteProductHandler(id)
                                setProducts(products.filter((y, j) => memI2 !== j))
                                setIsShowAlert(false)
                            }}>
                                Удалить
                            </div>
                            <div className={styles.alertButtonWrapperCancel}>
                                Отмена
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isShowCreateSubDir &&
                <div className={styles.shadow} onClick={() => { setIsShowCreateSubDir(false); setModalName("") }}>
                    <div className={styles.modalWrapper} style={{ position: 'relative' }} onClick={(e) => e.stopPropagation()}>
                        <input
                            value={modalName}
                            className={styles.inputStyle}
                            placeholder="Введите название "
                            style={{ width: 'calc(100% - 30px)', margin: 20, fontSize: 30 }}
                            onChange={(e) => setModalName(e.target.value)} />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={styles.alertButtonWrapperDelete} onClick={() => {
                                createSubDir()
                            }}>
                                Добавить
                            </div>
                            <div className={styles.alertButtonWrapperCancel} onClick={() => { setIsShowCreateSubDir(false); setModalName("") }}>
                                Отмена
                            </div>
                        </div>
                    </div>
                </div>
            }
            {isShowModalAddProduct &&
                <div className={styles.shadow} onClick={() => {
                    setIsShowModalAddProduct(false);
                    setModalImage("");
                    setModalName("");
                    setModalDescription("");
                }}>
                    <div style={{ color: 'white', display: 'flex', flexDirection: 'column' }} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.wrapProd2}
                            style={{ width: width > 1920 ? 240 : width > 768 ? 0.05128205128205128 * width + 141.53846153846155 : 0.13480392156862744 * width + 111.47058823529412, height: width > 1920 ? 292 : width > 768 ? 0.04935897435897436 * width + 197.23076923076923 : (0.024509803921568627 * width + 206.1764705882353 + 20) }}>
                            <div style={{ margin: 12 }} >
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ width: width > 1920 ? 210 : 0.04487179487179487 * width + 123.84615384615384, height: width > 1920 ? 204 : 0.041025641025641026 * width + 125.23076923076923 }}>
                                        <form style={{ cursor: "pointer", cursor: 'pointer' }}
                                            method="post" encType="multipart/form-data">
                                            <label className="input-file" style={{ cursor: 'pointer' }}>
                                                <input style={{ cursor: 'pointer', position: 'absolute', opacity: 0 }} type="file" name="file" id='image'
                                                    accept='.png, .jpg, .jpeg, .webp'
                                                    onChange={changeHandler}
                                                />
                                                <div >
                                                    <LazyLoadImage
                                                        src={address + "uploads/" + modalImage}
                                                        effect="blur"
                                                        width={width > 1920 ? 210 : 0.04487179487179487 * width + 123.84615384615384}
                                                        height={width > 1920 ? 204 : 0.041025641025641026 * width + 125.23076923076923}
                                                    />
                                                </div>
                                            </label>
                                        </form>
                                    </div>
                                </div>
                                <div className={styles.labName} style={{ marginBottom: 5 }}>
                                    {/* {x.Name} */}
                                    <input
                                        value={modalName}
                                        className={styles.inputStyle}
                                        placeholder="Введите название"
                                        onChange={(e) => setModalName(e.target.value)} />
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div className={styles.labDescription} style={{ flex: 1 }}>
                                        {/* {x["Description"]} */}
                                        <input
                                            value={modalDescription}
                                            className={styles.inputDescriptionStyle}
                                            placeholder="Введите описание"
                                            onChange={(e) => setModalDescription(e.target.value)} />
                                    </div>
                                    <div>
                                        <svg width="22" height="11" viewBox="0 0 22 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.0741 1L21 5.84615M21 5.84615L15.0741 10M21 5.84615H1" stroke="#9E9E9E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.createButtonWrapper} onClick={() => createHandler()}>
                            Создать
                        </div>
                    </div>
                </div>
            }

            <div style={width > 1920 ? { maxWidth: 1920 } : {}}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ flex: 1 }} />
                    <div className={styles.breadcrumbsStyle}>
                        <div className={styles.breadcrumbsElement} onClick={() => { setDirectory(2); setCurrentSubDirectory(subDirectories[0]); }}>
                            Продукты
                        </div>
                        <div style={{ display: 'flex' }}>
                            <span style={{ marginLeft: 5, marginRight: 5 }}> {">"} </span>
                            <div className={styles.breadcrumbsElement} onClick={() => setCurrentSubDirectory(subDirectories[0])}>
                                {(directory === 2) ? "Цифровые лаборатории" : (directory === 3) ? "Робототехника" : (directory === 4) ? "БПЛА (БВС)" : (directory === 5) ? "Учебное оборудование" : (directory === 97) ? "Текущие предложения" : ""}
                            </div>
                        </div>
                        {currentSubDirectory?.Name &&
                            <div style={{ display: 'flex' }}>
                                <span style={{ marginLeft: 5, marginRight: 5 }}> {">"} </span>
                                <div className={styles.breadcrumbsElement}>
                                    {currentSubDirectory?.Name}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <DoubleSidedGradient />
                </div>

                {(width <= 768 && width > 600) &&
                    <>
                        <div className={styles.margen} />
                        <div className={styles.smartMenu}>
                            <div className={styles.menuElement} style={directory === 2 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(2)} >Цифровые лаборатории</div>
                            <div className={styles.menuElement} style={directory === 3 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(3)} >Робототехника</div>
                            <div className={styles.menuElement} style={directory === 4 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(4)} >БПЛА (БВС)</div>
                            <div className={styles.menuElement} style={directory === 5 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(5)} >Учебное оборудование</div>
                            {/* <div className={styles.menuElement} style={directory === 97 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(97)} >Текущие предложения</div> */}
                        </div>
                    </>
                }
                {(width <= 600) &&
                    <div style={{ cursor: "pointer" }} onClick={() => setIsShowMenu(!isShowMenu)}>
                        <div className={styles.margen} />
                        <div className={styles.smartMenuV2} style={isShowMenu ? { height: 176, transition: '0.5s' } : { height: 44, transition: '0.5s' }}>
                            <div style={{ display: 'flex' }}>
                                <div className={styles.menuElement} style={{ fontWeight: 600, flex: 1, color: "#201644" }} >{directory === 2 ? "Цифровые лаборатории" : directory === 3 ? "Робототехника" : directory === 4 ? "БПЛА (БВС)" : directory === 5 ? "Учебное оборудование" : ""}</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <LazyLoadImage
                                        src={isShowMenu ? buttonUp : buttonDown}
                                        effect="blur"
                                    />
                                </div>
                            </div>
                            <div style={{ zIndex: 100 }}>
                                {directory !== 2 && <div className={styles.menuElement} style={directory === 2 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(2)} >Цифровые лаборатории</div>}
                                {directory !== 3 && <div className={styles.menuElement} style={directory === 3 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(3)} >Робототехника</div>}
                                {directory !== 4 && <div className={styles.menuElement} style={directory === 4 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(4)} >БПЛА (БВС)</div>}
                                {directory !== 5 && <div className={styles.menuElement} style={directory === 5 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(5)} >Учебное оборудование</div>}
                                {/* {directory !== 97 && <div className={styles.menuElement} style={directory === 97 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(97)} >Текущие предложения</div>} */}
                            </div>
                        </div>
                    </div>
                }

                <div style={{ height: 64, background: "rgba(216, 216, 216, 0.4)", position: 'relative' }} >
                    <div style={{ height: 250, background: "rgba(216, 216, 216, 0.4)", position: 'absolute', bottom: -250, left: 0, width: "100%" }} />
                </div>
                <div style={width > 768 ? { display: 'flex' } : { display: 'flex', justifyContent: 'center' }}>
                    <div style={{ position: 'relative' }}>
                        <div className={styles.backgraundGradient}>
                            {width > 768 &&
                                <div style={{ left: width > 1920 ? 130 : 0.2864583333333333 * width - 420, bottom: -(322 / 1.8), position: 'absolute', zIndex: 1, pointerEvents: 'none', width: width - (width > 1920 ? 130 : 0.2864583333333333 * width - 420) - 20, overflow: 'hidden' }}>
                                    <svg width="1390" height="316" viewBox="0 0 1390 316" fill="#F6F6F6" xmlns="http://www.w3.org/2000/svg" className={styles.newsCross}>
                                        <path d="M175.462 0.990365C175.645 -0.126268 177.251 -0.126284 177.435 0.990348L199.365 134.127C199.443 134.603 199.85 134.955 200.332 134.964L1390 158L200.332 181.036C199.85 181.045 199.443 181.397 199.365 181.873L177.435 315.01C177.251 316.126 175.645 316.126 175.462 315.01L153.515 181.773C153.445 181.344 153.104 181.009 152.673 180.946L1.79975 158.99C0.658434 158.823 0.658423 157.177 1.79974 157.01L152.673 135.054C153.104 134.991 153.445 134.656 153.515 134.227L175.462 0.990365Z" fill="#F6F6F6" />
                                    </svg>
                                </div>
                            }

                        </div>

                        {width > 768 &&
                            <div className={styles.menuWrapper} style={{ paddingLeft: width > 1920 ? 405 : (0.3515625 * width - 270), position: 'relative' }}>
                                <div className={styles.menuElement} style={directory === 2 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(2)} >Цифровые лаборатории</div>
                                <div className={styles.menuElement} style={directory === 3 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(3)} >Робототехника</div>
                                <div className={styles.menuElement} style={directory === 4 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(4)} >БПЛА (БВС)</div>
                                <div className={styles.menuElement} style={directory === 5 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(5)} >Учебное оборудование</div>
                                {/* <div className={styles.menuElement} style={directory === 97 ? { fontWeight: 600 } : {}} onClick={() => setDirectory(97)} >Текущие предложения</div> */}
                            </div>
                        }
                    </div>
                    <div style={{ width: width > 1920 ? 800 : 0.391025641025641 * width + 199.23076923076923, marginLeft: 50, zIndex: 2 }}>
                        <div className={styles.subDirectoriesWrapper} key={subDirectories}>
                            {currentSubDirectory?.Name && <>
                                {subDirectories.map((x, i) => {
                                    return (
                                        <div key={i} className={styles.subDirectoryElement} style={x?.Id === currentSubDirectory?.Id ? { background: "linear-gradient(175.2deg, #C5E2F1 3.88%, rgba(197, 226, 241, 0) 50.02%)", width: width > 1920 ? 240 : 0.05128205128205128 * width + 141.53846153846155 } : { width: width > 1920 ? 240 : 0.05128205128205128 * width + 141.53846153846155 }} onClick={() => setCurrentSubDirectory(x)}
                                            draggable={isModerator}
                                            onDragStart={(e) => dragStartHandler(e, x, i)}
                                            onDragLeave={(e) => dragEndHandler(e)}
                                            onDragEnd={(e) => dragEndHandler(e)}
                                            onDragOver={(e) => dragOverHandler(e)}
                                            onDrop={(e) => dropHandler(e, x, i)}>
                                            {sessionStorage.getItem('token') && <div className={styles.closeButtonWrapper} onClick={(e) => {
                                                // setIsShowAlert(true)
                                                setMemI22(i)
                                                setId2(x?.Id)
                                                setIsShowAlertDeleteSubDir(true)
                                                e.stopPropagation()
                                            }}>X</div>}
                                            {/* {sessionStorage.getItem('token') &&
                                                <div>
                                                    <div className={styles.editButtonWrapper} onClick={(e) => {
                                                        setIsOpenLable(true)
                                                        e.stopPropagation()
                                                    }}>
                                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_436_4)">
                                                                <path d="M9.63433 3.0179L9.19237 3.46007L6.54035 0.808418L6.98231 0.366206C7.47052 -0.122069 8.26223 -0.122069 8.75048 0.366206L9.63433 1.25028C10.1219 1.73829 10.1219 2.52966 9.63433 3.0179ZM3.44655 8.32086C3.3245 8.44291 3.3245 8.64057 3.44655 8.76264C3.56866 8.88537 3.76642 8.88537 3.88847 8.76264L8.75048 3.90197L8.30802 3.45976L3.44655 8.32086ZM1.23656 6.11138C1.11445 6.23354 1.11445 6.43112 1.23656 6.55325C1.3586 6.67532 1.55637 6.67532 1.67848 6.55325L6.54035 1.6922L6.09857 1.25028L1.23656 6.11138ZM6.98173 2.1338L2.1203 6.99513C1.87618 7.23877 1.8768 7.63486 2.1203 7.87898C2.36452 8.12312 2.75997 8.12425 3.00473 7.87832L7.86616 3.0179L6.98173 2.1338ZM3.00347 9.20343C2.85399 9.05377 2.78194 8.8641 2.75513 8.66866C2.69159 8.67847 2.6276 8.6876 2.56222 8.6876C2.22831 8.6876 1.9147 8.55708 1.67848 8.32086C1.44226 8.08404 1.31225 7.77086 1.31225 7.43699C1.31225 7.37611 1.32138 7.31678 1.32996 7.25763C1.12792 7.23012 0.941183 7.14162 0.794693 6.99513C0.780637 6.98118 0.775794 6.96224 0.762996 6.94695L0 9.99986L3.04498 9.2376C3.03156 9.22538 3.01637 9.21623 3.00347 9.20343Z" fill="white" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_436_4">
                                                                    <rect width="10" height="10" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </div>
                                                </div>
                                            } */}
                                            <div style={{ marginLeft: 10, marginRight: 10, marginTop: 13, marginBottom: 13, minHeight: 30, display: 'flex', alignItems: 'center', pointerEvents: 'none' }}>
                                                {x.Name}
                                            </div>

                                        </div>
                                    )
                                })}
                            </>}
                            {sessionStorage.getItem('token') && <div className={styles.subDirectoryElement} style={{ width: width > 1920 ? 240 : 0.05128205128205128 * width + 141.53846153846155 }} onClick={() => setIsShowCreateSubDir(true)}>
                                <div className={styles.plusWrapper}>
                                    +
                                </div>
                            </div>}
                        </div>
                        <div style={{ width: ((width > 1920 ? 240 : width > 768 ? 0.05128205128205128 * width + 141.53846153846155 : 0.13480392156862744 * width + 111.47058823529412) + 10) * Math.trunc((width > 1920 ? 800 : 0.391025641025641 * width + 199.23076923076923) / ((width > 1920 ? 240 : width > 768 ? 0.05128205128205128 * width + 141.53846153846155 : 0.13480392156862744 * width + 111.47058823529412) + (10))) }}>
                            <hr style={{ border: " 1px solid #0092DD", height: 0 }} />
                        </div>

                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {products.map((x, i) => {
                                return (
                                    <div className={styles.wrapProd} draggable={isModerator}
                                        onDragStart={(e) => dragStartHandler2(e, x, i)}
                                        onDragLeave={(e) => dragEndHandler2(e)}
                                        onDragEnd={(e) => dragEndHandler2(e)}
                                        onDragOver={(e) => dragOverHandler2(e)}
                                        onDrop={(e) => dropHandler2(e, x, i)}
                                        style={{ width: width > 1920 ? 240 : width > 768 ? 0.05128205128205128 * width + 141.53846153846155 : 0.13480392156862744 * width + 111.47058823529412, position: 'relative' }}
                                        onClick={(e) => { e.stopPropagation(); navigate('/product_page/' + x.Id); window.scrollTo({top: 0, behavior: "instant"});}}>
                                        {sessionStorage.getItem('token') &&
                                            <div>
                                                <div className={styles.closeButtonWrapper} onClick={(e) => {
                                                    setIsShowAlert(true)
                                                    setMemI2(i)
                                                    setId(x.Id)
                                                    e.stopPropagation()
                                                }}>X</div>
                                                <div className={styles.editButtonWrapper} onClick={(e) => {
                                                    navigate('/product_builder/' + x.Id)
                                                    e.stopPropagation()
                                                }}>
                                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_436_4)">
                                                            <path d="M9.63433 3.0179L9.19237 3.46007L6.54035 0.808418L6.98231 0.366206C7.47052 -0.122069 8.26223 -0.122069 8.75048 0.366206L9.63433 1.25028C10.1219 1.73829 10.1219 2.52966 9.63433 3.0179ZM3.44655 8.32086C3.3245 8.44291 3.3245 8.64057 3.44655 8.76264C3.56866 8.88537 3.76642 8.88537 3.88847 8.76264L8.75048 3.90197L8.30802 3.45976L3.44655 8.32086ZM1.23656 6.11138C1.11445 6.23354 1.11445 6.43112 1.23656 6.55325C1.3586 6.67532 1.55637 6.67532 1.67848 6.55325L6.54035 1.6922L6.09857 1.25028L1.23656 6.11138ZM6.98173 2.1338L2.1203 6.99513C1.87618 7.23877 1.8768 7.63486 2.1203 7.87898C2.36452 8.12312 2.75997 8.12425 3.00473 7.87832L7.86616 3.0179L6.98173 2.1338ZM3.00347 9.20343C2.85399 9.05377 2.78194 8.8641 2.75513 8.66866C2.69159 8.67847 2.6276 8.6876 2.56222 8.6876C2.22831 8.6876 1.9147 8.55708 1.67848 8.32086C1.44226 8.08404 1.31225 7.77086 1.31225 7.43699C1.31225 7.37611 1.32138 7.31678 1.32996 7.25763C1.12792 7.23012 0.941183 7.14162 0.794693 6.99513C0.780637 6.98118 0.775794 6.96224 0.762996 6.94695L0 9.99986L3.04498 9.2376C3.03156 9.22538 3.01637 9.21623 3.00347 9.20343Z" fill="white" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_436_4">
                                                                <rect width="10" height="10" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>

                                                </div>
                                            </div>
                                        }
                                        <a href={"https://tkpoluslab.ru/product_page/" + x.Id} className={styles.link}>
                                            <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); navigate('/product_page/' + x.Id); window.scrollTo({top: 0, behavior: "instant"}); }}>

                                                <div style={{ margin: 12, pointerEvents: 'none' }} >
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <LazyLoadImage
                                                            src={address + "uploads/" + x.Image}
                                                            placeholderSrc={address + "uploads/" + x.Image.split('.')[0] + '-s.jpeg'}
                                                            effect="blur"
                                                            width={width > 1920 ? 210 : 0.04487179487179487 * width + 123.84615384615384}
                                                            height={width > 1920 ? 204 : 0.041025641025641026 * width + 125.23076923076923}
                                                            style={{objectFit:'contain'}}
                                                        />
                                                    </div>
                                                    <div className={styles.labName} style={{ marginBottom: 5 }}>
                                                        {x.Name}
                                                    </div>
                                                    <div style={{ display: 'flex' }}>
                                                        <div className={styles.labDescription} style={{ flex: 1 }}>
                                                            {x["Description"]}
                                                        </div>
                                                        <div>
                                                            <svg width="22" height="11" viewBox="0 0 22 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15.0741 1L21 5.84615M21 5.84615L15.0741 10M21 5.84615H1" stroke="#9E9E9E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })}
                            {(sessionStorage.getItem('token') && currentSubDirectory) &&
                                <div className={styles.wrapProd} style={{ width: width > 1920 ? 240 : width > 768 ? 0.05128205128205128 * width + 141.53846153846155 : 0.13480392156862744 * width + 111.47058823529412, height: width > 1920 ? 292 : width > 768 ? 0.04935897435897436 * width + 197.23076923076923 : 0.024509803921568627 * width + 206.1764705882353 }}
                                    onClick={() => setIsShowModalAddProduct(true)}>
                                    <div className={styles.addButton}>
                                        +
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className={styles.footerWrapper} style={{ zIndex: 100 }}>
                        {width > 480 &&
                            <div style={{ display: 'flex', maxWidth: 1110 }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <LazyLoadImage
                                        src={exclamation}
                                        effect="blur"
                                    />
                                </div>
                                <div className={styles.warningText}>
                                    Убедительная просьба, при покупке учебного оборудования согласовывать важные для вас характеристики, комплектацию и цену учебного оборудования.
                                </div>

                                <div onClick={() => { navigate("/customers"); window.scrollTo({top: 0, behavior: "instant"}); }} style={{ width: 208, display: 'flex', alignItems: 'center' }}>
                                    <TransparentButton width={198} extension={208} text={"Заказчикам"} />
                                </div>
                            </div>}
                        {width <= 480 &&
                            <div style={{ maxWidth: 1110 }}>
                                <div style={{ display: 'flex', margin: 18 }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <LazyLoadImage
                                            src={exclamation}
                                            effect="blur"
                                        />
                                    </div>
                                    <div className={styles.warningText}>
                                        Убедительная просьба, при покупке учебного оборудования согласовывать важные для вас характеристики, комплектацию и цену учебного оборудования.
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div onClick={() => { navigate("/customers"); window.scrollTo({top: 0, behavior: "instant"}); }} style={{ width: 345, display: 'flex', alignItems: 'center', marginBottom: 30 }}>
                                        <TransparentButton width={335} extension={345} text={"Заказчикам"} />
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Partners;
