import './App.css';
import mail2 from './Images/Regular_size/mail2.svg';
import PageWrapper from './Components/PageWrapper/PageWrapper';
import {
  BrowserRouter as Router,
  Route, Routes, Navigate,
} from "react-router-dom";
import { useEffect, useLayoutEffect, useRef, useState, Suspense, lazy } from "react";
import axios from "axios";
import NotFound from './Components/NotFound/NotFound';
import MainPage from './Components/MainPage/MainPage';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import News from './Components/News/News';
import Products from './Components/Products/Products';
import NewsViewer from './Components/NewsViewer/NewsViewer';
import Customers from './Components/Customers/Customers';
import Contact from './Components/Contact/Contact';
import About from './Components/About/About';
import Order804 from './Components/Order804/Order804';
import Moderation from './Components/Moderation/Moderation';
import PageBuilder from './Components/PageBuilder/PageBuilder';
import ProductBuilder from './Components/ProductBuilder/ProductBuilder';
import NewsBuilder from './Components/NewsBuilder/NewsBuilder';
import ProductViewer from './Components/ProductViewer/ProductViewer';
import Uav from './Components/Uav/Uav';
import CommercialOffer from './Components/CommonComponents/CommercialOffer/CommercialOffer';
import Upper from './Components/CommonComponents/Upper/Upper';
import styles from './App.module.css'

axios.interceptors.request.use(
  config => {
    config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

function App() {
  const [isKP, setIsKP] = useState(false)
  return (
    <div>

      <Router>
        <div style={{ display: 'flex', flexDirection: 'column' }} >
        {(!isKP) &&
                <div className={styles.kpWrapper} onClick={() => setIsKP(true)}>
                    <img src={mail2} width={"100%"} />
                </div>
            }
            <Upper/>
            {(isKP) && <CommercialOffer exit={() => setIsKP(false)} />}
          <div style={{ background: "#F6F6F6", width: '100vw', height: '100vh', position: 'fixed', zIndex: -100 }} />
          <div style={{ zIndex: 1000 }}> <Header /> </div>
          <div>
            <Routes>
              {/* <Route exact path='/' element={<Navigate to="/main" />}></Route> Это пример редиректа */}
              <Route exact path='/' element={<MainPage />}></Route>
              <Route exact path='/news' element={<News />}></Route>
              <Route exact path='/news/:id' element={<News />}></Route>
              <Route exact path='/news_view' element={<NewsViewer to="/" />}></Route>
              <Route exact path='/news_view/:id' element={<NewsViewer />}></Route>
              <Route exact path='/products' element={<Products />}></Route>
              <Route exact path='/customers' element={<Customers />}></Route>
              <Route exact path='/contact' element={<Contact />}></Route>
              <Route exact path='/about' element={<About />}></Route>
              <Route exact path='/moderation' element={<Moderation />}></Route>
              <Route exact path='/page_builder/:id' element={<PageBuilder />}></Route>
              <Route exact path='/page_builder' element={<PageBuilder to="/" />}></Route>
              <Route exact path='/product_builder/:id' element={<ProductBuilder />}></Route>
              <Route exact path='/product_builder' element={<ProductBuilder to="/" />}></Route>
              <Route exact path='/news_builder/:id' element={<NewsBuilder />}></Route>
              <Route exact path='/news_builder' element={<NewsBuilder to="/" />}></Route>
              <Route exact path='/product_page/:id' element={<ProductViewer />}></Route>
              <Route exact path='/product_page' element={<Navigate to="/" />}></Route>
              <Route exact path='/order804' element={<Order804 />}></Route>
              <Route exact path='/uav' element={<Uav />}></Route>
              <Route exact path='/404' element={<div />}></Route>
              <Route exact path='*' element={<NotFound />}></Route>
            </Routes>
          </div>
          <div>
            <Footer />
          </div>
        </div>

      </Router>
    </div>
  );
}


export default App;
