import styles from './NewsViewer.module.css';
import DoubleSidedGradient from './../CommonComponents/DoubleSidedGradient/DoubleSidedGradient';
import SlateEditor from '../CommonComponents/Editor/SlateEditor/Editor';
import { useState, useEffect } from "react";
import { useStore } from "effector-react";
import { addressStore } from "../Store/ConfigStore";
import axios from "axios";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import SlateRenderer from '../CommonComponents/Editor/SlateRenderer/Renderer';


function NewsViewer() {
    let { id } = useParams();
    const [newsNamePreview, setNewsNamePreview] = useState("");
    const [isLoad, setIsLoad] = useState(false);
    const [path, setPath] = useState([]);
    const address = useStore(addressStore);
    const [status, setStatus] = useState(false);
    const [newsDescription, setNewsDescription] = useState("");
    const [newsName, setNewsName] = useState("");
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => { // хук отслеживает изменение ширины экрана
        axios.get(address + 'api/v1/articles/getArticle?id=' + id)
            .then(res => {
                setStatus(res.data.Status)
                setPath(res.data.Path)
                setNewsNamePreview(res.data.Description)
                if (res.data.Content?.length !== 0 && res.data.Content?.length !== undefined) {
                    setNewsName(res.data.Content[0].newsName)
                    setNewsDescription(res.data.Content[0].newsDescription)
                    if (res.data.Content[0]?.content) {
                        setContent(res.data.Content[0].content)
                    } else {
                        setContent([{ type: 'paragaph', children: [{ text: '' }], id: "0", },])
                    }
                }
                setIsLoad(true)
            })
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const [content, setContent] = useState([
        {
            type: 'paragaph',
            children: [{ text: '' }],
            id: "0",
        },
    ]);

    let navigate = useNavigate();
    return (
        <div style={width > 1920 ? { justifyContent: 'center', display: 'flex' } : {}}>
            <div style={width > 1920 ? { width: 1920, overflow: 'hidden' } : { overflow: 'hidden' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ flex: 1 }} />
                    <div className={styles.breadcrumbsStyle}>
                        <div className={styles.breadcrumbsElement} onClick={() => {
                            navigate("/news"); window.scrollTo(0, 0);
                        }}>
                            Новости
                        </div>
                        <div style={{ display: 'flex' }}>
                            <span style={{ marginLeft: 5, marginRight: 5 }}> {">"} </span>
                            <div className={styles.breadcrumbsElement}>
                                {newsNamePreview}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <DoubleSidedGradient />
                </div>

                <div className={styles.headerProduct} style={{ justifyContent: 'center' }}>
                    <div className={styles.paragraph} style={{ position: 'relative' }}>
                        <div className={styles.headerWrap} style={{minHeight:150}}>
                            <div style={{ marginTop: 51 }}>
                                <div className={styles.inputNameStyle+" "+styles.paragraph}>
                                    {newsName}
                                </div>

                            </div>
                            <div style={{ marginTop: 31, paddingBottom: 60 }}>
                                <div className={styles.inputDescriptionStyle+" "+styles.paragraph}>
                                    {newsDescription}
                                </div>
                            </div>
                        </div>
                        <div style={{ bottom: 160, left: width > 768 ? -220 : -1000, position: 'absolute', width: 0, height: 0, pointerEvents: 'none', zIndex: 0 }}>
                            <svg width="1390" height="316" viewBox="0 0 1390 316" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M175.462 0.990365C175.645 -0.126267 177.251 -0.126284 177.435 0.990348L199.365 134.127C199.443 134.603 199.85 134.955 200.332 134.964L1390 158L200.332 181.036C199.85 181.045 199.443 181.397 199.365 181.873L177.435 315.01C177.251 316.126 175.645 316.126 175.462 315.01L153.515 181.773C153.445 181.344 153.104 181.009 152.673 180.946L1.79975 158.99C0.658434 158.823 0.658423 157.177 1.79974 157.01L152.673 135.054C153.104 134.991 153.445 134.656 153.515 134.227L175.462 0.990365Z" fill="#C5E2F1" />
                            </svg>
                        </div>
                    </div>
                </div>

                {isLoad &&
                    <div className={styles.justifier} style={{ marginTop: 50 }}>
                        <div className={styles.viewWrapper}>
                            <SlateRenderer content={JSON.stringify(content)} />
                        </div>
                    </div>}
            </div>
        </div>
    );
}

export default NewsViewer;
