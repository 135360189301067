import Header from "../Header/Header";
import styles from './Contact.module.css'
import vk from '../../Images/Regular_size/vk.svg';
import telegram from '../../Images/Regular_size/telegram.svg';
import youtube from '../../Images/Regular_size/youtube.svg';
import DoubleSidedGradient from "../CommonComponents/DoubleSidedGradient/DoubleSidedGradient";
import FirstContact from "./FirstContact/FirstContact";
import SecondContact from "./SecondContact/SecondContact";
import { useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Contact() {
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    return (
        <div className={styles.justifier}>
            <div className={styles.contactBackground}>
                <DoubleSidedGradient />
                <div className={styles.contactTint} />
                <svg width="1390" height="316" viewBox="0 0 1390 316" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.contactCross}>
                    <path d="M175.462 0.990365C175.645 -0.126268 177.251 -0.126284 177.435 0.990348L199.365 134.127C199.443 134.603 199.85 134.955 200.332 134.964L1390 158L200.332 181.036C199.85 181.045 199.443 181.397 199.365 181.873L177.435 315.01C177.251 316.126 175.645 316.126 175.462 315.01L153.515 181.773C153.445 181.344 153.104 181.009 152.673 180.946L1.79975 158.99C0.658434 158.823 0.658423 157.177 1.79974 157.01L152.673 135.054C153.104 134.991 153.445 134.656 153.515 134.227L175.462 0.990365Z" fill="#C5E2F1" />
                </svg>

                <div className={styles.contactTextBox}>
                    <div className={styles.topLeftText}>Наши контакты</div>
                    <div className={styles.topRightText}>На этой странице собраны все контактные данные нашей компании, куда вы можете обратиться за дополнительной информацией.</div>
                </div>
                <FirstContact onLoad={() => { setIsMapLoaded(true) }} />
                <SecondContact doShow={isMapLoaded} />
                <div className={styles.lineWrapper}>
                    <div className={styles.lineSeparator} />
                </div>
                <div className={styles.legalContactsBoxWrapper}>
                    <div className={styles.legalContactsBox}>
                        <div className={styles.firstHalfWrapper}>
                            <div className={styles.legalContactAddress}>
                                <span class="notranslate" translate="no">
                                    ООО "ТК "Полюс" <br /><br />
                                    Юридический адрес 634029, г. Томск,
                                    ул. Белинского, д. 18</span>
                            </div>
                            <div className={styles.legalContactIds}>
                                <span class="notranslate" translate="no"> ОГРН 1027000889255 <br />
                                    ИНН 7017026850 <br />
                                    КПП 701701001</span>
                            </div>
                        </div>
                        <div className={styles.secondHalfWrapper}>
                            <div className={styles.legalContactSupport}>
                                <span class="notranslate" translate="no">
                                    Поддержка: <br />
                                    support@tkpolus.ru 
                                    </span>
                            </div>
                            <div className={styles.legalContactSocials}>
                                {/* <LazyLoadImage
                                    src={vk}
                                    effect="blur"
                                /> */}
                                <a href={"https://t.me/Polusnews"}>
                                    <LazyLoadImage
                                        src={telegram}
                                        effect="blur"
                                    />
                                </a>
                                <a href={"https://www.youtube.com/@poluslab"}>
                                    <LazyLoadImage
                                        src={youtube}
                                        className={styles.youtube}
                                        effect="blur"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Contact;
