import styles from './SecondContact.module.css'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import locate from '../../../Images/Regular_size/blackLocate.svg';
import phone from '../../../Images/Regular_size/blackPhone.svg';
import mail from '../../../Images/Regular_size/convert.svg';
import { useState, useEffect } from "react";

function SecondContact(props) {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className={styles.addressBox}>
            <div className={styles.addressTextBoxContainer}>
                <div className={styles.addressHeaderText}>
                    Офис в Москве
                </div>
                <div className={styles.addressSidebarBox}>
                {width>850 && 
                    <svg width="11" height="240" viewBox="0 0 11 240" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.addressSidebar}>
                        <path d="M5.2847 240C2.36604 240 0 237.634 0 234.715L0 0C5.83732 0 10.5694 4.73209 10.5694 10.5694V234.715C10.5694 237.634 8.20336 240 5.2847 240Z" fill="#C5E2F1"/>
                    </svg>
                }
                {width<=850 &&
                    <svg width="8" height="230" viewBox="0 0 8 230" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.addressSidebar}>
                        <path d="M4 230C1.79086 230 0 228.209 0 226L0 0C4.41828 0 8 3.58173 8 8V226C8 228.209 6.20914 230 4 230Z" fill="#C5E2F1"/>
                    </svg>
                }
                    <div className={styles.addressItemBox}>
                        <div className={styles.addressItem}>
                            <LazyLoadImage
                                src={locate}
                                effect="blur"
                                height={30}
                                width={25} 
                            />
                            <div className={styles.addressItemText}>
                                109029, г. Москва, ул. Скотопрогонная, 27/26с1
                                (Часы работы Пн.-Пт. с 9.00 - 18.00)
                            </div>
                        </div>     
                        <div className={styles.addressItem}>
                            <LazyLoadImage
                                src={phone}
                                effect="blur"
                                height={25}
                                width={25} 
                            />
                            <div className={styles.addressItemText}>
                                +7 901 057 2457
                            </div>
                        </div>  
                        <div className={styles.addressItem}>
                            <LazyLoadImage
                                src={mail}
                                effect="blur"
                                height={18}
                                width={25} 
                            />
                            <div className={styles.addressItemText}>
                                Почта для связи: <br/>
                                msk@tkpolus.ru
                            </div>
                        </div>                             
                    </div>
                </div>
            </div>
            <div className={styles.addressMapWrapper}>
                {   
                    props.doShow &&
                    <YMaps>
                        <Map defaultState={{ center: [55.733153, 37.697118], zoom: 16, locate: [55.733153, 37.697118] }} style={{ width: "100%", height: "100%" }}>
                            <Placemark geometry={[55.733153, 37.697118]} />
                        </Map>
                    </YMaps>
                }
            </div>
        </div>
    );
}

export default SecondContact;
