import { useRef, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import styles from './ImageLinksEditor.module.css'
import plus from '../../../Images/Regular_size/plus.svg';
import close from '../../../Images/Regular_size/X.svg';
const uploadFile = (file, onSuccess) => {
    const token = sessionStorage.getItem('token')
    let proceed = true
    let fileType = file.type
    if (file.name.endsWith(".zip")){
        fileType = "application/zip"
    }
    if (file.name.endsWith(".rar")){
        fileType = "application/vnd.rar"
    }
    fetch(
        'https://tkpoluslab.ru:8080/api/v1/files/upload',
        {
            method: 'POST',
            body: file,
            headers: {
                'Content-type': fileType,
                'Content-length': `${file.size}`,
                'Authorization': 'Bearer '+token,
                },
        })
        .catch((err) => {proceed = false})
        .then((resp)=>
        {
            if (proceed && resp.ok){
                resp.json().then((json)=>
                {
                    onSuccess(json.FileName)
                })
            }else{
                alert("Ошибка загрузки")
            }
        }) 
}
function ImageLinksEditor({productLinks,setProductLinks}) {
    const fileRef = useRef(null);
    return (
        <div className={styles.imageLinksEditorWrapper}>
            <input type="file"
                    id="fileInput" name="fileInput"
                    onInput = {(e)=>{
                        if (e.target.files) {
                            uploadFile(e.target.files[0],(fileName) => {
                                const url = prompt("Введите ссылку:")
                                if (url!==null&&url!==""){
                                    setProductLinks([...productLinks,{url:url,file:fileName}])
                                }
                            })
                        }
                    }}
                    onClick={(e)=>{
                        e.value = ''
                        e.target.value = ''
                    }}
                    style={{display:'none'}}
                    ref = {fileRef}
                />
            {productLinks&&productLinks.map((x, i) => {
                                        return (
                                            <div>
                                                <a href={x.url} target="_blank">
                                                    <LazyLoadImage 
                                                        draggable="false" 
                                                        src={'https://tkpoluslab.ru:8080/uploads/'+x.file} 
                                                        className={styles.itemIcon}
                                                    />
                                                </a>
                                                <LazyLoadImage 
                                                    draggable="false" 
                                                    src={close} 
                                                    className={styles.closeIcon}
                                                    onClick={()=>{
                                                        const temp = [...productLinks]
                                                        temp.splice(i,1)
                                                        setProductLinks(temp)
                                                    }}
                                                />
                                            </div>
                                        )
                                    })}
           <LazyLoadImage draggable="false" src={plus} className={styles.itemIcon} onClick={()=>{
                console.log(productLinks)
                fileRef.current.click()
           }}/>
        </div>
    );
}

export default ImageLinksEditor;
