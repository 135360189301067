import styles from './PageContent.module.css'


function PageContent(props) { // pageData address

    return (
        <div>
            PageContent
        </div>
    );
}


export default PageContent;
