import { useLayoutEffect, useRef, useState, useMemo, useEffect, Component } from "react";
import Select from 'react-select';
import axios from "axios";
import { useStore } from "effector-react";
import { addressStore } from "../../Store/ConfigStore";
import styles from './CommercialOffer.module.css'
import BlueButton from './../BlueButton/BlueButton';
import necheck from '../../../Images/Regular_size/necheck.svg';
import check from '../../../Images/Regular_size/check.svg';
import buttonIcon from '../../../Images/Regular_size/buttonIcon.svg';
import close from '../../../Images/Regular_size/close.svg';
import arrowvniz from '../../../Images/Regular_size/arrowvniz.svg';
import CustomAlert from './../CustomAlert/CustomAlert';

const options = [
    { value: 'Цифровые лаборатории', label: 'Цифровые лаборатории' },
    { value: 'Робототехника', label: 'Робототехника' },
    { value: 'БПЛА (БВС)', label: 'БПЛА (БВС)' },
    { value: 'Учебное оборудование', label: 'Учебное оборудование' },
];

function CommercialOffer(props) {
    const [isShow, setIsShow] = useState(false)
    const [alertText, setAlertText] = useState("")
    const [width, setWidth] = useState(window.innerWidth); // тут хранится ширина экрана
    const [directory, setDirectory] = useState("")
    const [isMouseOverButton, setIsMouseOverButton] = useState(false);
    const [flagUsl, setFlagUsl] = useState(false)
    const [flagRass, setFlagRass] = useState(true)

    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [phone, setPhone] = useState("");
    const [organization, setOrganization] = useState("");
    const [product, setProduct] = useState(null);

    const address = useStore(addressStore);
    const [isBlock, setIsBlock] = useState(false)

    const alertHandler = (text) => {
        setAlertText(text)
        setIsShow(true)
    }


    useEffect(() => { // хук отслеживает изменение ширины экрана
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const sendHandler = () => {
        if (isBlock) {
            alertHandler("Идёт отправка...")
            return
        }
        if (name === "") {
            alertHandler("Введите ваше имя")
            return
        }
        if (mail === "") {
            alertHandler("Введите вашу почту")
            return
        }
        if (phone === "") {
            alertHandler("Укажите ваш номер телефона")
            return
        }
        if (!phone.match(/(?:\+|\d)[\d\-\(\) ]{9,}\d/gi)) {
            alertHandler("Неправильный номер")
            return
        }
        if (organization === "") {
            alertHandler("Укажите вашу организацию")
            return
        }
        if (product === "" || !product) {
            alertHandler("Укажите интересующий вас продукт")
            return
        }
        if (!flagUsl) {
            alertHandler("Вы должны быть согласны с условиями обработки персональных данных")
            return
        }
        setIsBlock(true)
        const request = {
            "Name": name,
            "Email": mail,
            "Phone": phone,
            "Organization": organization,
            "Product": product.value,
            "Subscribe": flagRass
        };
        axios.post(address + `api/v1/offers/send`, request)
            .then(res => {
                if (res.status === 200) {
                    // alertHandler("Заявка успешно отправлена");
                    props.exit()
                    setIsBlock(false)
                }
            }).catch((error) => {
                alertHandler("Ошибка отправки")
                setIsBlock(false)
            });

    }

    const [uniqueId] = useState(
        () => 'select_' + Math.random().toFixed(5).slice(2),
    );



    return (

        <div className={styles.shadowWrapper} onClick={() => props.exit()} style={isBlock ? { cursor: 'wait' } : {}}>
            <CustomAlert exit={() => setIsShow(false)} text={alertText} isShow={isShow} />
            {width < 768 &&
                <div className={styles.CommercialOfferWrapperMini} onClick={(e) => e.stopPropagation()} >
                    <div className={styles.textHeaderMini}>
                        Получить КП на товары компании ООО "ТК "Полюс"
                    </div>
                    <input
                        style={{ marginTop: 46 }}
                        value={name}
                        className={styles.inputNameMini}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={"Введите имя..."}
                    />
                    <input
                        style={{ marginTop: 20 }}
                        value={mail}
                        className={styles.inputNameMini}
                        onChange={(e) => setMail(e.target.value)}
                        placeholder={"Ваш Email..."}
                    />
                    <div className={styles.phoneWrapperMini}>
                        Телефон
                    </div>
                    <input
                        type="tel"
                        id="phone"
                        style={{ marginTop: 8 }}
                        value={phone}
                        className={styles.inputNameMini}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder={"+7"}
                    />
                    <input
                        style={{ marginTop: 20 }}
                        value={organization}
                        className={styles.inputNameMini}
                        onChange={(e) => setOrganization(e.target.value)}
                        placeholder={"Организация"}
                    />

                    <div className={styles.productBoxWrapperMini} >
                        <div className={styles.productBoxMini}>
                            <div className={styles.productEditMini}>
                                <div className={styles.selectStyleMini}>
                                    <Select
                                        className={styles.selectStyle2Mini}
                                        placeholder={"Продукт"}
                                        defaultValue={product}
                                        onChange={setProduct}
                                        options={options}
                                        id={uniqueId}
                                        onMenuClose={() => {
                                            const menuEl = document.querySelector(`#${uniqueId} .menu`);
                                            const containerEl = menuEl?.parentElement;
                                            const clonedMenuEl = menuEl?.cloneNode(true);

                                            if (!clonedMenuEl) return;

                                            clonedMenuEl.classList.add('menu--close');
                                            clonedMenuEl.addEventListener('animationend', () => {
                                                containerEl?.removeChild(clonedMenuEl);
                                            });

                                            containerEl?.appendChild(clonedMenuEl);
                                        }}

                                    />
                                </div>

                                <div style={{ userSelect: 'none', cursor: 'pointer' }}>
                                    {(product === "" || !product) && <div>Продукт</div>}
                                    {(!(product === "" || !product)) && <div>{product.label}</div>}
                                </div>
                                <div className={styles.arrowvnizImage}>
                                    <img
                                        src={arrowvniz} width={"100%"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.checkBoxesWrapper}>
                        <div className={styles.checkBoxWrapper} style={{ marginTop: 24 }}>
                            <div className={styles.checkBox} onClick={() => setFlagUsl(!flagUsl)}>
                                <img src={flagUsl ? check : necheck} height={"100%"} />
                            </div>
                            <div className={styles.checkBoxTextMini} style={{ marginLeft: 10 }}>
                                Согласен с условиями обработки <span style={{ textDecoration: 'underline' }}> персональных данных</span>
                            </div>
                        </div>
                        <div className={styles.checkBoxWrapper} style={{ marginTop: 12 }}>
                            <div className={styles.checkBox} onClick={() => setFlagRass(!flagRass)}>
                                <img src={flagRass ? check : necheck} height={"100%"} />
                            </div>
                            <div className={styles.checkBoxTextMini} style={{ marginLeft: 10 }}>
                                Согласие на получение новостных рассылок ООО "ТК "Полюс"
                            </div>
                        </div>
                    </div>

                    <div className={styles.BlueButtonWrapperMini} >
                        <div className={styles.buttonWrapper} style={!isMouseOverButton ? { background: "#0092DD", width: "335px" } : { background: "linear-gradient(166.51deg, rgba(255, 255, 255, 0) 9.67%, rgba(254, 254, 254, 0.5) 227.74%), #0092DD", width: "335px" }} onMouseOver={() => setIsMouseOverButton(true)}
                            onMouseLeave={() => setIsMouseOverButton(false)} onClick={() => sendHandler()}>
                            <div className={styles.buttonTextMini} style={{ display: 'flex', marginLeft: 90 }}>
                                {"Отправить заявку"}
                            </div>
                            <div style={{ flex: 1 }} />
                            <div style={{ display: 'flex', marginRight: "1.5vmax", minWidth: "28px", width: "1.4vmax", height: "0.5vmax" }}>
                                <img
                                    src={buttonIcon} height={"100%"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }


            {width >= 768 &&
                <div className={styles.CommercialOfferWrapper} onClick={(e) => e.stopPropagation()} >
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <div className={styles.textHeader}>
                                Получить КП на товары компании ООО "ТК "Полюс"
                            </div>
                            <div className={styles.text}>
                                На этой странице Вы можете оставить свои данные и оформить заявку на коммерческое предложение от нашей компании.
                            </div>
                            <div className={styles.BlueButtonWrapper}>
                                <div className={styles.buttonWrapper} style={(!isMouseOverButton || flagUsl === false) ? { background: "#0092DD", width: "13vmax", minWidth: "240px" } : { background: "linear-gradient(166.51deg, rgba(255, 255, 255, 0) 9.67%, rgba(254, 254, 254, 0.5) 227.74%), #0092DD", width: "16vmax", minWidth: "270px" }} onMouseOver={() => setIsMouseOverButton(true)}
                                    onMouseLeave={() => setIsMouseOverButton(false)} onClick={() => sendHandler()}>
                                    <div className={styles.buttonText} style={{ display: 'flex', marginLeft: 30 }}>
                                        {"Отправить заявку"}
                                    </div>
                                    <div style={{ flex: 1 }} />
                                    <div style={{ display: 'flex', marginRight: "1.5vmax", minWidth: "28px", width: "1.4vmax", height: "0.5vmax" }}>
                                        <img
                                            src={buttonIcon} height={"100%"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: 1, marginLeft: 10 }}>
                            <div className={styles.closeLineWrapper}>
                                <div className={styles.closeWrapper} onClick={() => props.exit()}>
                                    <img
                                        src={close} height={"100%"}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '1.5vmax', justifyContent: 'space-between' }}>
                                <input
                                    value={name}
                                    className={styles.inputName}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder={"Введите имя..."}
                                />
                                <input
                                    value={mail}
                                    className={styles.inputMail}
                                    onChange={(e) => setMail(e.target.value)}
                                    placeholder={"Ваш Email..."}
                                />
                            </div>
                            <div className={styles.phoneWrapper}>
                                Телефон
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <input
                                    type="tel"
                                    id="phone"
                                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                    value={phone}
                                    className={styles.inputName}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder={"+7"}

                                />
                                <input
                                    value={organization}
                                    className={styles.inputMail}
                                    onChange={(e) => setOrganization(e.target.value)}
                                    placeholder={"Организация"}
                                />
                            </div>
                            <div className={styles.productBoxWrapper}>
                                <div className={styles.productBox}>
                                    <div className={styles.productHeader}>
                                        Интересуемый продукт
                                    </div>
                                    <div className={styles.productEdit}>
                                        <div className={styles.selectStyle}>
                                            <Select
                                                className={styles.selectStyle2}
                                                placeholder={"Продукт"}
                                                defaultValue={product}
                                                onChange={setProduct}
                                                options={options}
                                            />
                                        </div>

                                        <div style={{ userSelect: 'none', cursor: 'pointer' }}>
                                            {(product === "" || !product) && <div>Продукт</div>}
                                            {(!(product === "" || !product)) && <div>{product.label}</div>}
                                        </div>
                                        <div className={styles.arrowvnizImage}>
                                            <img
                                                src={arrowvniz} width={"100%"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.checkBoxesWrapper}>
                        <div className={styles.checkBoxWrapper}>
                            <div className={styles.checkBox} onClick={() => setFlagUsl(!flagUsl)}>
                                <img src={flagUsl ? check : necheck} height={"100%"} />
                            </div>
                            <div className={styles.checkBoxText}>
                                Согласен с условиями обработки &nbsp; <span style={{ textDecoration: 'underline' }}> персональных данных</span>
                            </div>
                        </div>
                        <div className={styles.checkBoxWrapper} style={{ marginTop: '0.5vmax' }}>
                            <div className={styles.checkBox} onClick={() => setFlagRass(!flagRass)}>
                                <img src={flagRass ? check : necheck} height={"100%"} />
                            </div>
                            <div className={styles.checkBoxText}>
                                Согласие на получение новостных рассылок ООО "ТК "Полюс"
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div >
    );
}

export default CommercialOffer;
