import styles from './NewsScrollBlock.module.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from "react-router-dom";

function NewsScrollBlock(props) {
    let navigate = useNavigate();
    return (
        <div className={styles.newsScrollBlock} onClick={(e) => {
            navigate('/news_view/' + props.Id)
            window.scrollTo({top: 0, behavior: "instant"});
            e.stopPropagation()
        }}>
            <div className={styles.newsImageHolder}>
                <LazyLoadImage
                    src={props.imageURL}
                    placeholderSrc={props.imageURL}
                    effect="blur"
                    width={"100%"}
                    className={styles.newsImage}
                />
            </div>
            <div className={styles.hoverBox}>
                <svg width="10" height="70" viewBox="0 0 10 70" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.newsHoverImage}>
                    <path d="M5 70C2.23858 70 0 67.7614 0 65L0 0V0C5.52285 0 10 4.47715 10 10L10 65C10 67.7614 7.76142 70 5 70V70Z" fill="#0092DD"/>
                </svg>
                <div className={styles.newsText}>{props.text}</div>
            </div>
        </div>
    );
}
export default NewsScrollBlock;
