import { useLayoutEffect, useRef, useState, useMemo, useEffect } from "react";
import styles from './CustomAlert.module.css'

function CustomAlert(props) {
    return (
        
            <div style={props.isShow ? { opacity: 1, transition: '0.3s', zIndex: 1011 } : { opacity: 0, transition: '0.3s', pointerEvents: 'none', zIndex: 1011 }}>
                <div className={styles.shadowWrapper} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.alertWrapper}>
                        <div style={{ position: 'absolute', width: "100%" }}>
                            <div className={styles.text}>
                                {props.text}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className={styles.marginAlert} />
                            <div className={styles.buttonWrapper}>
                                <div className={styles.button} onClick={() => props.exit()}>
                                    ОК
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
   
    );
}

export default CustomAlert;
