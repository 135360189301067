import Slider from './../../Slider/Slider';
import { useState, useEffect } from 'react';

function MainSlider(props) {
    const [width, setWidth] = useState(window.innerWidth); // тут хранится ширина экрана
    useEffect(() => { // хук отслеживает изменение ширины экрана
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div>
            <div style={(width > 650) ? { marginTop: -100, zIndex: 0 } : { zIndex: 0 }}>
                <div style={width <= 1920 ? { marginLeft: -((width > 768 ? 1920 : (width > 650 && width <= 768) ? 1560 : (width > 360 && width <= 650) ? 740 : 720) - width) / 5 } : { display: 'flex', justifyContent: 'center', marginLeft: 0 }}>
                    {width > 768 && <Slider isWidth={props.isWidth} isOpen={props.isOpen} width={1920} height={820} />}
                    {(width > 650 && width <= 768) && <Slider isWidth={props.isWidth} isOpen={props.isOpen} width={1560} height={666} />}
                    {(width > 360 && width <= 650) && <Slider isWidth={props.isWidth} isOpen={props.isOpen} width={740} height={315} />}
                    {(width <= 360) && <Slider isWidth={props.isWidth} isOpen={props.isOpen} width={720} height={300} />}
                </div>
            </div>

        </div >
    );
}

export default MainSlider;
