import { Block } from 'slate';
import { Editor, Transforms,Element as SlateElement} from 'slate'
import {useSlateStatic, useSlate, ReactEditor} from 'slate-react'
import { useSelected, useFocused } from "slate-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Button from '../common/Button'
import Icon from '../common/Icon'
import { makeId } from './withNodeId';
import React, { useEffect, useRef, useState } from 'react';
import { uploadFile } from '../Toolbar/Toolbar';
import {Slider} from 'react';

const alignment = ['alignLeft','alignRight','alignCenter','alignJustify']
const list_types = ['orderedList','unorderedList']



export const sizeMap = {
    small:'0.75em',
    normal:'1em',
    medium:'1.75em',
    huge:'2.5em'
}
export const toggleBlock = (editor,format)=>{   
    const isActive = isBlockActive(editor,format);
    const isList = list_types.includes(format)
    const isIndent = alignment.includes(format)
    const isAligned = alignment.some(alignmentType => isBlockActive(editor,alignmentType))
    if(isAligned && isIndent){
        Transforms.unwrapNodes(editor,{
            match:n => alignment.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
            split:true
        })
    }
    
    
    /* Wraping the nodes for alignment, to allow it to co-exist with other block level operations*/
    if(isIndent){
        Transforms.wrapNodes(editor,{
            type:format,
            children:[]
        })
        return
    }
    Transforms.unwrapNodes(editor,{
        match:n => list_types.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
        split:true
    })
    
    Transforms.setNodes(editor,{
        type:isActive?'paragraph':isList?'list-item':format,
    })
    
    
    if(isList && !isActive){
        Transforms.wrapNodes(editor,{
            type:format,
            children:[]
        })
    }

}
export const addMarkData = (editor,data)=>{
    Editor.addMark(editor,data.format,data.value);
}
export const toggleMark = (editor,format)=>{
    const isActive = isMarkActive(editor, format)
    
    if (isActive) {
        Editor.removeMark(editor,format)
    } else {
        Editor.addMark(editor, format, true)
    }
}
export const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    
    return marks ? marks[format] === true : false
}

export const isBlockActive = (editor,format)=>{
    
    const [match] = Editor.nodes(editor,{
        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format
    })
    
    
    return !!match;
}

export const activeMark = (editor,format) =>{
    const defaultMarkData = {
        color:'black',
        bgColor:'black',
        fontSize:'normal',
        fontFamily:'sans'
    } 
    const marks = Editor.marks(editor);
    const defaultValue = defaultMarkData[format];
    return marks?.[format] ?? defaultValue;
}

export const getMarked = (leaf,children) =>{
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }
    
    if (leaf.code) {
        children = <code>{children}</code>
    }
    
    if (leaf.italic) {
        children = <em>{children}</em>
    }
    if (leaf.strikethrough) {
        children = <span style={{textDecoration:'line-through'}}>{children}</span>
    }
    if (leaf.underline) {
        children = <u>{children}</u>
    }
    if(leaf.superscript){
        children = <sup>{children}</sup>
    }
    if(leaf.subscript){
        children = <sub>{children}</sub>
    }
    if(leaf.color){
        children = <span style={{color:leaf.color}}>{children}</span>
    }
    if(leaf.bgColor){
        children = <span style={{backgroundColor:leaf.bgColor}}>{children}</span>
    }
    if(leaf.fontSize){
        const size = sizeMap[leaf.fontSize]
        children = <span style={{fontSize:size}}>{children}</span>
    }
    if(leaf.link){
        children = <a href={"placeholder"}>{children}</a>
    }
    return children;
}
const SliderInput = (props)=>{
    const [value, setValue] = useState(props.defaultScale)
    return(
        <input
            max='100'
            min='10'
            value={value}
            type='range'
            onClick={e => e.stopPropagation()}
            onMouseDown={e => e.stopPropagation()}
            onMouseUp={e => e.stopPropagation()}
            onChange={e => {
                const newValue = e.target.value
                props.onChange(newValue)
                setValue(newValue)
            }}
        />
    )
}
const ImageElement = ({ attributes, children, element }) => {
    const selected = useSelected();
    const editor = useSlate();
    const path = ReactEditor.findPath(editor,element)
    let trueScale = 100
    if (element.scale){
        trueScale = element.scale   
    }
    return (
        <div 
            {...attributes} 
            style={{display:'flex', justifyContent:'left', flexDirection:element.alignImage==='center'? 'column' : 'row'}}
        >
            <div 
                style={{
                        userSelect: "none", 
                        order:element.alignImage==='right'? 2 : 1,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: element.alignImage==='center'? 'center' : '',
                        marginLeft:'20px',
                        marginright:'20px'
                    }}
                contentEditable={false}
                onMouseDown={(e)=>{
                    e.preventDefault()
                    Transforms.select(editor,path)
                }}
            >
                {selected && <div
                    contentEditable={false}
                    style={{
                        position:'absolute',
                        right:0
                    }}
                >
                    <Button onMouseDown={
                        ()=>{
                                Transforms.setNodes(editor,{alignImage: 'left'})
                            }
                        }
                        active={element.alignImage==='left'}
                    >
                        <Icon icon={'alignLeft'}/>
                    </Button>
                    <Button onMouseDown={
                        ()=>{
                                Transforms.setNodes(editor,{alignImage: 'center'})
                            }
                        }
                        active={element.alignImage==='center'}
                        >
                        <Icon icon={'alignCenter'}/>
                    </Button>
                    <Button onMouseDown={
                        ()=>{
                                Transforms.setNodes(editor,{alignImage: 'right'})
                            }
                        }
                        active={element.alignImage==='right'}
                        >
                        <Icon icon={'alignRight'}/>
                    </Button>
                    <SliderInput defaultScale={element.scale} onChange={(newValue)=>{Transforms.setNodes(editor,{scale:newValue})}}/>
                    <Button onMouseDown={
                        ()=>{
                                Transforms.setNodes(editor,{background: element.background===true?false:true})
                            }
                        }
                        active={element.background===true}
                        >
                        <Icon icon={'image'}/>
                    </Button>
                </div>}
                <LazyLoadImage
                    src={element.url}
                    placeholderSrc={element.url}
                    effect="blur"
                    draggable='false'
                    width={830*(trueScale/100)}
                />
            </div>
            <div style={{width:'100%', order:element.alignImage==='right'? 1 : 2}}>
                {children}
            </div>
        </div>
    )
  }
  const SeparatorElement = ({ attributes, children, element }) => {
    const selected = useSelected();
    const editor = useSlate();
    const path = ReactEditor.findPath(editor,element)
    return (
        <div {...attributes} contentEditable={false}>
            {selected && <div
                    contentEditable={false}
                >
                    <Button onMouseDown={
                        ()=>{
                                Transforms.setNodes(editor,{alignSelf: 'left'})
                            }
                        }
                        active={element.alignSelf==='left'}
                        >
                        <Icon icon={'alignLeft'}/>
                    </Button>
                    <Button onMouseDown={
                        ()=>{
                                Transforms.setNodes(editor,{alignSelf: 'right'})
                            }
                        }
                        active={element.alignSelf==='right'}
                        >
                        <Icon icon={'alignRight'}/>
                    </Button>
                </div>}
            <div style={{display:'flex', justifyContent:element.alignSelf==='left'?'left':'right'}}>
                <svg width="100" height="10" viewBox="0 0 100 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={element.alignSelf==='left'?{}:{transform:'scale(-1,1)'}}>
                    <path d="M0 5C0 2.23858 2.23858 0 5 0H100C100 5.52285 95.5229 10 90 10H5C2.23858 10 0 7.76142 0 5Z" fill="#0092DD"/>
                </svg>
            </div>
            {children}
        </div>
    )
  }
  const ImageGalleryElement = ({ attributes, children, element }) => {
    const selected = useSelected();
    const editor = useSlate();
    const path = ReactEditor.findPath(editor,element)
    const [currentItemId, setCurrentItemId] = useState(null); 
    const ref = useRef(null);
    return (
        <div {...attributes} contentEditable={false} >
            {selected && <div style={{marginBottom:'10px'}}>
                <Button onMouseDown={
                    ()=>{
                            Transforms.setNodes(editor,{displayType: 'slider'})
                        }
                    }
                    active={element.displayType==='slider'}
                    >
                    Слайдер
                </Button>
                <Button onMouseDown={
                    ()=>{
                            Transforms.setNodes(editor,{displayType: 'carousel'})
                        }
                    }
                    active={element.displayType==='carousel'}
                    >
                    Карусель
                </Button>
            </div>}
            <div style={{display:'grid',gridTemplateColumns:'repeat(4, 120px)', marginLeft:'10px'}}>
                <input type="file"
                        id="imageInput" name="imageInput"
                        accept="image/*"
                        onInput = {(e)=>{
                            if (e.target.files) {
                                uploadFile(e.target.files[0],(fileName) => {
                                    const finalUrl = 'https://tkpoluslab.ru:8080/uploads/'+fileName
                                    if (currentItemId){
                                        const index = element.items.map(e => e.id).indexOf(currentItemId);
                                        const currentItem = element.items[index]
                                        Transforms.setNodes(editor, {items:[
                                            ...element.items.slice(0,index),
                                            {url:finalUrl,id:currentItem.id,key:currentItem.key},
                                            ...element.items.slice(index+1)
                                        ]}, {at: path})
                                    }else{
                                        Transforms.setNodes(editor,{items:[...element.items,{url:finalUrl, id:makeId(), key:makeId()}]}, {at: path})
                                    }
                                    Transforms.select(editor,path)
                                })
                            }
                        }}
                        onClick={(e)=>{
                            e.value = ''
                            e.target.value = ''
                        }}
                        style={{display:'none'}}
                        ref = {ref}
                    />
                {element.items.map((currentItem)=>{
                    return(
                        <div style={{height:'90px', width:'120px', border:'solid 1px',display:'flex',justifyContent:'right'}} key={currentItem.key}
                            onDoubleClick={()=>{
                                setCurrentItemId(currentItem.id)
                                ref.current.click()
                            }}
                        >
                            <div style={{position:'absolute', marginRight:'3px', cursor:'pointer', userSelect:'none'}}
                                onMouseDown={()=>{
                                    Transforms.setNodes(editor,{items:element.items.filter((item)=>{
                                        return item.id !== currentItem.id
                                    })})
                                }}
                            >✕</div>
                            <LazyLoadImage
                                src={currentItem.url}
                                placeholderSrc={currentItem.url}
                                effect="blur"
                                draggable='false'
                                style={{height:'90px', width:'120px',objectFit:'cover'}}
                            />
                        </div>
                    )
                })}
                <div style={{height:'90px', width:'120px', border:'solid 1px', display:'flex', flexDirection:'row', justifyContent:'center', cursor:'pointer'}}
                    onMouseDown={()=>{
                        if (selected){
                            setCurrentItemId(null)
                            ref.current.click()
                        }
                    }}
                >
                    <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <Icon icon={'imageAdd'}/>
                    </div>
                </div>
                {children}
            </div>
        </div>
    )
  }
  const YoutubeElement = ({ attributes, children, element }) => {
    return (
        <div {...attributes} contentEditable={false}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'center'}}>
                <iframe
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/${element.url}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </div>
            {children}
        </div>
    )
  }
  const VideoElement = ({ attributes, children, element }) => {
    return (
        <div {...attributes} contentEditable={false}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'center', height: '300px'}}>
                <video controls="controls">
                    <source src={element.url}/>
                </video>
            </div>
            {children}
        </div>
    )
  }
  const FileElement = ({ attributes, children, element }) => {
    const selected = useSelected();
    const editor = useSlate();
    return (
        <div {...attributes}>
            {selected && <div style={{marginBottom:'10px', position:'absolute'}} contentEditable={false}>
                        <Button onMouseDown={
                            ()=>{
                                    Transforms.setNodes(editor,{displayType: 0})
                                }
                            }
                            active={element.displayType===0}
                            >
                            Текст сверху
                        </Button>
                        <Button onMouseDown={
                            ()=>{
                                    Transforms.setNodes(editor,{displayType: 1})
                                }
                            }
                            active={element.displayType===1}
                            >
                            Текст слева
                        </Button>
                    </div>}
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                <div style={{display:'flex', flexDirection:element.displayType===0?'column':'row', width:'50%'}}>
                    <div style={{width:'50%', marginTop: '10px'}}>
                        {children}
                    </div>
                    <a href={element.url} download style={{zIndex:'100'}}>
                        <div contentEditable={false} style={{display: 'flex', height: '39px', width: '100px', borderRadius: '10px 1px 10px 10px', border: '2px solid', justifyContent: 'center', cursor: 'pointer' }}>
                            <div contentEditable={false} style={{display: 'flex', flexDirection:'column', justifyContent: 'center' }}>
                                Загрузить
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
  }
  const ConclusionElement = ({ attributes, children, element }) => {
    const selected = useSelected();
    const editor = useSlate();
    return (
        <div {...attributes} style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
            {selected && <div style={{marginBottom:'10px', userSelect: 'none'}} contentEditable={false} >
                        <Button onMouseDown={
                            ()=>{
                                    Transforms.setNodes(editor,{displayType: 0})
                                }
                            }
                            contentEditable={false} style={{userSelect: 'none'}}
                            active={element.displayType===0}
                            >
                            <span>Заключение с чертами</span>
                        </Button>
                        <Button onMouseDown={
                            ()=>{
                                    Transforms.setNodes(editor,{displayType: 1})
                                }
                            }
                            contentEditable={false} style={{userSelect: 'none'}}
                            active={element.displayType===1}
                            >
                            <span>Заключение слева со стрелкой</span>
                        </Button>
                    </div>}
            <div contentEditable={false} style={{userSelect: 'none'}}><hr style={{width:'90%', userSelect: 'none'}}/></div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
                <div style={{width:'50%'}}>
                    {children}
                </div>
            </div>
            <div contentEditable={false} style={{userSelect:'none'}}><hr style={{width:'90%', userSelect: 'none'}}/></div>
        </div>)
  }
export const getBlock = (props) => {
    const {element,children} = props;
    const attributes = props.attributes ?? {};
    switch(element.type){
        case 'headingOne':
            return <h1 {...attributes} {...element.attr}>{children}</h1>
        case 'headingTwo':
            return <h2 {...attributes} {...element.attr}>{children}</h2>
        case 'headingThree':
            return <h3 {...attributes} {...element.attr}>{children}</h3>
        case 'blockquote':
            return <blockquote {...attributes} {...element.attr}>{children}</blockquote>
        case 'alignLeft':
            return <div style={{textAlign:'left',listStylePosition:'inside'}} {...attributes} {...element.attr}>{children}</div>
        case 'alignCenter':
            return <div style={{textAlign:'center',listStylePosition:'inside'}} {...attributes} {...element.attr}>{children}</div>
        case 'alignRight':
            return <div style={{textAlign:'right',listStylePosition:'inside'}} {...attributes} {...element.attr}>{children}</div>
        case 'alignJustify':
            return <div style={{textAlign:'justify', listStylePosition:'inside'}} {...attributes} {...element.attr}>{children}</div>
        case 'formatClear':
            return <div  {...attributes} {...element.attr}>{children}</div>
        case 'list-item':
            return  <li {...attributes} {...element.attr}>{children}</li>
        case 'orderedList':
            return <ol type='1' {...attributes}>{children}</ol>
        case 'unorderedList':
            return <ul {...attributes}>{children}</ul>
        case 'image':
            return <ImageElement {...props}/>
        case 'separator':
            return <SeparatorElement {...props}/>
        case 'gallery':
            return <ImageGalleryElement {...props}/>
        case 'video':
            return <VideoElement {...props}/>
        case 'youtube':
            return <YoutubeElement {...props}/>
        case 'file':
            return <FileElement {...props}/>
        case 'conclusion':
            return <ConclusionElement {...props}/>
        default :
            return <div {...element.attr} {...attributes}>{children}</div>
    }
} 