import styles from './NewsPreloader.module.css'
import { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

function NewsPreloader(props) {
    const [width, setWidth] = useState(window.innerWidth);
    let navigate = useNavigate();
    useEffect(() => { // хук отслеживает изменение ширины экрана
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div style={width > 1920 ? { justifyContent: 'center', display: 'flex' } : {}}>
            <div style={width > 1920 ? { width: 1920, overflow: 'hidden' } : { overflow: 'hidden' }}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <div className={styles.headerProduct} style={{ justifyContent: 'center' }}>
                        <div className={styles.paragraph} style={{ position: 'relative' }}>
                            <div className={styles.headerWrap} style={{ minHeight: 150 }}>
                                <div className={styles.mainBoxPreloader} style={width>1400?{ position: 'relative', width:"110%" }:{ position: 'relative' }}>
                                    {sessionStorage.getItem('token') &&
                                        <>
                                            <div className={styles.closeButtonWrapper} onClick={(e) => {
                                                props.deliteHandler()
                                                e.stopPropagation()
                                            }}>X</div>
                                            <div className={styles.editButtonWrapper} onClick={(e) => {
                                                navigate('/news_builder/' + props.id)
                                                e.stopPropagation()
                                            }}>
                                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_436_4)">
                                                        <path d="M9.63433 3.0179L9.19237 3.46007L6.54035 0.808418L6.98231 0.366206C7.47052 -0.122069 8.26223 -0.122069 8.75048 0.366206L9.63433 1.25028C10.1219 1.73829 10.1219 2.52966 9.63433 3.0179ZM3.44655 8.32086C3.3245 8.44291 3.3245 8.64057 3.44655 8.76264C3.56866 8.88537 3.76642 8.88537 3.88847 8.76264L8.75048 3.90197L8.30802 3.45976L3.44655 8.32086ZM1.23656 6.11138C1.11445 6.23354 1.11445 6.43112 1.23656 6.55325C1.3586 6.67532 1.55637 6.67532 1.67848 6.55325L6.54035 1.6922L6.09857 1.25028L1.23656 6.11138ZM6.98173 2.1338L2.1203 6.99513C1.87618 7.23877 1.8768 7.63486 2.1203 7.87898C2.36452 8.12312 2.75997 8.12425 3.00473 7.87832L7.86616 3.0179L6.98173 2.1338ZM3.00347 9.20343C2.85399 9.05377 2.78194 8.8641 2.75513 8.66866C2.69159 8.67847 2.6276 8.6876 2.56222 8.6876C2.22831 8.6876 1.9147 8.55708 1.67848 8.32086C1.44226 8.08404 1.31225 7.77086 1.31225 7.43699C1.31225 7.37611 1.32138 7.31678 1.32996 7.25763C1.12792 7.23012 0.941183 7.14162 0.794693 6.99513C0.780637 6.98118 0.775794 6.96224 0.762996 6.94695L0 9.99986L3.04498 9.2376C3.03156 9.22538 3.01637 9.21623 3.00347 9.20343Z" fill="white" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_436_4">
                                                            <rect width="10" height="10" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        </>
                                    }
                                    <div className={styles.blueBox}>
                                        <div style={{ height: 1 }} />
                                        <div className={styles.darkLine} />
                                        <div className={styles.lightLine} />
                                    </div>
                                </div>
                                <div style={{ marginTop: 80 }} className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                                <div className={styles.greyLine} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsPreloader;
