// Название компонента Upper придумал Кирилл

import styles from './Upper.module.css'
import { useRef, useState, useEffect } from "react";

function Upper(props) {
    const [scrollTop, setScrollTop] = useState(0);
    useEffect(() => {
        const handleScroll = event => {
            setScrollTop(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={scrollTop>200?styles.upperWrapper:styles.upperWrapper2} onClick={() => { window.scrollTo(0, 0) }}>
            <svg width="73" height="36" viewBox="0 0 73 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M37.8566 8.69543C37.0905 7.98824 35.9096 7.98824 35.1435 8.69543L6.71314 34.9388C5.08986 36.4372 2.55922 36.336 1.06081 34.7127C-0.437609 33.0894 -0.336383 30.5588 1.2869 29.0604L29.7172 2.817C33.5478 -0.718949 39.4522 -0.71895 43.2828 2.817L71.7131 29.0604C73.3364 30.5588 73.4377 33.0894 71.9392 34.7127C70.4408 36.336 67.9102 36.4372 66.2869 34.9388L37.8566 8.69543Z" fill="white" />
            </svg>
        </div>
    );
}

export default Upper;
