import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { createEditor, Transforms } from 'slate';
import { withHistory } from "slate-history";
import {Slate, Editable, withReact, ReactEditor} from 'slate-react';
import { Toolbar } from './Toolbar/Toolbar'
import { getMarked, getBlock } from './utils/SlateUtilityFunctions.js'
import styles from './Editor.module.css'
import {DndContext, DragOverlay} from "@dnd-kit/core"
import {SortableContext, useSortable, verticalListSortingStrategy} from "@dnd-kit/sortable"
import { createPortal } from "react-dom";
import { makeId, withNodeId } from './utils/withNodeId';
const Element = (props) =>{
    return getBlock(props);
}
const Leaf = ({ attributes, children, leaf }) => {
    children = getMarked(leaf,children);
    return <span {...attributes}>{children}</span>
}

const toPx = (value) => (value ? `${Math.round(value)}px` : undefined);
const Sortable = ({ sortable, children }) => {
    return (
        <div
            className={styles.sortable}
            {...sortable.attributes}
            ref={sortable.setNodeRef}
            style={{
                transition: sortable.transition,
                "--translate-y": toPx(sortable.transform?.y),
                pointerEvents: sortable.isSorting ? "none" : undefined,
                opacity: sortable.isDragging ? 0 : 1
            }}
        >
            {children}
        </div>
    );
};
  
const DragOverlayContent = ({ element, renderElement, renderLeaf }) => {
    const editor = useMemo(() => withNodeId(withHistory(withReact(createEditor()))), []);
    const [value] = useState([JSON.parse(JSON.stringify(element))]);

    return (
        <div style={{display:'flex', minWidth:'500px'}} className={styles.dragOverlayContent}>
            <button contentEditable={false} style={{position: 'absolute', marginLeft: '-50px', marginTop: '10px'}}>✕</button>
            <button contentEditable={false} style={{position: 'absolute', marginLeft: '-25px', marginTop: '10px'}}>⠿</button>
            <Slate editor={editor} value={value}>
                <Editable readOnly={true} renderElement={renderElement} renderLeaf={renderLeaf} />
            </Slate>
        </div>
    );
};


const SlateEditor = (props)=>{
    const editor = useMemo(() => withNodeId(withHistory(withReact(createEditor()))), []);
    editor.isVoid = element => {
        switch (element.type){
            case 'separator':
                return true
            case 'gallery':
                return true
            case 'video':
                return true
            case 'youtube':
                return true
            default:
                return false
        }
      }
    /*const [value,setValue] = useState([
        {
            type:'paragaph',
            children:[{text:''}],
            id: makeId(),
        },
    ]);*/
    const [controlDown, setControlDown] = useState(false);
    const [shiftDown, setShiftDown] = useState(false);
    const [activeId, setActiveId] = useState(null);

    const clearSelection = () => {
        ReactEditor.blur(editor);
        Transforms.deselect(editor);
        window.getSelection()?.empty();
    };

    const handleDragStart = (event) => {
        clearSelection()
        if (event.active) {
            setActiveId(event.active.id);
        }
      };
    
    const handleDragEnd = (event) => {
        const overId = event.over?.id;
        const overIndex = editor.children.findIndex((x) => x.id === overId);

        if (overId !== activeId && overIndex !== -1) {
            Transforms.moveNodes(editor, {
            at: [],
            match: (node) => node.id === activeId,
            to: [overIndex]
            });
        }

        setActiveId(null);
    };

    const handleDragCancel = () => {
        setActiveId(null);
    };


    const handleEditorChange = (newValue) =>{
        props.setContent(newValue)
        /*const content = JSON.stringify(newValue)
        console.log(content)*/
    }


    const normalElement = useCallback(props => <Element {...props}/>,[])
    
    const SortableElement = (props) => {
        const trueElement = editor.children.find((x) => x.id === props.element.id);
        const sortable = useSortable({ 
            id: props.element.id ,
            transition: {
              duration: 350,
              easing: "ease"
            }
        });
        return (
            <Sortable sortable={sortable} style={{padding:0}}>
                <div style={{padding:0}}>
                    <button contentEditable={false} style={{position: 'absolute', marginLeft: '-50px', marginTop: '10px'}}
                        onMouseDown={(e)=>{
                            if (editor.children.length > 1) {
                                e.preventDefault()
                                Transforms.removeNodes(editor,{
                                    at:[],
                                    match: (node, path) => node.id === props.element.id,
                                })
                            }
                        }}
                    >✕</button>
                    <button contentEditable={false} {...sortable.listeners} style={{position: 'absolute', marginLeft: '-25px', marginTop: '10px', userSelect:'none'}}>⠿</button>
                    <Element {...props}/>
                </div>
            </Sortable>
        );
    };

    const renderElement = useCallback((props) => {
        const isTopLevel = ReactEditor.findPath(editor, props.element).length === 1;
    
        return isTopLevel ? (
            <SortableElement {...props} />
        ) : (
            <Element {...props}/>
        );
      }, []);

    const renderLeaf = useCallback(props => {
        return <Leaf {...props} />
    }, [])


    const [htmlAction,setHtmlAction] = useState({
        showInput:false,
        html:'',
        action:'',
        location:'',
    })
    const items = useMemo(() => editor.children.map((element) => element.id), [
        editor.children
      ]);
    const activeElement = editor.children.find((x) => x.id === activeId);
    const [allowEffect, setAllowEffect] = useState(true)
    const toolbarRef = useRef(null)
    const editorRef = useRef(null)
    function useIsInViewport(ref) {
        const [isIntersecting, setIsIntersecting] = useState(false);
      
        const observer = useMemo(
          () =>
            new IntersectionObserver(([entry]) =>
              setIsIntersecting(entry.isIntersecting),
            ),
          [],
        );
      
        useEffect(() => {
          observer.observe(ref.current);
      
          return () => {
            observer.disconnect();
          };
        }, [ref, observer]);
      
        return isIntersecting;
      }
    const isVisible = useIsInViewport(toolbarRef)
    const isEditorVisible = useIsInViewport(editorRef)
    return (
        <Slate editor = {editor} value = {props.content} onChange = {handleEditorChange} >
            <div ref={toolbarRef}/>
            <div  style={{height:'100px'}}>
                <div style={{width:'auto', position:((!isVisible)&&(isEditorVisible))?'fixed':'relative', top:0, zIndex:1000}}>
                    <Toolbar allowEffect = {allowEffect} setAllowEffect = {setAllowEffect}/>  
                </div>
            </div>
            
             
            <div className={styles.editorWrapper} style={{border:'1px solid #f3f3f3'}} ref={editorRef}>
                <DndContext
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    onDragCancel={handleDragCancel}
                >
                    <SortableContext items={items} strategy={verticalListSortingStrategy}>
                        <Editable
                            className={styles.editor}
                            style={{padding:'0 0 0 50px'}}
                            placeholder='...'
                            renderElement={renderElement} 
                            renderLeaf={renderLeaf}
                            onKeyDown={(e)=>{
                                if (e.key === 'Tab'){ 
                                    e.preventDefault();
                                    editor.insertText("    ")
                                }
                                if (e.key === 'Shift'){ 
                                    e.preventDefault()
                                    setShiftDown(true)
                                }
                                if (e.key === 'Control'){ 
                                    setControlDown(true)
                                }
                                if (e.key === 'Enter'){
                                    e.preventDefault()
                                    const { selection } = editor
                                    let selected
                                    if (selection !== null && selection.anchor !== null) {
                                        selected = editor.children[selection.anchor.path[0]];
                                    } else {
                                        selected = null;
                                    }
                                    const isList = selected.type === 'orderedList' || selected.type === 'unorderedList'
                                    let isTopLevel = null
                                    if (selected){
                                        isTopLevel = ReactEditor.findPath(editor, selected).length === 1;
                                    }else{
                                        isTopLevel = true;
                                    }
                                    
                                    if(selected!==null){
                                        if (shiftDown) {
                                            Transforms.insertNodes(editor, {
                                                children: [{text: ""}],
                                                type: 'paragraph',
                                            })
                                            if (!isTopLevel){
                                                Transforms.liftNodes(editor)
                                            }
                                        }else{
                                            if (isList){
                                                Transforms.insertNodes(editor, {
                                                    children: [{text: ""}],
                                                    type: 'paragraph',
                                                })
                                            }else{
                                                editor.insertText("\n") 
                                            }               
                                        }
                                        return
                                    }
                                    return
                                }
                                if ((e.key === 'я' || e.key === 'Я') && controlDown && shiftDown){
                                    editor.redo()
                                }
                                else if ((e.key === 'я' || e.key === 'Я') && controlDown && !shiftDown){
                                    editor.undo()
                                }
                            }}
                            onKeyUp={(e)=>{
                                if (e.key === 'Shift'){ 
                                    setShiftDown(false)
                                }
                                if (e.key === 'Control'){ 
                                    setControlDown(false)
                                }
                            }}
                            onDragStart={(e)=>{
                                e.preventDefault()
                            }}
                        />
                    </SortableContext>
                    {createPortal(
                        <DragOverlay>
                            <div style={{zIndex:100,width:'100%',height:'100%',position:'absolute'}}/>
                            {activeElement && <DragOverlayContent element={activeElement} renderElement={normalElement} renderLeaf={renderLeaf}/>}
                        </DragOverlay>,
                        document.body
                    )}
                </DndContext>   
            </div>                
        </Slate>
        
    )
}

export default SlateEditor