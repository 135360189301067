import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from './AboutPartnerBlock.module.css'
import { useStore } from "effector-react";
import { addressStore } from "../../Store/ConfigStore";

function AboutPartnerBlock(props) {
    const address = useStore(addressStore);
    return (
        <div className={styles.aboutPartnerBlock}>
            <div className={styles.aboutPartnerBlockImageWrapperWrapper}>
                <div className={styles.aboutPartnerBlockImageWrapper}>
                    <a href={props.link}>
                        <LazyLoadImage
                            src={props.image}
                            placeholderSrc={props.placeholder}
                            effect="blur"
                            className={styles.aboutPartnerBlockImage}
                        />
                    </a>
                </div>
            </div>
            <div className={styles.aboutPartnerBlockText}>
                {props.text}
                {props.text === "Облако знаний - образовательный онлайн-сервис с тысячами интерактивных работ." &&
                    <a style={{ marginTop: 2 }} href={address + "/uploads/cG6LqNwSF0Qob7vC9zknxtI9c6ZhV69aYjnnKSGSsH7fGawMT5NT7uTBH2KQ3F9O.pdf"} target="_blank">Скачать презентацию о партнёре</a>
                }
            </div>
        </div>
    );
}

export default AboutPartnerBlock;
