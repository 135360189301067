import { getBlock,getMarked } from './utilityFunctions.js'
import { Text } from 'slate'
import styles from './Renderer.module.css'

const renderSlateNode = node =>{
  if(Text.isText(node)){
      let string = getMarked(node,node.text);
      return string
  }
  const children = node.children.map(n => renderSlateNode(n))

  let block = getBlock({children,element:node})
  
  return block
}

const SlateRenderer = (props) => {
  const content = JSON.parse(props.content)
  return (
    <div className={styles.renderer}>
      {content.map(n => renderSlateNode(n))}
    </div>
  )
}

export default SlateRenderer;

