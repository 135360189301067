import { useState, useEffect } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import image from '../../Images/Regular_size/standardPicture.png';
import exclamation from '../../Images/Regular_size/exclamation.svg';
import youtube from '../../Images/Regular_size/LongTube.webp';
import telegram from '../../Images/Regular_size/LongTel.webp';
import linux from '../../Images/Regular_size/linux.svg';
import windows from '../../Images/Regular_size/windows.svg';
import redos from '../../Images/Regular_size/redos.png';
import alt from '../../Images/Regular_size/alt.png';
import astra from '../../Images/Regular_size/astra.png';
import google from '../../Images/Regular_size/google.svg';
import axios from "axios";
import { useStore } from "effector-react";
import { addressStore } from "../Store/ConfigStore";
import styles from './ProductViewer.module.css';
import { useLocation, useNavigate } from "react-router-dom";
import TransparentButton from "../CommonComponents/TransparentButton/TransparentButton";
import BlueButton from "../CommonComponents/BlueButton/BlueButton";
import DownloadButton from '../CommonComponents/DownloadButton/DownloadButton';
import DownloadButtonOS from "../CommonComponents/DownloadButtonOS/DownloadButtonOS";
import SliderElement from "../CommonComponents/SliderElement/SliderElement";
import { json, useParams } from "react-router";
import DoubleSidedGradient from './../CommonComponents/DoubleSidedGradient/DoubleSidedGradient';
import SlateRenderer from '../CommonComponents/Editor/SlateRenderer/Renderer';
import ImageLinksViewer from "./ImageLinksViewer/ImageLinksViewer";

function ProductViewer() {
    const address = useStore(addressStore);
    const [isShowModal, setIsShowModal] = useState(false);
    let { id } = useParams();
    const [productNamePreview, setProductNamePreview] = useState("");
    const [path, setPath] = useState([]);
    const [productName, setProductName] = useState("");
    const [price, setPrice] = useState("");
    const [productDescription, setProductDescription] = useState("");
    const [productLinks, setProductLinks] = useState([]);
    const [productTextDown, setProductTextDown] = useState("");
    const [productTextDownEditor, setProductTextDownEditor] = useState([
        {
            type: 'paragaph',
            children: [{ text: '' }],
            id: "0",
        },
    ]);
    const [productButtons, setProductButtons] = useState([{ buttonName: "Описание", content: "Контент описания", isShowText: false, isMain: true, isVisible: true },
    { buttonName: "Состав комплекта", content: "Контент Состав комплекта", isShowText: false, isMain: true, isVisible: true },
    { buttonName: "Дополнительно", content: "Контент Дополнительно", isShowText: false, isMain: true, isVisible: true },
    { buttonName: "Программное обеспечение", content: "Контент ПО", isShowText: false, isMain: true, isVisible: false }]);
    const [images, setImages] = useState([]);
    let navigate = useNavigate();

    const [memText, setMemText] = useState("");
    const [memID, setMemID] = useState(0);
    const [currentVkladka, setCurrentVkladka] = useState(0);
    const [isPreviewEditor, setIsPreviewEditor] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [isPrice, setIsPrice] = useState(true);
    const [isOrder, setIsOrder] = useState(false);

    useEffect(() => { // хук отслеживает изменение ширины экрана
        axios.get(address + 'api/v1/articles/getArticle?id=' + id)
            .then(res => {
                // alert(JSON.stringify(res.data))
                setPath(res.data.Path)
                setProductNamePreview(res.data.Name)
                if (res.data.Content?.length !== 0 && res.data.Content?.length !== undefined) {
                    setProductName(res.data.Content[0].productName)
                    setPrice(res.data.Content[0].price)
                    setProductDescription(res.data.Content[0].productDescription)
                    setProductLinks(res.data.Content[0].productLinks)
                    setProductTextDown(res.data.Content[0].productTextDown)
                    if (res.data.Content[0]?.productTextDownEditor) {
                        setProductTextDownEditor(res.data.Content[0].productTextDownEditor)
                    } else {
                        setProductTextDownEditor([{ type: 'paragaph', children: [{ text: '' }], id: "0", },])
                    }
                    if (res.data.Content[0].hasOwnProperty('isPrice')) {
                        setIsPrice(res.data.Content[0].isPrice)
                    } else {
                        setIsPrice(true)
                    }
                    if (res.data.Content[0].hasOwnProperty('isOrder')) {
                        setIsOrder(res.data.Content[0].isOrder)
                    } else {
                        setIsOrder(true)
                    }
                    setProductButtons(res.data.Content[0].productButtons.map((x, i) => {
                        if (x?.contentEditor) {
                            return x
                        } else {
                            x["contentEditor"] = [{ type: 'paragaph', children: [{ text: '' }], id: "0", },]
                            return x
                        }
                    }))
                    for (let i = 0; i < res.data.Content[0].productButtons.length; i++) {
                        if (res.data.Content[0].productButtons[i].isVisible === true) {
                            setCurrentVkladka(i)
                            break
                        }
                    }
                    setImages(res.data.Content[0].images)
                    setIsLoad(true)
                }

            })
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => { // хук отслеживает изменение ширины экрана
        const handleExit = (event) => {
            event.preventDefault();
            event.stopImmediatePropagation()
            navigate("/products", { state: { directoryId: path[path.length - 1]?.Id, subDirectory: { "Id": path[path.length - 2]?.Id, "Name": path[path.length - 2]?.Name, "Image": "" } } }); window.scrollTo(0, 0);
        };
        window.addEventListener('popstate', handleExit, false);
        return () => {
            window.removeEventListener('popstate', handleExit);
        };
    }, [path]);

    const onKeyDown = e => {
        if (e.key === 'Escape')
            setIsShowModal(false)
    }
    const [current, setCurrent] = useState(0)
    const scrollCarousel = () => {
        let next = current + 1
        if (next >= images.length) {
            next = 0
        }
        setCurrent(next)
    }


    useEffect(() => {
        const timer = setInterval(() => {
            scrollCarousel()
        }, 5000)
        return () => clearInterval(timer);
    }, [current, isLoad])
    return (
        <div className={styles.globalWrap} onKeyDown={onKeyDown} tabIndex="0">
            <div style={width > 1920 ? { background: '#F6F6F6', position: 'relative', display: 'flex', justifyContent: 'center' } : { background: '#F6F6F6' }}>
                {isShowModal &&
                    <>
                        <div className={styles.shadow} onClick={() => setIsShowModal(false)}>
                            <div style
                                ={{ color: 'white', display: 'flex', flexWrap: 'wrap' }} >
                                <SliderElement width={0.28205128205128205 * width + 258.46153846153845} height={400} nativeSize={true} images={images.map((x, i) => address + "uploads/" + x)} />
                            </div>
                        </div>
                    </>
                }
                <div style={width > 1920 ? { maxWidth: 1920, overflow: 'hidden' } : { overflow: 'hidden' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{ flex: 1 }} />
                        <div className={styles.breadcrumbsStyle}>
                            <div className={styles.breadcrumbsElement} onClick={() => {
                                navigate("/products"); window.scrollTo(0, 0);
                            }}>
                                Продукты
                            </div>
                            <div style={{ display: 'flex' }}>
                                <span style={{ marginLeft: 5, marginRight: 5 }}> {">"} </span>
                                <div className={styles.breadcrumbsElement} onClick={() => {
                                    navigate("/products", { state: { directoryId: path[path.length - 1]?.Id } }); window.scrollTo(0, 0);
                                }}>
                                    {(path[path.length - 1]?.Id === 2) ? "Цифровые лаборатории" : (path[path.length - 1]?.Id === 3) ? "Робототехника" : (path[path.length - 1]?.Id === 4) ? "БПЛА (БВС)" : (path[path.length - 1]?.Id === 5) ? "Учебное оборудование" : (path[path.length - 1]?.Id === 97) ? "Текущее предложение" : ""}
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <span style={{ marginLeft: 5, marginRight: 5 }}> {">"} </span>
                                <div className={styles.breadcrumbsElement} onClick={() => {
                                    navigate("/products", { state: { directoryId: path[path.length - 1]?.Id, subDirectory: { "Id": path[path.length - 2]?.Id, "Name": path[path.length - 2]?.Name, "Image": "" } } }); window.scrollTo(0, 0);
                                }}>
                                    {path[path.length - 2]?.Name}
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <span style={{ marginLeft: 5, marginRight: 5 }}> {">"} </span>
                                <div className={styles.breadcrumbsElement}>
                                    {productNamePreview}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <DoubleSidedGradient />
                    </div>
                    <div className={styles.headerProduct} style={{ justifyContent: 'center' }}>
                        <div className={styles.paragraph} style={{ position: 'relative' }}>
                            <div className={styles.headerText} style={{ marginTop: 20 }}>
                                {productName}
                            </div>
                            <div className={styles.headerWrap}>
                                <div style={{ width: 315, height: 220, cursor: 'pointer', marginBottom: 70, marginRight: 20, marginTop: 20 }} onClick={() => setIsShowModal(true)}>
                                    {/* Карусель Карусель Карусель Карусель */}
                                    <div className={styles.carouselWrapper}>
                                        {images.length === 1 &&
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", height: '100%' }}>
                                                <LazyLoadImage
                                                    src={address + "uploads/" + images[0]}
                                                    effect="blur"
                                                    width={"100%"}
                                                    height={"100%"}
                                                    style={{ objectFit: 'contain' }}
                                                />
                                            </div>
                                        }
                                        {images.length !== 1 &&
                                            <>
                                                <div className={styles.bottomArrow}>
                                                    {
                                                        (images.length > 3) &&
                                                        <div onClick={(e) => { scrollCarousel(); e.stopPropagation() }}>
                                                            <svg width="78" height="44" viewBox="0 0 78 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect width="44" height="78" rx="22" transform="matrix(0 -1 -1 0 78 44)" fill="#D8D8D8" fill-opacity="0.5" />
                                                                <path d="M54.5 12.5L37.6477 36.5L23.5 12.5" stroke="#0092DD" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                        </div>
                                                    }
                                                </div>
                                                {images.map((x, i) => {
                                                    const shifted = (i - current + images.length) % images.length
                                                    return (
                                                        <div className={current === i ? styles.carouselElementFocused : shifted < 3 ? styles.carouselElement : styles.carouselElementHidden} style={{ order: shifted }}>
                                                            <LazyLoadImage
                                                                src={address + "uploads/" + images[i]}
                                                                effect="blur"
                                                                width={"100%"}
                                                                height={"100%"}
                                                                style={{ objectFit: 'contain' }}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </>
                                        }


                                    </div>
                                </div>
                                <div style={{ marginLeft: 20, flex: 1 }}>
                                    <div style={{ marginTop: isPrice ? '20px' : '45px' }}>
                                        {isPrice &&
                                            <div className={styles.priceText}>
                                                <span style={{ fontWeight: 600, marginRight: 10, fontFamily: 'Verdana' }}>Цена: &nbsp;
                                                    {price} ₽ </span>
                                            </div>
                                        }
                                    </div>
                                    <div className={styles.productText} style={{ textAlign: 'justify', whiteSpace: "pre-wrap" }}>
                                        {productDescription}
                                    </div>
                                    {isOrder &&
                                        <div style={{ flex: 2, marginTop: 10, marginBottom: 50, width: (width > 768) ? 220 : 300 }} onClick={() => { navigate("/customers"); window.scrollTo(0, 0) }}>
                                            <BlueButton width={(width > 768) ? 196 : 280} extension={(width > 768) ? 220 : 300} text={"Заказчикам"} />
                                        </div>
                                    }
                                    <ImageLinksViewer productLinks={productLinks}/>
                                </div>
                            </div>
                            <div style={{ bottom: 160, left: width > 768 ? -220 : -800, position: 'absolute', width: 0, height: 0, pointerEvents: 'none' }}>
                                <svg width="1390" height="316" viewBox="0 0 1390 316" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M175.462 0.990365C175.645 -0.126267 177.251 -0.126284 177.435 0.990348L199.365 134.127C199.443 134.603 199.85 134.955 200.332 134.964L1390 158L200.332 181.036C199.85 181.045 199.443 181.397 199.365 181.873L177.435 315.01C177.251 316.126 175.645 316.126 175.462 315.01L153.515 181.773C153.445 181.344 153.104 181.009 152.673 180.946L1.79975 158.99C0.658434 158.823 0.658423 157.177 1.79974 157.01L152.673 135.054C153.104 134.991 153.445 134.656 153.515 134.227L175.462 0.990365Z" fill="#C5E2F1" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className={styles.justifier} style={{ marginTop: 50 }}>
                        <div className={styles.viewWrapper}>
                            {isLoad &&
                                <SlateRenderer content={JSON.stringify(productTextDownEditor)} />}
                        </div>
                    </div>



                    <div style={{ display: 'flex', marginTop: 50, justifyContent: 'center' }}>
                        <div className={styles.paragraph}>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {productButtons.map((x, i) => {
                                    if (x.isVisible !== false) {
                                        return (
                                            <div className={styles.buttonWrap} style={currentVkladka === i ? { background: "rgba(216, 216, 216, 0.4)" } : {}} onDoubleClick={() => {
                                                let mem = [...productButtons];
                                                if (mem[memID].isShowText) {
                                                    mem[memID].isShowText = false;
                                                    mem[memID].buttonName = memText
                                                }
                                                setMemID(i);
                                                setMemText(x.buttonName)
                                                mem = [...productButtons];
                                                mem[i].isShowText = true;
                                                setProductButtons(mem)
                                            }} onClick={() => setCurrentVkladka(i)}>
                                                <div>{x.buttonName}</div>
                                            </div>
                                        )
                                    } else {
                                        return (<div />)
                                    }
                                })}

                            </div>
                            <div style={{ background: "#0092DD", height: 1 }} />
                        </div>
                    </div>

                    {(currentVkladka !== 3 && currentVkladka !== 4 && isLoad) &&
                        <div className={styles.justifier} style={{ marginTop: 20 }}>
                            <div className={styles.viewWrapper}>
                                <SlateRenderer content={JSON.stringify(productButtons[currentVkladka]?.contentEditor)} />
                            </div>
                        </div>

                    }

                    {currentVkladka === 3 &&
                        <div style={{ display: 'flex', marginTop: 50, justifyContent: 'center' }}>
                            <div className={styles.text + " " + styles.paragraph}>
                                <div>
                                    <div className={styles.productTextHeader}>Скачать программное обеспечение для цифровых лабораторий</div>
                                    <div className={styles.productText}>Здесь вы сможете загрузить актуальную версию ПО для наших цифровых лабораторий на сегодняшний день. Для скачивания ПО выберете вашу операционную систему и загрузите файл. </div>
                                    <div style={{ height: 1, background: '#9E9E9E', marginTop: 40 }} />
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div>
                                            <div className={styles.poTextWrapper + ' ' + styles.productText}>
                                                <div>Программное обеспечение для ПК</div>
                                            </div>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10, maxWidth: 700 }}>
                                                <div style={{ marginTop: 20 }}><DownloadButtonOS icon={linux} text={"Linux"} text2={"Загрузить"} width={177} extension={188} link="irGko4Urfpoy5ZbDui4JAW58k8l5hA9bmx8js6dXAXwvyrbiPg2LkeI3P6cn1f22.zip" /></div>
                                                <div style={{ width: 35 }} />
                                                <div style={{ marginTop: 20 }} ><DownloadButtonOS icon={windows} text={"Windows"} text2={"Загрузить"} width={177} extension={188} link="tGdtJBDgOlVqfBDgvdbCWsDVhBk1jAI4quE9dLZm6swYx1WrDHr9T7FfyIX9gcfZ.zip" /></div>
                                                <div style={{ width: 20 }} />
                                                <div style={{ marginTop: 20 }} ><DownloadButtonOS icon={astra} text={"Astra linux"} text2={"Загрузить"} width={177} extension={188} link="lLT5lXGm3Ky8ACTqlhSoCKddU7o33SEEh6c0ElXIrN5rukQbf4uwaSjsel8PcfOM.zip" /></div>
                                                <div style={{ width: 20 }} />
                                                <div style={{ marginTop: 20 }} ><DownloadButtonOS icon={alt} text={"Alt Education"} text2={"Загрузить"} width={177} extension={188} link="LPvwpIAR7v2rpbG0DnWkHbSCZ6yIzXNqsYIYpsoY2rsCiJ89EMXT4f9ExlBEGMsb.zip" /></div>
                                                <div style={{ width: 20 }} />
                                                <div style={{ marginTop: 20 }} ><DownloadButtonOS icon={redos} text={"red os"} text2={"Загрузить"} width={177} extension={188} link="tpJneJGn0bUs4jvffiy2eJDX51KaoRe8R9XIGcPYMdFF1cMtwU2Z5JA7wFbouFqT.zip" /></div>
                                                <div style={{ width: 20 }} />
                                            </div>
                                        </div>
                                        <div className={styles.flexMagic} />
                                        <div style={{}}>
                                            <div className={styles.poTextWrapper + ' ' + styles.productText}>
                                                <div>Установить приложение на Android</div>
                                            </div>
                                            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
                                                <div style={{ marginTop: 20 }}><DownloadButtonOS icon={google} text={"Android"} text2={"Загрузить"} width={177} extension={188} link="6pyd9m0DF0tZw9jX0gIhGhGtqOb2Y0QwKI0mPVQnJL2cR1CcaLpsM8YQsuX3Il8l.zip" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'end', marginTop: 70, flexWrap: 'wrap', alignItems: 'center' }}>
                                        <div>ПО для микроскопа:</div>
                                        <div style={{ width: 207 }} />
                                        <a style={{ marginTop: 10 }} href={address + "/uploads/HEpFrnnVlfWiATrfJBNYKVGcZbOAefoaFRliURkGNyE8juMbKpawZQA76oIM6HbZ.rar"} target="_blank"><DownloadButton width={290} extension={310} text={"ПО для микроскопа"} /></a>
                                        <div style={{ flex: 1 }} />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'end', marginTop: 20, flexWrap: 'wrap', alignItems: 'center' }}>
                                        <div>Инструкция подключения микроскопа:</div>
                                        <div style={{ width: 63 }} />
                                        <a style={{ marginTop: 10 }} href={window.location.href.split(window.location.pathname)[0] + '/microscope manual.pdf'} target="_blank"><DownloadButton width={290} extension={310} text={"Инструкция"} /></a>
                                        <div style={{ flex: 1 }} />
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'end', marginTop: 10, flexWrap: 'wrap', alignItems: 'center' }}>
                                        <div>Руководство пользователя (PDF файл):</div>
                                        <div style={{ width: 66 }} />
                                        <a style={{ marginTop: 10 }} href={address + "/uploads/JgfKyrBvtla9PLm9lJuRzMYcjDrSBGBYgngDb1ZcFqRF2J0o2WIvtbNyDufa2vEB.pdf"} target="_blank"><DownloadButton width={290} extension={310} text={"Руководство пользователя"} /></a>
                                        <div style={{ flex: 1 }} />
                                        <div style={{ display: 'flex' }}>
                                            <a href={"https://www.youtube.com/@poluslab"} style={{ cursor: 'pointer' }}>
                                                <LazyLoadImage
                                                    src={youtube}
                                                    effect="blur"
                                                />
                                            </a>
                                            <a href={"https://t.me/Polusnews"} style={{ marginLeft: 10, cursor: 'pointer' }}>
                                                <LazyLoadImage
                                                    src={telegram}
                                                    effect="blur"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <div className={styles.productText} style={{ flex: "500px", marginRight: 20, marginTop: 30 }}>
                                            Если возникли затруднения с установкой ПО, появились вопросы или предложения, Вы всегда можете обратиться к отделу нашей поддержки клиентов. Мы с радостью Вам поможем и ответим на все возникающие у Вас вопросы.
                                        </div>
                                        <div className={styles.supportWrapper}>
                                            <div className={styles.supportText}>
                                                <span style={{ marginRight: 3 }}>Поддержка:</span><a className={styles.link} href="mailto:support@tkpolus.ru">support@tkpolus.ru</a><br />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {currentVkladka === 4 &&
                        <div style={{ justifyContent: 'center', display: 'flex', marginTop: 50 }}>
                            <div className={styles.text + " " + styles.paragraph}>
                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Сертификат соответствия  Евразийский экономический союз:
                                        </div>
                                        <div style={{ marginLeft: 20 }}>
                                            <a href={address + "/uploads/4HsVPz0rWb7FNzorVN4E7FTIolKsqGUPEAqQ6kTvBCDYJWMGdppRRSUxmuqoLkit.pdf"} target="_blank"><DownloadButton width={210} extension={220} text={"Загрузить"} /></a>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 20 }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            Сертификат соответствия ГОСТ Р:
                                        </div>
                                        <div style={{ marginLeft: 20 }}>
                                            <a href={address + "/uploads/29Xpl8NsDEtdteklXr2ylPWturvWuGIFdIHYKSgBUEjJzdOn0THIjW0jI5xwqgDq.pdf"} target="_blank"><DownloadButton width={210} extension={220} text={"Загрузить"} /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }


                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className={styles.footerWrapper} style={{ zIndex: 100 }}>
                            {width > 480 &&
                                <div style={{ display: 'flex', maxWidth: 1110 }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <LazyLoadImage
                                            src={exclamation}
                                            effect="blur"
                                        />
                                    </div>
                                    <div className={styles.warningText}>
                                        Убедительная просьба, при покупке учебного оборудования согласовывать важные для вас характеристики, комплектацию и цену учебного оборудования.
                                    </div>
                                    <div onClick={() => { navigate("/customers"); window.scrollTo(0, 0); }} style={{ width: 208, display: 'flex', alignItems: 'center' }}>
                                        <TransparentButton width={198} extension={208} text={"Заказчикам"} />
                                    </div>
                                </div>}
                            {width <= 480 &&
                                <div style={{ maxWidth: 1110 }}>
                                    <div style={{ display: 'flex', margin: 18 }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <LazyLoadImage
                                                src={exclamation}
                                                effect="blur"
                                            />
                                        </div>
                                        <div className={styles.warningText}>
                                            Убедительная просьба, при покупке учебного оборудования согласовывать важные для вас характеристики, комплектацию и цену учебного оборудования.
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div onClick={() => { navigate("/customers"); window.scrollTo(0, 0); }} style={{ width: 345, display: 'flex', alignItems: 'center', marginBottom: 30 }}>
                                            <TransparentButton width={335} extension={345} text={"Заказчикам"} />
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div >
            </div>

        </div>

    );
}

export default ProductViewer;
