import styles from './FirstContact.module.css'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import locate from '../../../Images/Regular_size/blackLocate.svg';
import phone from '../../../Images/Regular_size/blackPhone.svg';
import mail from '../../../Images/Regular_size/convert.svg';
import { useState, useEffect } from "react";

function FirstContact(props) {
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className={styles.addressBox}>
            <div className={styles.addressTextBoxContainer}>
                <div className={styles.addressHeaderText}>
                    Главный офис компании
                </div>
                <div className={styles.addressSidebarBox}>
                    {width>850 && 
                        <svg width="11" height="280" viewBox="0 0 11 280" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.addressSidebar}>
                            <path d="M5.5 280C2.46243 280 0 277.538 0 274.5L0 0C6.07513 0 11 4.92487 11 11V274.5C11 277.538 8.53757 280 5.5 280Z" fill="#0092DD"/>
                        </svg>
                    }
                    {width<=850 &&
                        <svg width="8" height="270" viewBox="0 0 8 270" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.addressSidebar}>
                            <path d="M4 270C1.79086 270 0 268.209 0 266L0 0C4.41828 0 8 3.58173 8 8L8 266C8 268.209 6.20914 270 4 270Z" fill="#0092DD"/>
                        </svg>
                    }
                    <div className={styles.addressItemBox}>
                        <div className={styles.addressItem}>
                            <LazyLoadImage
                                src={locate}
                                effect="blur"
                                height={30}
                                width={25} 
                            />
                            <div className={styles.addressItemText}>
                                634029, г. Томск, ул. Белинского, д. 18 <br/>
                                (Часы работы Пн.-Пт. с 9.00 - 18.00)
                            </div>
                        </div>     
                        <div className={styles.addressItem}>
                            <LazyLoadImage
                                src={phone}
                                effect="blur"
                                height={25}
                                width={25} 
                            />
                            <div className={styles.addressItemText}>
                                +7 (3822) 528-999 
                            </div>
                        </div>  
                        <div className={styles.addressItem}>
                            <LazyLoadImage
                                src={mail}
                                effect="blur"
                                height={18}
                                width={25} 
                            />
                            <div className={styles.addressItemText}>
                                Почта для связи: <br/>
                                polus@tkpolus.ru<br />
                                tkpolus@mail.tomsknet.ru
                            </div>
                        </div>                             
                    </div>
                </div>
            </div>
            <div className={styles.addressMapWrapper}>
                {
                    <YMaps>
                        <Map onLoad={props.onLoad} defaultState={{ center: [56.474907, 84.958860], zoom: 16, locate: [56.474907, 84.958860] }} style={{ width: "100%", height: "100%" }}>
                            <Placemark geometry={[56.474907, 84.958860]} />
                        </Map>
                    </YMaps>
                }
            </div>
        </div>
    );
}

export default FirstContact;
