import { useState, useEffect } from 'react';
import styles from './ProductComponent.module.css'
import { LazyLoadImage } from "react-lazy-load-image-component";
import arrow from '../../../Images/Regular_size/arrow.svg';
import blueArrow from '../../../Images/Regular_size/blueArrow.svg';
function ProductComponent(props) {
    const [width, setWidth] = useState(window.innerWidth); // тут хранится ширина экрана
    const [isMouseOverButton, setIsMouseOverButton] = useState(false);
    useEffect(() => { // хук отслеживает изменение ширины экрана
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.productWrapper} style={{
            width: width<=1920?(55 / 312 * width) + (2620 / 13):540,
            height: width<=1920?(1 / 13 * width) + (1330 / 13):250
        }} onMouseOver={() => setIsMouseOverButton(true)}
            onMouseLeave={() => setIsMouseOverButton(false)}>
            <div  style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <div>
                    <div className={styles.productName}>
                        {props.text}
                    </div>
                        <div>
                        <div className={styles.description2} style={{marginTop:5}}>
                            {props.des1}
                            <br/>
                            {props.des2}
                    </div>
                </div>
                    
                    
                </div>
                <div style={{ flex: 1 }} />
                <div style={{ marginBottom: "15%", marginLeft: "15%" }}>
                    <div style={{ position: 'relative' }}>
                        <div style={!isMouseOverButton ? { opacity: "100%", transition: '0.1s', position: 'absolute', bottom: 0 } : { opacity: "0%", transition: '0.1s', position: 'absolute', bottom: 0 }}>
                            <LazyLoadImage
                                src={arrow}
                            />
                        </div>
                        <div style={isMouseOverButton ? { opacity: "100%", transition: '0.3s', position: 'absolute', bottom: 0 } : { opacity: "0%", transition: '0.3s', position: 'absolute', bottom: 0 }}>
                            <div style={{display:'flex'}}>
                                <div className={styles.watchText}> 
                                    Смотреть
                                </div>
                                <LazyLoadImage
                                    src={blueArrow}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ alignItems: 'center', display: 'flex', marginRight: "5%" }}>
                <LazyLoadImage
                    src={props.src}
                    placeholderSrc={props.placeholderSrc}
                    height={width<=1920?(101 / 1560 * width) + (1101 / 13):209}
                    width={width<=1920?((11 / 156) * width) + (1035 / 13):215}
                    effect="blur"
                />
            </div>
        </div>
    );
}

export default ProductComponent;
