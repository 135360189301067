import { useLayoutEffect, useRef, useState, useMemo, useEffect } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useStore } from "effector-react";
import { addressStore } from "../../Store/ConfigStore";
import styles from './SliderElement.module.css'
import leftButton from '../../../Images/Regular_size/sliderButtonLeft.svg';
import rightButton from '../../../Images/Regular_size/sliderButtonRight.svg';
import point from '../../../Images/Regular_size/point.svg';
import circle from '../../../Images/Regular_size/circle.svg';


function SliderElement(props) {
    const address = useStore(addressStore);
    const [slide, setSlide] = useState(0);
    const [points, setPoints] = useState(Array(props.images.length).fill().map((_, i) => 0));
    const [isShowImage, setIsShowImage] = useState(false);
    const [isSize, setIsSize] = useState(false);
    const leftSlideHandler = (slide) => { // логика переключения слайда 
        if (slide < 0) {
            setSlide(slide + 100)
            let copy = Array(props.images.length).fill().map((_, i) => 0);
            copy[Math.abs((slide + 100) / 100)] = 1
            setPoints(copy)
        }
        else {
            setSlide(-(props.images.length - 1) * 100)
            let copy = Array(props.images.length).fill().map((_, i) => 0);
            copy[props.images.length - 1] = 1
            setPoints(copy)
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            rightSlideHandler(slide);
        }, 5000);
        return () => clearInterval(interval);
    }, [slide])

    const rightSlideHandler = (slide) => { // логика переключения слайда 
        if (slide > 0 - ((props.images.length - 1) * 100)) {
            setSlide(slide - 100)
            let copy = Array(props.images.length).fill().map((_, i) => 0);
            copy[Math.abs((slide - 100) / 100)] = 1
            setPoints(copy)
        } else {
            setSlide(0)
            let copy = Array(props.images.length).fill().map((_, i) => 0);
            copy[0] = 1
            setPoints(copy)
        }
    }


    useEffect(() => {
        let copy = Array(props.images.length).fill().map((_, i) => 0);
        copy[Math.abs(slide / 100)] = 1
        setPoints(copy)
    }, []);

    useEffect(() => {
        if (isShowImage) {
            setTimeout(function tick() {
                setIsSize(true)
            }, 100);
        }
    }, [isShowImage]);

    useEffect(() => {
        if (!isSize) {
            setTimeout(function tick() {
                setIsShowImage(false)
            }, 300);
        }
    }, [isSize]);





    return (
        <div style={{ width: props.width, height: props.height, position: 'relative' }}>
            {(isShowImage || isSize) && <div style={{ position: 'fixed', width: '100vw', height: '100vh', top: 0, left: 0, zIndex: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <div style={{ display: 'flex', width: props.width, height: props.height }}>
                    {props.nativeSize &&
                        <div style={{ width: props.width, height: props.height, justifyContent: 'center', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            <LazyLoadImage
                                src={props.images[Math.abs(slide / 100)]}
                                effect="blur"
                                onClick={(e) => { setIsSize(!isSize); e.stopPropagation() }}
                                className={styles.imageStyle}
                                style={{ height: isSize ? '100vh' : '0px', width: isSize ? '100vw' : '0px' }}
                            />
                        </div>
                    }
                    {!props.nativeSize &&
                        <LazyLoadImage
                            src={props.images[Math.abs(slide / 100)]}
                            effect="blur"
                            onClick={(e) => { setIsShowImage(!isShowImage); e.stopPropagation(); }}
                            width={props.width}
                            height="100%"
                            style={{ objectFit: 'cover' }}
                        />
                    }
                </div>
            </div>}

            <div className={styles.sliderButtonLeft} style={{ width: 0.018666666666666668 * props.width + 39.28 }} onClick={(e) => { leftSlideHandler(slide); e.stopPropagation() }}>
                <LazyLoadImage
                    src={leftButton}
                    width={0.018666666666666668 * props.width + 39.28}
                    effect="blur"
                />
            </div>
            <div className={styles.pointCenter}>
                <div>
                    {points.map((x, i) => {
                        if (x === 0) {
                            return (
                                <LazyLoadImage
                                    src={point}
                                    effect="blur"
                                />
                            )
                        } else {
                            return (
                                <LazyLoadImage
                                    src={circle}
                                    effect="blur"
                                />
                            )
                        }

                    })}
                </div>
            </div>
            <div className={styles.sliderButtonRight} onClick={(e) => { rightSlideHandler(slide); e.stopPropagation() }}>
                <LazyLoadImage
                    src={rightButton}
                    width={0.018666666666666668 * props.width + 39.28}
                    effect="blur"
                />
            </div>
            <div style={{ display: 'flex', width: props.width, height: props.height, overflow: 'hidden' }}>
                {props.images.map((x, i) => {
                    return (
                        <div style={{ width: props.width, height: props.height, transform: "TranslateX(" + slide + "%)", transition: '0.3s', userSelect: 'none', position: 'relative' }}>
                            {/* <div className={styles.pointWrapper}>
                                <div>
                                    {points.map((x, i) => {
                                        if (x === 0) {
                                            return (
                                                <LazyLoadImage
                                                    src={point}
                                                    effect="blur"
                                                />
                                            )
                                        } else {
                                            return (
                                                <LazyLoadImage
                                                    src={circle}
                                                    effect="blur"
                                                />
                                            )
                                        }

                                    })}
                                </div>
                            </div> */}
                            {props.nativeSize &&
                                <div style={{ width: props.width, height: props.height, justifyContent: 'center', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                    <LazyLoadImage
                                        src={x}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (props.extensible)
                                                setIsShowImage(!isShowImage)
                                        }

                                        }
                                        effect="blur"
                                        height="100%"
                                        width="100%"
                                        style={{ objectFit: 'contain' }}
                                    />
                                </div>
                            }
                            {!props.nativeSize &&
                                <LazyLoadImage
                                    src={x}
                                    effect="blur"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        if (props.extensible)
                                            setIsShowImage(!isShowImage)
                                    }
                                    }
                                    width={props.width}
                                    height="100%"
                                    style={{ objectFit: 'cover' }}
                                />
                            }

                        </div>
                    )
                })}
            </div>
        </div >
    );
}

export default SliderElement;
