import styles from './Slider.module.css'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect, useLayoutEffect, useState } from "react";
import buttonIcon from '../../Images/Regular_size/buttonIcon.svg';
import BlueButton from './../CommonComponents/BlueButton/BlueButton';
import { useNavigate } from "react-router-dom";
import { wrap } from 'framer-motion';
function Slider(props) {
    const [slide, setSlide] = useState(-100); // смещение картинок при листании слайдера в процентах
    const [buttonColorLeft, setButtonColorLeft] = useState("#e3e3e3"); //цвета кнопок
    const [buttonColorRight, setButtonColorRight] = useState("#e3e3e3");
    const [isMouseOverButton, setIsMouseOverButton] = useState(false);
    const [isBlock, setIsBlock] = useState(false);
    const [sliderContent, setSliderContent] = useState([
        ["https://sun9-69.userapi.com/impg/1SCNyN3UGlM7sau1gccJHF4pKZ3PWHFIxFNf1g/NsPtuULlWXw.jpg?size=2560x1092&quality=96&sign=0d152eb55530bc785aabc8ed49e5c346&type=album", "Готовые комплекты образовательного оборудования"],
        // ["https://sun9-78.userapi.com/impg/b4czmAKKEXJy7Vs9mMe9-kRZh6actuET7PqqAg/0hzdKTpVYos.jpg?size=750x422&quality=96&sign=67b7b955a04664d981d8ba6ba7e77172&type=album", "Если волк молчит, то лучше его не перебивать."],
        // ["https://sun9-6.userapi.com/impg/PYsFVgqIbjAuJVxMLFCdPbrjk69uq4fAFlUDUg/HXxcbaKb29A.jpg?size=720x405&quality=96&sign=da8b087396dd0bdb43b39f6fc777da03&type=album", "Если конкурент слаб, это значит, что он не сильный."],
        // ["https://sun9-2.userapi.com/impg/cv75ZeW9fgoOJd3U5A0AkFOP19iN4NZRchipDA/rwwX7VEADNw.jpg?size=861x517&quality=96&sign=08c7d9f698c8489236e942c5d7abb47b&type=album", "Конкуретны слабеют, государство давит, а Полюс решает."]
    ]); //TODO изменить картинки
    let navigate = useNavigate();
    const [isScale, setIsScale] = useState(1);
    const leftSlideHandler = () => { // логика переключения слайда 
        if (slide < 0)
            setSlide(slide + 100)
    }

    const rightSlideHandler = () => { // логика переключения слайда 
        if (slide > 0 - ((sliderContent.length + 1) * 100))
            setSlide(slide - 100)
    }

    useEffect(() => {
        if (slide === 0) {
            setTimeout(() => {
                setSlide(-sliderContent.length * 100)
                setIsBlock(true)
                setTimeout(() => {
                    setIsBlock(false)
                }, 100);
            }, 200);
        }
        if (slide === (-(sliderContent.length + 1) * 100)) {
            setTimeout(() => {
                setSlide(-1 * 100)
                setIsBlock(true)
                setTimeout(() => {
                    setIsBlock(false)
                }, 100);
            }, 200);
        }
    }, [slide])
    const [width, setWidth] = useState(window.innerWidth); // тут хранится ширина экрана
    const [isScale2, setIsScale2] = useState(false); // тут хранится ширина экрана

    useEffect(() => { // хук отслеживает изменение ширины экрана

        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIsScale(1.08)
        }, 0);
        const interval = setInterval(() => {
            setTimeout(() => {
                setIsScale(1.0)
            }, 0);
            setTimeout(() => {
                setIsScale(1.08)
            }, 20000);
        }, 20000);
        return () => clearInterval(interval);
    }, []);


    return (
        <div>
            {/* {slide} */}
            {/* style={{left:(width-1920)/2}} */}
            {width <= 1920 &&
                <div className={styles.subWrap} style={{ width: "100%" }}>
                    <div className={styles.newSliderText} style={props.isOpen ? { transform: "TranslateX(0%)", width: props.isWidth + "%" } : { transform: "TranslateX(100%)", width: props.isWidth + "%" }}>
                        <div>
                            <div>
                                <div className={styles.slideHeaderText} style={{ marginTop: 20 }}>
                                    Комплексные решения в области информационных технологий для повышения  качества образования
                                </div>
                                <div className={styles.slideHeader2Text} style={{ marginTop: 20 }}>
                                    Мы предлагаем широкую палитру решений: от поставок с <br />
                                    интерактивными панелями до комплексных образовательных <br />
                                    решений с цифровыми лабораториями.<br />
                                </div>
                            </div>
                            <div className={styles.buttonWrap} onClick={() => { navigate("/products"); window.scrollTo(0, 0); }}>
                                <BlueButton width={259} extension={280} text={"Перейти к товарам"} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {width > 1920 &&
                <div style={{display:'flex', justifyContent:'center'}}>
                    <div className={styles.subWrap} style={{ width: "1920px", left:'calc(50% - 960px)' }}>
                        <div className={styles.newSliderText} style={props.isOpen ? { transform: "TranslateX(0%)", width: props.isWidth + "%" } : { transform: "TranslateX(100%)", width: props.isWidth + "%" }}>
                            <div>
                                <div className={styles.slideHeaderTextNotAdaptive} style={{ marginTop: 20, marginLeft:400 }}>
                                    Комплексные решения в области информационных технологий для повышения  качества образования
                                </div>
                                <div className={styles.slideHeader2TextNotAdaptive} style={{ marginTop: 20, marginLeft:400 }}>
                                    Мы предлагаем широкую палитру решений по заказу:
                                    от работы с интерактивной доской до комплексных интерактивных решений.
                                </div>
                            </div>
                            <div className={styles.buttonWrapNotAdaptive} style= {{marginLeft:400}} onClick={() => { navigate("/products"); window.scrollTo(0, 0); }}>
                                <BlueButton width={259} extension={280} text={"Перейти к товарам"} />
                            </div>
                        </div>
                    </div>
                </div>
            }



            <div className={styles.wrapper} style={{ maxWidth: 1920 }}>

                {/* <div className={styles.leftButtonStyle} onClick={() => leftSlideHandler()} onMouseOver={() => setButtonColorLeft("#b5b5b5")} onMouseLeave={() => setButtonColorLeft("#e3e3e3")}>
                    <svg width="50" height="70" viewBox="0 0 50 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M40.4165 61.25L21.2498 35L40.4165 8.16667L34.5832 0L9.58317 35L34.5832 70L40.4165 61.25Z" fill={buttonColorLeft} />
                    </svg>
                </div>
                <div className={styles.rightButtonStyle} onClick={() => rightSlideHandler()} onMouseOver={() => setButtonColorRight("#b5b5b5")} onMouseLeave={() => setButtonColorRight("#e3e3e3")}>
                    <svg width="50" height="70" viewBox="0 0 50 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.5835 61.25L28.7502 35L9.5835 8.16667L15.4168 0L40.4168 35L15.4168 70L9.5835 61.25Z" fill={buttonColorRight} />
                    </svg>
                </div> */}
                <div style={{ justifyContent: "center", display: 'flex', "overflow-x": "hidden" }}>
                    <div className={styles.SliderWrapper}>
                        {/* {[sliderContent[sliderContent.length - 1], ...sliderContent, sliderContent[0]].map((x, i) => {
                            return (
                                <div key={i}>
                                    <div style={{ transform: "TranslateX(" + slide + "%)", position: 'relative', transition: "all " + ((isBlock === false) ? "0.2" : "0") + "s", transform: 'scale(1)' }}>
                                        <LazyLoadImage
                                            src={x[0]}
                                            style={{ width: props.width, height: props.height }}
                                        />
                                    </div>
                                </div>
                            )
                        })} */}
                        <div className={styles.mainImg} style={{ position: 'relative', transition: "all " + ((isBlock === false) ? "20" : "0") + "s", transform: 'scale(' + isScale + ')', "overflow-x": "hidden" }}>
                            <LazyLoadImage
                                src={sliderContent[0][0]}
                                style={{ width: props.width, height: props.height }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Slider;
