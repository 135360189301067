import styles from './NewsUavAdd.module.css'
import { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

function NewsUavAdd(props) {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => { // хук отслеживает изменение ширины экрана
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{marginTop: -100, cursor:"pointer"}}>
            <div style={width > 1920 ? { justifyContent: 'center', display: 'flex'} : {  }}>
                <div style={width > 1920 ? { width: 1920, overflow: 'hidden' } : { overflow: 'hidden' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className={styles.headerProduct} style={{ justifyContent: 'center' }}>
                            <div className={styles.paragraph} style={{ position: 'relative' }}>
                                <div className={styles.headerWrap} style={{ minHeight: 150 }}>
                                    <div className={styles.mainBoxPreloader}>
                                        <div className={styles.blueBox}>
                                            <div className={styles.plus}>
                                                +
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsUavAdd;
