import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from './AboutScrollBlock.module.css'
import { useState } from "react";

function AboutScrollBlock(props) {
    const [isShowImage, setIsShowImage] = useState(false);
    const [isSize, setIsSize] = useState(false);
    const delaySetIsSize = () => {
        setTimeout(() => setIsSize(true), 50);
    }
    const delaySetIsShowImage = () => {
        setTimeout(() => setIsShowImage(false), 300);

    }
    return (
        <div className={styles.aboutScrollBlock}>
            {isShowImage &&
                <div className={styles.bigImage} onClick={()=>{delaySetIsShowImage(); setIsSize(false)}}>
                    <LazyLoadImage
                        src={props.image}
                        placeholderSrc={props.placeholder}
                        effect="blur"
                        className={styles.forScaleAboutScrollBlockImage}
                        style={{ height: isSize ? '100vh' : '0px', width: isSize ? '100vw' : '0px'}}
                    />
                </div>
            }
            <LazyLoadImage
                src={props.placeholder}
                placeholderSrc={props.placeholder}
                effect="blur"
                onClick={()=>{setIsShowImage(true); delaySetIsSize()}}
                className={styles.aboutScrollBlockImage}
            />
        </div>
    );
}

export default AboutScrollBlock;
