import styles from './MainPage.module.css'
import { Suspense, lazy } from 'react';
import { useRef, useState, useEffect } from "react";
import Stack from './Stack/Stack';
import MainSlider from './MainSlider/MainSlider';
import MainOrder from './MainOrder/MainOrder';
import MainProducts from './MainProducts/MainProducts';
import NewsScroller from './../CommonComponents/NewsScroller/NewsScroller';

function MainPage() {
    const [isOpen, setIsOpen] = useState(true);
    const refComponent = useRef(null);
    const [h, setH] = useState(0);
    const [update, setUpdate] = useState(0);
    const [isWidth, setIsWidth] = useState(100);




    const [width, setWidth] = useState(window.innerWidth); // тут хранится ширина экрана
    useEffect(() => { // хук отслеживает изменение ширины экрана
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    const [scrollTop, setScrollTop] = useState(0);
    const [oldScrollTop, setOldScrollTop] = useState(1);

    useEffect(() => {
        const handleScroll = event => {
            setScrollTop(window.scrollY);
            console.log(window.scrollY);
            if (window.scrollY===0) {
                setIsOpen(true)
                setTimeout(() => setIsWidth(100), 1000);
            }

        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if ((scrollTop - oldScrollTop) < 0) {
            if (window.scrollY <= 100 && isOpen === false && isWidth === 0) {
                // setIsOpen(true)
                setIsWidth(100)
            }
        } else {
            if (isOpen) {
            }
        }
        setOldScrollTop(scrollTop)
    }, [scrollTop]);


    return (
        <div className={styles.globalWrap} id="container"
        >
            <head>
                <meta name="yandex-verification" content="a2455129deeaa807" />
                <meta name="google-site-verification" content="xRwe_W2EqGyHoI4P-Be32hW-SDz4FQCzfRpX7xPznY0" />
            </head>
            <div style={{ marginTop: 0 }} >
                <MainSlider isWidth={isWidth} isOpen={true} />
            </div>
            <div className={styles.animStyle} style={isOpen ? {} : (width < 650 ? { transform: "TranslateY(-" + (319 + 90) + "px)" } : width < 768 ? { transform: "TranslateY(-" + (580 + 90) + "px)" } : { transform: "TranslateY(-" + (734 + 90) + "px)" })} ref={refComponent}>
                <div>
                    {/* <MainAbout /> */}
                </div>
                <MainOrder />
                <MainProducts />
                <Stack />
                <NewsScroller />
                <div className={styles.footer} style={isOpen ? {} : (width < 650 ? { marginBottom: -(319 + 90) } : width < 768 ? { marginBottom: -(580 + 90) } : { marginBottom: -(734 + 90) })} />
            </div>
        </div >
    );
}

export default MainPage;
