import styles from './NewsScroller.module.css';
import axios from "axios";
import NewsScrollBlock from "./NewsScrollBlock/NewsScrollBlock";
import stockNews from "../../../Images/Regular_size/stocknews.png";
import slideLeft from "../../../Images/Regular_size/slideLeft.svg";
import slideRight from "../../../Images/Regular_size/slideRight.svg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useRef, useState, useEffect } from "react";
import { useStore } from "effector-react";
import { addressStore } from "../../Store/ConfigStore";

function News() {
    const ref = useRef(null)
    const [width, setWidth] = useState(window.innerWidth);
    const [products, setProducts] = useState([])
    const address = useStore(addressStore);
    useEffect(() => {
        axios.get(address + 'api/v1/articles/getDirectory?id=1&page=1&pageSize=20')
            .then(res => {
                setProducts(res.data["Articles"])
            })
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    return (
        <div className={styles.justifier}>
            <div className={styles.innerJustifier}>
                <div className={styles.newsScrollerText}>
                    Наши новости
                </div>
                <div className={styles.newsScroller} ref={ref}>
                    <div className={styles.newsPadding} />
                    {products.map((x, i) => {
                        return (
                            // <div>{JSON.stringify(x.Id)} </div>
                            <NewsScrollBlock
                                imageURL={address + "uploads/" + x.Image}
                                text={x.Description}
                                Id={x.Id}
                            />
                        )
                    })}
                </div>
                <div className={styles.scrollButtons}>
                    <LazyLoadImage
                        src={slideLeft}
                        effect="blur"
                        className={styles.scrollLeft}
                        onClick={() => { ref.current.scrollLeft -= width / 3 }}
                    />
                    <LazyLoadImage
                        src={slideRight}
                        effect="blur"
                        className={styles.scrollLeft}
                        onClick={() => { ref.current.scrollLeft += width / 3 }}
                    />
                </div>
            </div>
        </div>
    );
}

export default News;
